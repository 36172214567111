export const INFO_TYPE = {
  WARNING: 'warning',
  ERROR: 'error',
  SUCCESS: 'success',
}

export const INPUT_TYPES = {
  TEXT: 'text',
  PASSWORD: 'password',
  DROPDOWN: 'dropdown',
  DATE: 'date',
  VALUE_DROPDOWN: 'valueDropdown',
}
