import React, {useEffect, useState} from 'react'
import {Button, Input, ToggleSwitch} from '@teachmint/common'
import {useDispatch} from 'react-redux'
import SliderScreen from '../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../utils/history'
import styles from './SliderAddDepartment.module.css'
import {useTranslation} from 'react-i18next'
import {
  ADD_DEPARTMENT_FIELDS,
  DEFAULT_ADD_DEPARTMENT_FIELDS_VALUES,
} from './addDepartmentFormData'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../utils/validation'
import {getFromSessionStorage} from '../../../../utils/storage.helpers'
import globalActions from '../../../../redux/actions/global.actions'
import KrayonInputField from '../../../../components/common/KrayonInputField/KrayonInputField'

export default function SliderAddDepartment() {
  const [formData, setFormData] = useState(DEFAULT_ADD_DEPARTMENT_FIELDS_VALUES)
  const [errorObject, setErrorObject] = useState({})

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(formData, ADD_DEPARTMENT_FIELDS, fieldName, value)
    )

  // Autofill course prefix if department name changed
  useEffect(() => {
    let generatedName = ''
    const words = formData?.name?.split(' ')?.filter((word) => word?.length > 0)
    if (words?.length > 1)
      generatedName = words
        ?.slice(0, 2)
        ?.map((word) => word[0])
        ?.join('')
    else generatedName = words?.[0]?.slice(0, 2) || ''

    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_DEPARTMENT_FIELDS,
        ADD_DEPARTMENT_FIELDS.course_prefix.fieldName,
        generatedName.toUpperCase()
      )
    )
  }, [formData?.name])

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_DEPARTMENT_FIELDS,
      formData
    )

    const instituteId = getFromSessionStorage('INSTITUTE_ID')
    if (isInputValid && instituteId) {
      const successAction = () => history.goBack()

      dispatch(
        globalActions?.addInstituteDepartment?.request(
          {...formData, node_id: instituteId},
          successAction
        )
      )
    }
  }

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_DEPARTMENT__LABEL')}
          />

          <SliderScreenBody>
            <div>
              <form>
                {Object.values(ADD_DEPARTMENT_FIELDS)?.map(
                  ({fieldType, fieldName, label, placeholder}) => (
                    <div key={fieldName} className={styles.inputWrapper}>
                      <Input
                        type={fieldType}
                        title={t(label)}
                        fieldName={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        placeholder={t(placeholder)}
                        errorMsg={errorObject[fieldName]}
                      />
                    </div>
                  )
                )}

                {/* <div className={styles.coursePrefixToggleWrapper}>
                  <div className={styles.headingWrapper}>
                    <div className="tm-hdg-16">
                      {t(
                        'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PREFIX_ENABLED_LABEL'
                      )}
                    </div>
                    <ToggleSwitch
                      id="course-prefix-toggle"
                      small
                      checked={formData?.course_prefix_enabled}
                      onChange={(v) =>
                        handleInputChange({
                          fieldName: 'course_prefix_enabled',
                          value: v,
                        })
                      }
                    />
                  </div>

                  {formData?.course_prefix_enabled && (
                    <div className={styles.coursePrefixInputWrapper}>
                      <KrayonInputField
                        fieldTypetype={
                          ADD_DEPARTMENT_FIELDS.course_prefix.fieldType
                        }
                        label={t(ADD_DEPARTMENT_FIELDS.course_prefix.label)}
                        fieldName={
                          ADD_DEPARTMENT_FIELDS.course_prefix.fieldName
                        }
                        value={
                          formData[
                            ADD_DEPARTMENT_FIELDS.course_prefix.fieldName
                          ]
                        }
                        onChange={handleInputChange}
                        placeholder={t(
                          ADD_DEPARTMENT_FIELDS.course_prefix.placeholder
                        )}
                        
                        errorMsg={
                          errorObject[
                            ADD_DEPARTMENT_FIELDS.course_prefix.fieldName
                          ]
                        }
                      />
                    </div>
                  )}
                </div> */}

                <Button
                  className={styles.submitButton}
                  size="medium"
                  onClick={handleFormSubmit}
                >
                  {t('COMMON_SUBMIT')}
                </Button>
              </form>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
