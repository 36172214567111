import React from 'react'
import styles from './StatsSection.module.css'
import classNames from 'classnames'
import {useSelector} from 'react-redux'

export default function StatsSection() {
  const {globalData} = useSelector((state) => state)

  const statsItems = [
    {
      id: 1,
      label: 'Departments',
      value: globalData?.instituteDepartment?.data?.length || 0,
    },
    {id: 2, label: 'Users', value: '3000'},
    {id: 3, label: 'Departments', value: '30'},
    {id: 4, label: 'Users', value: '3000'},
  ]

  return (
    <div className={styles.wrapper}>
      {statsItems?.map(({id, label, value}) => (
        <div key={id} className={styles.item}>
          <div className={classNames('tm-hdg-26 lg:tm-hdg-32', styles.value)}>
            {value}
          </div>
          <div className={classNames('tm-para-14 lg:tm-para-16', styles.label)}>
            {label}
          </div>
        </div>
      ))}
    </div>
  )
}
