// Root level
export const LOGIN = '/login'
export const DASHBOARD = '/dashboard'
export const COURSE = '/course/:courseId'

// Dashboard level routes
export const DEPARTMENTS = `${DASHBOARD}/departments`
export const MANAGE_USER = `${DASHBOARD}/manage-user`
export const INSTITUTE_SESSION = `${DASHBOARD}/institute-session`
export const MANAGE_DEPARTMENT = `${DASHBOARD}/department/:departmentId`
export const MY_COURSES = `${DASHBOARD}/my-courses`

// Department level routes
export const DEPARTMENTS__ADD = `${DEPARTMENTS}/add`

// Session level routes
export const INSTITUTE_SESSION__ADD = `${INSTITUTE_SESSION}/add`

// Manage department routes
export const MANAGE_DEPARTMENT__MANAGE_USER = `${MANAGE_DEPARTMENT}/manage-user`
export const MANAGE_DEPARTMENT__PROGRAMS = `${MANAGE_DEPARTMENT}/programs`
export const MANAGE_DEPARTMENT__COURSES = `${MANAGE_DEPARTMENT}/course`

// Department Program routes
export const MANAGE_DEPARTMENT__PROGRAMS__ADD = `${MANAGE_DEPARTMENT__PROGRAMS}/add`
export const MANAGE_DEPARTMENT__PROGRAMS__ADD_BATCH = `${MANAGE_DEPARTMENT__PROGRAMS}/add-program-batch`

// Department Course routes
export const MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS = `${MANAGE_DEPARTMENT__COURSES}/offerings`
export const MANAGE_DEPARTMENT__COURSES__COURSE_MASTER = `${MANAGE_DEPARTMENT__COURSES}/master`

// Department Course offering routes
export const MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS__ADD = `${MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS}/add`

// Department Course master routes
export const MANAGE_DEPARTMENT__COURSES__COURSE_MASTER__ADD = `${MANAGE_DEPARTMENT__COURSES__COURSE_MASTER}/add`

// Department Manage user routes
export const MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS = `${MANAGE_DEPARTMENT__MANAGE_USER}/students`
export const MANAGE_DEPARTMENT__MANAGE_USER__FACULTY = `${MANAGE_DEPARTMENT__MANAGE_USER}/faculty`

// Department Manage user student routes
export const MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS__ADD = `${MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS}/add`

// Department Manage user faculty routes
export const MANAGE_DEPARTMENT__MANAGE_USER__FACULTY__ADD = `${MANAGE_DEPARTMENT__MANAGE_USER__FACULTY}/add`

// Course page routes
export const COURSE__MANAGE_STUDENTS = `${COURSE}/students`
export const COURSE__MANAGE_FACULTY = `${COURSE}/faculty`
export const COURSE__RECORDINGS = `${COURSE}/recodings`

// Course page student route
export const COURSE__MANAGE_STUDENTS__ADD = `${COURSE__MANAGE_STUDENTS}/add`

// Course page faculty route
export const COURSE__MANAGE_FACULTY__ADD = `${COURSE__MANAGE_FACULTY}/add`

// Login routes
export const LOGIN__FORGOT_PASSWORD = `${LOGIN}/forgot-password`
export const LOGIN__TEMPORARY_PASSWORD_PAGE = `${LOGIN}/temporary-password`
export const LOGIN__RESET_PASSWORD = `${LOGIN}/reset-password`
