import React from 'react'
import EmptyScreen from '../../components/common/EmptyScreen/EmptyScreen'
import styles from './MyCourses.module.css'
import {useTranslation} from 'react-i18next'

export default function MyCourses() {
  const {t} = useTranslation()

  return (
    <div>
      <div className={styles.emptyWrapper}>
        <EmptyScreen
          icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
          label={t('MY_COURSES__EMPTY_LABEL')}
          desc={t('MY_COURSES__EMPTY_DESC')}
        />
      </div>
    </div>
  )
}
