import Joi from 'joi'

const VALIDATION_SCHEMA = {
  passwordInput: Joi.string().min(0).max(50).label('Password'),
  password: Joi.string().required().max(50).label('Password'),
}

const MODAL_FIELDS = {
  password: {
    fieldType: 'password',
    label: 'LOGIN__TEMPORARY_PASSWORD__PASSWORD_LABEL',
    placeholder: 'LOGIN__TEMPORARY_PASSWORD__PASSWORD_PLACEHOLDER',
    fieldName: 'password',
    options: {
      inputValidation: VALIDATION_SCHEMA.passwordInput,
      finalValidation: VALIDATION_SCHEMA.password,
    },
  },
}

export const TEMPORARY_PASSWORD_FIELDS = {
  password: MODAL_FIELDS.password,
}
export const DEFAULT_TEMPORARY_PASSWORD_FIELDS_VALUES = {
  password: '',
}
