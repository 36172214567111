import {Icon} from '@teachmint/common'
import React from 'react'
import styles from './AnnouncementPanel.module.css'

export default function AnnouncementPanel() {
  const items = [{iconName: 'calendar'}, {iconName: 'announcement'}]

  return (
    <div className={styles.wrapper}>
      {items?.map(({iconName}, index) => (
        <div key={index} className={styles.item}>
          <Icon
            name={iconName}
            size="xxs"
            className={styles.dropdownIcon}
            color="inverted"
          />
        </div>
      ))}
    </div>
  )
}
