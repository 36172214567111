import {React} from 'react'
import {Route, Switch} from 'react-router-dom'
import styles from './ContentArea.module.css'
import {
  DASHBOARD,
  DEPARTMENTS,
  INSTITUTE_SESSION,
  MANAGE_DEPARTMENT,
  MY_COURSES,
} from '../../../../constants/pages.constants'
import Dashboard from '../../../Dashboard/Dashboard'
import DepartmentsPage from '../../../DepartmentsPage/DepartmentsPage'
import DepartmentContentArea from '../../../DepartmentsPage/pages/DepartmentContentArea/DepartmentContentArea'
import InstituteSession from '../../../InstituteSession/InstituteSession'
import MyCourses from '../../../MyCourses/MyCourses'

export default function ContentArea() {
  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route exact path={DASHBOARD} component={Dashboard} />
        <Route path={DEPARTMENTS} component={DepartmentsPage} />
        <Route path={INSTITUTE_SESSION} component={InstituteSession} />
        <Route path={MANAGE_DEPARTMENT} component={DepartmentContentArea} />
        <Route path={MY_COURSES} component={MyCourses} />
      </Switch>
    </div>
  )
}
