import axios from 'axios'
import {REACT_APP_API_URL} from '../../constants/envVars.constants'

export const utilsGetInstituteInfo = async (subdomain) => {
  let params = new URLSearchParams()
  params.append('subdomain', subdomain)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}institute/list`,
    params,
  })
  return res
}

export const utilsGetPublicInstituteInfo = async (subdomain) => {
  let params = new URLSearchParams()
  params.append('node_id', subdomain)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}utils/institute`,
    params,
  })
  return res
}
