import React from 'react'
import {useSelector} from 'react-redux'
import {Route, Switch} from 'react-router-dom'
import {DASHBOARD, DEPARTMENTS__ADD} from '../../constants/pages.constants'
import {GLOBAL_PERMISSION_IDS} from '../../constants/permissions.constants'
import history from '../../utils/history'
import {checkPermission} from '../../utils/permission.helpers'
import DepartmentsList from './components/DepartmentsList/DepartmentsList'
import SliderAddDepartment from './components/SliderAddDepartment/SliderAddDepartment'

export default function DepartmentsPage() {
  const state = useSelector((state) => state)

  // Check if route permission exist
  // if (!checkPermission(state, GLOBAL_PERMISSION_IDS.DEPARTMENT__GET))
  //   history.replace(DASHBOARD)

  return (
    <div>
      <DepartmentsList />

      <Switch>
        <Route path={DEPARTMENTS__ADD} component={SliderAddDepartment} />
      </Switch>
    </div>
  )
}
