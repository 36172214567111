import React from 'react'
import PropTypes from 'prop-types'
import styles from './DateField.module.css'

export default function DateField({
  fieldName,
  value,
  onChange,
  placeholder,
  showFieldBorder,
  infoType,
  id,
}) {
  return (
    <input
      type="date"
      className={styles.input}
      data-status={showFieldBorder ? infoType : ''}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange({fieldName, value: e.target.value, event: e})}
      id={id}
    />
  )
}

DateField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  showFieldBorder: PropTypes.bool,
  infoType: PropTypes.string,
  id: PropTypes.string,
}

DateField.defaultProps = {
  fieldName: '',
  value: '',
  placeholder: '',
  onChange: () => {},
  showFieldBorder: false,
  infoType: '',
  id: '',
}
