import axios from 'axios'
import {REACT_APP_API_URL} from '../../../../constants/envVars.constants'

export const utilsGetDepartmentPrograms = async (departmentId) => {
  let params = new URLSearchParams()
  params.append('node_id', departmentId)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}program/list`,
    params,
  })
  return res
}

export const utilsAddDepartmentProgram = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}program/add`,
    data,
  })
  return res
}

export const utilsAddDepartmentProgramBatch = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}program-batch/add`,
    data,
  })
  return res
}
