import React from 'react'
import {useSelector} from 'react-redux'
import EmptyScreen from '../../../../../../components/common/EmptyScreen/EmptyScreen'
import {
  COURSE,
  COURSE__MANAGE_STUDENTS,
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS__ADD,
} from '../../../../../../constants/pages.constants'
import history from '../../../../../../utils/history'
import {useTranslation} from 'react-i18next'
import styles from './CourseOfferingListPage.module.css'
import {matchPath} from 'react-router-dom'
import {Button} from '@teachmint/common'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'

export default function CourseOfferingListPage() {
  const {departmentCourseInfo} = useSelector((state) => state)
  const {t} = useTranslation()

  const getAddCourseOfferingPath = () =>
    updatePathWithParams(
      MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS__ADD,
      getParamsFromPath(matchPath, MANAGE_DEPARTMENT)
    )

  return (
    <div>
      {departmentCourseInfo?.departmentCourseList?.length > 0 ? (
        <>
          <div className={styles.addCourseOfferingBtnWrapper}>
            <Button
              className={styles.button}
              size="medium"
              onClick={() => history.push(getAddCourseOfferingPath())}
            >
              {t('DEPARTMENT_COURSES__COURSE_OFFERING__ADD_COURSE_ACTION')}
            </Button>
          </div>

          {departmentCourseInfo?.departmentCourseList?.map(
            ({_id, name, classes}) => (
              <div key={_id} className={styles.item}>
                <div className={styles.itemHeader}>
                  <div className="tm-hdg-16">{name}</div>
                </div>

                <div className={styles.courseSectionWrapper}>
                  {classes?.map(({_id, name}) => (
                    <div
                      key={_id}
                      className={styles.sectionItem}
                      onClick={() =>
                        history.push(
                          updatePathWithParams(COURSE__MANAGE_STUDENTS, {
                            courseId: _id,
                          })
                        )
                      }
                    >
                      <div className="tm-hdg-16">{name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('DEPARTMENT_COURSES__COURSE_OFFERING__EMPTY_LABEL')}
            desc={t('DEPARTMENT_COURSES__COURSE_OFFERING__EMPTY_DESC')}
            btnOptions={{
              label: t('DEPARTMENT_COURSES__COURSE_OFFERING__EMPTY_BTN_LABEL'),
              action: () => history.push(getAddCourseOfferingPath()),
            }}
          />
        </div>
      )}
    </div>
  )
}
