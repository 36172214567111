import React, {useState, useEffect} from 'react'
import {useSelector} from 'react-redux'
import EmptyScreen from '../../../../../../components/common/EmptyScreen/EmptyScreen'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__COURSES__COURSE_MASTER__ADD,
} from '../../../../../../constants/pages.constants'
import history from '../../../../../../utils/history'
import {useTranslation} from 'react-i18next'
import ListCard from '../../../../../../components/common/ListCard/ListCard'
import SearchBar from '../../../../../../components/common/SearchBar/SearchBar'
import classNames from 'classnames'
import styles from './CourseMasterListPage.module.css'
import {matchPath} from 'react-router-dom'
import {Table} from '@teachmint/common'
import {DEPARTMENT_COURSE_MASTER_LIST_TABLE_HEADERS} from './courseMasterListPage.constants.js'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'

export default function CourseMasterListPage() {
  const [filteredCourses, setFilteredCourses] = useState([])
  const [searchText, setSearchText] = useState('')

  const {departmentCourseInfo} = useSelector((state) => state)
  const {t} = useTranslation()

  useEffect(() => {
    setFilteredCourses(departmentCourseInfo?.departmentCourseMasterList)
  }, [departmentCourseInfo?.departmentCourseMasterList])

  const getAddCourseMasterPath = () =>
    updatePathWithParams(
      MANAGE_DEPARTMENT__COURSES__COURSE_MASTER__ADD,
      getParamsFromPath(matchPath, MANAGE_DEPARTMENT)
    )

  const getRows = (courses) => {
    let rows = []

    courses?.forEach(({_id, name, code, course_credits}) => {
      rows.push({
        id: _id,
        name: <div className="tm-para-14">{name}</div>,
        code: <div className="tm-para-14">{code}</div>,
        courseCredits: <div className="tm-para-14">{course_credits}</div>,
        manage: (
          <div
            className={classNames(
              'tm-para-14 tm-cr-bl-2',
              styles.CourseMasterListManage
            )}
            onClick={() => history.push(getAddCourseMasterPath())}
          >
            {t('COMMON_MANAGE')}
          </div>
        ),
      })
    })
    return rows
  }

  const handleSearchFilter = (text) => {
    setSearchText(text)

    if (text === '')
      setFilteredCourses(departmentCourseInfo?.departmentCourseMasterList)
    else {
      let tempArray = departmentCourseInfo?.departmentCourseMasterList?.filter(
        ({name, code}) =>
          name?.toLowerCase()?.includes(text) ||
          code?.toLowerCase()?.includes(text)
      )
      setFilteredCourses(tempArray)
    }
  }

  return (
    <div>
      {departmentCourseInfo?.departmentCourseMasterList?.length > 0 ? (
        <div>
          <ListCard
            title={`${t('DEPARTMENT_COURSES__COURSE_MASTER__HEADER_LABEL')} : ${
              departmentCourseInfo?.departmentCourseMasterList?.length
            }`}
          >
            <div className={styles.subHeaderWrapper}>
              <div className={styles.subHeaderSearchbarWrapper}>
                <SearchBar
                  value={searchText}
                  placeholder={t(
                    'DEPARTMENT_COURSES__COURSE_MASTER__SEARCH_PLACEHOLDER'
                  )}
                  handleSearchFilter={handleSearchFilter}
                />
              </div>

              <div
                className={classNames(
                  'tm-para-14 tm-cr-bl-2',
                  styles.addAction
                )}
                onClick={() => history.push(getAddCourseMasterPath())}
              >
                {t('DEPARTMENT_COURSES__COURSE_MASTER__ADD_COURSE_ACTION')}
              </div>
            </div>

            <Table
              rows={getRows(filteredCourses)}
              cols={DEPARTMENT_COURSE_MASTER_LIST_TABLE_HEADERS}
            />
          </ListCard>
        </div>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('DEPARTMENT_COURSES__COURSE_MASTER__EMPTY_LABEL')}
            desc={t('DEPARTMENT_COURSES__COURSE_MASTER__EMPTY_DESC')}
            btnOptions={{
              label: t('DEPARTMENT_COURSES__COURSE_MASTER__EMPTY_BTN_LABEL'),
              action: () => history.push(getAddCourseMasterPath()),
            }}
          />
        </div>
      )}
    </div>
  )
}
