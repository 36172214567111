import {commonActionTypes} from '../actionTypes'
import {v4 as uuidv4} from 'uuid'

export const showSidebarAction = (flag) => {
  return {
    type: commonActionTypes.SHOW_SIDEBAR,
    payload: flag,
  }
}

export const hideSidebarAction = (flag) => {
  return {
    type: commonActionTypes.HIDE_SIDEBAR,
    payload: flag,
  }
}

export const showToast = (payload) => {
  const id = uuidv4()
  const {type, message = '', text = ''} = payload
  let nextType = type
  if (nextType === true) {
    nextType = 'success'
  } else if (nextType === false) {
    nextType = 'error'
  }

  return {
    type: commonActionTypes.SHOW_TOAST,
    payload: {
      ...payload,
      type: nextType,
      message: message || text,
      id,
    },
  }
}

export const showSuccessToast = (message) => {
  return showToast({type: 'success', message})
}

export const showErrorToast = (message) => {
  return showToast({type: 'error', message})
}

export const hideToast = (payload) => ({
  type: commonActionTypes.HIDE_TOAST,
  payload,
})

export const showFeedbackSliderAction = (flag) => {
  return {
    type: commonActionTypes.SHOW_FEEDBACK_SLIDER,
    payload: flag,
  }
}

export const hideFeedbackSliderAction = (flag) => {
  return {
    type: commonActionTypes.HIDE_FEEDBACK_SLIDER,
    payload: flag,
  }
}

export const setSelectedInstitute = (instituteId) => {
  return {
    type: commonActionTypes.SET_SELECTED_INSTITUTE,
    payload: instituteId,
  }
}
