export const instituteSidebar = {
  dashboardLabel: 'Dashboard',
  departmentLabel: 'Departments',
  manageUserLabel: 'Manage User',
  sessionLabel: 'Sessions',
  myCourseLabel: 'My Courses',
  addDepartmentLabel: 'Add Department',
  departmentProgramLabel: 'Programs',
  departmentCourseLabel: 'Courses',
  departmentManageStudentsLabel: 'Manage students',

  backToDepartments: 'Back to Departments',
}
