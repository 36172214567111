import Joi from 'joi'

const VALIDATION_SCHEMA = {
  passwordInput: Joi.string().min(0).max(50).label('Password'),
  password: Joi.string()
    .regex(/^(?=.*[0-9])(?=.*[a-zA-Z])\w{8,}$/)
    .label('Password')
    .messages({
      'string.pattern.base':
        'Password should contain at least one alphabet, one numeric and should be 8 characters long.',
    }),
}

const MODAL_FIELDS = {
  password: {
    fieldType: 'password',
    label: 'LOGIN__RESET_PASSWORD__PASSWORD_LABEL',
    placeholder: 'LOGIN__RESET_PASSWORD__PASSWORD_PLACEHOLDER',
    fieldName: 'password',
    options: {
      inputValidation: VALIDATION_SCHEMA.passwordInput,
      finalValidation: VALIDATION_SCHEMA.password,
    },
  },
  confirm_password: {
    fieldType: 'password',
    label: 'LOGIN__RESET_PASSWORD__CONFIRM_PASSWORD_LABEL',
    placeholder: 'LOGIN__RESET_PASSWORD__CONFIRM_PASSWORD_PLACEHOLDER',
    fieldName: 'confirm_password',
    options: {
      inputValidation: VALIDATION_SCHEMA.passwordInput,
      finalValidation: VALIDATION_SCHEMA.password,
    },
  },
}

export const RESET_PASSWORD_FIELDS = {
  password: MODAL_FIELDS.password,
  confirm_password: MODAL_FIELDS.confirm_password,
}
export const DEFAULT_RESET_PASSWORD_FIELDS_VALUES = {
  password: '',
  confirm_password: '',
}
