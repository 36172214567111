import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {
  COURSE,
  COURSE__MANAGE_FACULTY,
  COURSE__MANAGE_STUDENTS,
  COURSE__RECORDINGS,
} from '../../../../constants/pages.constants'
import CourseDashboard from '../../pages/CourseDashboard/CourseDashboard'
import CourseFaculty from '../../pages/CourseFaculty/CourseFaculty'
import CourseRecordings from '../../pages/CourseRecordings/CourseRecordings'
import CourseStudents from '../../pages/CourseStudents/CourseStudents'
import styles from './CourseContentArea.module.css'

export default function CourseContentArea() {
  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route exact path={COURSE} component={CourseDashboard} />
        <Route path={COURSE__MANAGE_STUDENTS} component={CourseStudents} />
        <Route path={COURSE__MANAGE_FACULTY} component={CourseFaculty} />
        <Route path={COURSE__RECORDINGS} component={CourseRecordings} />
      </Switch>
    </div>
  )
}
