import React from 'react'
import styles from './CourseStudents.module.css'
import CourseStudentsList from './components/CourseStudentsList/CourseStudentsList'
import SliderAddCourseStudents from './components/SliderAddCourseStudents/SliderAddCourseStudents'
import {Route, Switch} from 'react-router-dom'
import {COURSE__MANAGE_STUDENTS__ADD} from '../../../../constants/pages.constants'

export default function CourseStudents() {
  return (
    <div>
      <CourseStudentsList />

      <Switch>
        <Route
          path={COURSE__MANAGE_STUDENTS__ADD}
          component={SliderAddCourseStudents}
        />
      </Switch>
    </div>
  )
}
