import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {matchPath, Route, Switch} from 'react-router-dom'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS__ADD,
} from '../../../../../../constants/pages.constants'
import {getParamsFromPath} from '../../../../../../utils/routing.helpers'
import CourseOfferingListPage from '../CourseOfferingListPage/CourseOfferingListPage'
import SliderAddCourse from '../SliderAddCourse/SliderAddCourse'
import styles from './CoursesPage.module.css'
import globalActions from '../../../../../../redux/actions/global.actions'

export default function CoursesPage() {
  const dispatch = useDispatch()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  useEffect(() => {
    if (params?.departmentId) {
      dispatch(
        globalActions?.getDepartmentCourseMaster?.request(params?.departmentId)
      )
      dispatch(
        globalActions?.getDepartmentCourses?.request(params?.departmentId)
      )
    }
  }, [params?.departmentId])

  return (
    <div>
      <CourseOfferingListPage />

      <Switch>
        <Route
          path={MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS__ADD}
          component={SliderAddCourse}
        />
      </Switch>
    </div>
  )
}
