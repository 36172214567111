export const updatePathWithParams = (path, params) => {
  let finalPath = String(path)
  Object.keys(params)?.forEach((param) => {
    finalPath = finalPath.replace(`:${param}`, params[param])
  })
  return finalPath
}

export const getParamsFromPath = (matchPath, path) =>
  matchPath(window.location.pathname, {path})?.params || {}
