import React from 'react'
import {
  DEPARTMENT_SIDEBAR_ITEMS,
  SIDEBAR_ITEMS,
} from '../../../../constants/sidebarItems.constants'
import styles from './Sidebar.module.css'
import classNames from 'classnames'
import {Link, useRouteMatch, matchPath} from 'react-router-dom'
import {hideSidebarAction} from '../../../../redux/actions/common.actions'
import {Icon} from '@teachmint/common'
import {useDispatch, useSelector} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {
  DEPARTMENTS,
  MANAGE_DEPARTMENT,
} from '../../../../constants/pages.constants'
import history from '../../../../utils/history'
import {updatePathWithParams} from '../../../../utils/routing.helpers'
import {checkPermission} from '../../../../utils/permission.helpers'
import KrayonP3 from '../../../../components/common/KrayonP3/KrayonP3'

export default function Sidebar() {
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const {t} = useTranslation('instituteSidebar')

  // Check if single department is selected and id is avalible; if not redirect to dashboard
  let isDepartmentLevelSelected = false
  if (useRouteMatch({path: MANAGE_DEPARTMENT})?.params?.departmentId)
    isDepartmentLevelSelected = true

  // Select between main or department sidebar
  let finalSidebarItems = isDepartmentLevelSelected
    ? DEPARTMENT_SIDEBAR_ITEMS
    : SIDEBAR_ITEMS

  // Check for permissions
  finalSidebarItems = finalSidebarItems.filter(({permissionId}) => {
    if (!permissionId) return true
    return checkPermission(state, permissionId)
  })

  return (
    <div
      className={styles.sidebarOuterWrapper}
      onClick={() => dispatch(hideSidebarAction())}
    >
      <div className={styles.sidebarWrapper}>
        {isDepartmentLevelSelected && (
          <div
            onClick={() => history.push(DEPARTMENTS)}
            className={styles.backToDepartmentsWrapper}
          >
            <Icon name="backArrow" type="filled" size="xxs" color="secondary" />
            <KrayonP3 className={classNames(styles.backToDepartmentsLabel)}>
              {t('backToDepartments')}
            </KrayonP3>
          </div>
        )}

        <div>
          {finalSidebarItems
            .filter(({active}) => active)
            .map(({id, label, icon, route, childRoutes}) => {
              // Check for current route
              let isActiveTab = matchPath(window.location.pathname, {
                path: route,
              })?.isExact

              // Check for parent route
              !isActiveTab &&
                childRoutes?.forEach((path) => {
                  if (matchPath(window.location.pathname, {path})?.isExact)
                    isActiveTab = true
                })

              return (
                <div key={id}>
                  <Link
                    className={classNames(
                      styles.sidebarItemLink,
                      isActiveTab ? styles.sidebarItemLinkSelected : ''
                    )}
                    onClick={() => dispatch(hideSidebarAction())}
                    to={updatePathWithParams(
                      route,
                      matchPath(window.location.pathname, {
                        path: MANAGE_DEPARTMENT,
                      })?.params || {}
                    )}
                  >
                    <div className={styles.sidebarItemLinkInner}>
                      <Icon
                        name={icon}
                        type="filled"
                        size="xs"
                        color={`${isActiveTab ? 'primary' : 'basic'}`}
                      />

                      <div
                        className={classNames(
                          'tm-n-para-16',
                          isActiveTab ? 'tm-stencil-color-primary-1' : '',
                          styles.sidebarItemLabel
                        )}
                      >
                        {t(label)}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
