import {
  PERMISSION_CONSTANTS,
  VARIABLE_CONSTANTS,
} from '../constants/permissions.constants'

export const isDependencyClear = (dependencyObj, data) => {
  for (let i = 0; i < dependencyObj?.length; i++) {
    data = data?.[dependencyObj?.[i]]

    // Return "false" if data is null
    if (!data) return false
  }
  return data
}

export const getValueFromLoop = (permissionObjRoute, data, state) => {
  for (let i = 0; i < permissionObjRoute?.length; i++) {
    // Check if dynamic variable is present
    if (permissionObjRoute?.[i]?.[0] === ':') {
      // Get the route obj from VARIABLE_CONSTANTS
      const variableObj = VARIABLE_CONSTANTS[permissionObjRoute?.[i]]

      // Return "false' if route obj not present
      if (!variableObj) return false

      // Find the value using route object
      const variableValue = getValueFromLoop(
        variableObj?.objRoute,
        state,
        state
      )

      // Return "false' if variable value not present
      if (variableValue === false) return false

      // Update data to value
      data = data?.find(({_id}) => _id === variableValue)
    } else {
      data = data[permissionObjRoute[i]]
    }

    // Return "false" if  data is null
    if (!data) return false
  }
  return data
}

export const checkPermission = (state, permissionId) => {
  const permissionObj = PERMISSION_CONSTANTS[permissionId]

  // Return false if permission id do not exist
  if (!permissionObj) return false

  // Check if data is avalible
  if (!isDependencyClear(permissionObj?.dependency, state)) return true

  // Get roles object
  const roles = getValueFromLoop(permissionObj?.objRoute, state, state)

  // Return true if permission exist
  if (roles?.permission_ids?.includes(permissionId)) return true

  return false
}
