import {call, put, takeEvery} from 'redux-saga/effects'
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../redux/actions/common.actions'
import * as Api from './apiServices'
import globalActions from '../../../../redux/actions/global.actions'

function* getDepartmentCourses({data, successAction, failureAction}) {
  try {
    const response = yield call(
      Api.utilsGetDepartmentCourses,
      data.departmentId
    )
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.getDepartmentCourses.success(response?.data?.obj))
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getDepartmentCourses.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetDepartmentCoursesSaga() {
  yield takeEvery(
    globalActions.getDepartmentCourses.REQUEST,
    getDepartmentCourses
  )
}

function* addDepartmentCourse({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsAddDepartmentCourse, data)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.addDepartmentCourse.success(response?.data?.obj))
      yield put(
        showSuccessToast(
          `${response?.data?.name ? 'Updated' : 'Added'} successfully`
        )
      )
      yield put({type: globalActions.getDepartmentCourses.REQUEST})
    }
  } catch (error) {
    failureAction?.()
    yield put(globalActions.addDepartmentCourse.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchAddDepartmentCourseSaga() {
  yield takeEvery(
    globalActions.addDepartmentCourse.REQUEST,
    addDepartmentCourse
  )
}
