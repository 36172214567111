import React from 'react'
import {matchPath, Route, Switch} from 'react-router-dom'
import LinearTabOptions from '../../../../components/common/LinearTabOptions/LinearTabOptions'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__COURSES,
  MANAGE_DEPARTMENT__COURSES__COURSE_MASTER,
  MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS,
} from '../../../../constants/pages.constants'
import {useTranslation} from 'react-i18next'
import styles from './DepartmentCourses.module.css'
import CoursesPage from './components/CoursesPage/CoursesPage'
import CourseMasterPage from './components/CourseMasterPage/CourseMasterPage'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../utils/routing.helpers'
import history from '../../../../utils/history'

export default function DepartmentCourses() {
  const {t} = useTranslation()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  // Redirect to course offerings tab if courses url selected
  if (
    matchPath(window.location.pathname, {path: MANAGE_DEPARTMENT__COURSES})
      .isExact
  )
    history.replace(
      updatePathWithParams(MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS, params)
    )

  const tabOptions = [
    {
      route: updatePathWithParams(
        MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS,
        params
      ),
      isSelected: matchPath(window.location.pathname, {
        path: MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS,
      }),
      label: t('DEPARTMENT_COURSES__COURSES_TAB_LABEL'),
    },
    {
      route: updatePathWithParams(
        MANAGE_DEPARTMENT__COURSES__COURSE_MASTER,
        params
      ),
      isSelected: matchPath(window.location.pathname, {
        path: MANAGE_DEPARTMENT__COURSES__COURSE_MASTER,
      }),
      label: t('DEPARTMENT_COURSES__COURSE_MASTER_TAB_LABEL'),
    },
  ]

  return (
    <div>
      <LinearTabOptions options={tabOptions} isRouteBased={true} />

      <Route
        path={MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS}
        component={CoursesPage}
      />
      <Switch>
        <Route
          path={MANAGE_DEPARTMENT__COURSES__COURSE_MASTER}
          component={CourseMasterPage}
        />
      </Switch>
    </div>
  )
}
