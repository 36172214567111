import React, {useEffect, useState} from 'react'
import EmptyScreen from '../../../../components/common/EmptyScreen/EmptyScreen'
import history from '../../../../utils/history'
import styles from './InstituteSessionList.module.css'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {INSTITUTE_SESSION__ADD} from '../../../../constants/pages.constants'
import {Table} from '@teachmint/common'
import {INSTITUTE_SESSION_LIST_TABLE_HEADERS} from '../../instituteSession.constants'
import classNames from 'classnames'
import ListCard from '../../../../components/common/ListCard/ListCard'
import SearchBar from '../../../../components/common/SearchBar/SearchBar'
import {
  YYYY_MM_DD_FORMAT,
  timestampToFormat,
} from '../../../../utils/datetime.helpers'

export default function InstituteSessionList() {
  const [filteredSessions, setFilteredSessions] = useState([])
  const [searchText, setSearchText] = useState('')

  const {instituteSessionInfo} = useSelector((state) => state)
  const {t} = useTranslation()

  useEffect(() => {
    setFilteredSessions(instituteSessionInfo?.sessionList)
  }, [instituteSessionInfo?.sessionList])

  const getRows = (sessions) => {
    let rows = []

    sessions?.forEach(({_id, name, start_date, end_date}) => {
      rows.push({
        id: _id,
        name: <div className="tm-hdg-14">{name}</div>,
        startDate: (
          <div className="tm-hdg-14">
            {timestampToFormat(YYYY_MM_DD_FORMAT, start_date)}
          </div>
        ),
        endDate: (
          <div className="tm-hdg-14">
            {timestampToFormat(YYYY_MM_DD_FORMAT, end_date)}
          </div>
        ),
        manage: (
          <div
            className={classNames(
              'tm-para-14 tm-cr-bl-2',
              styles.sessionListManage
            )}
            onClick={() => {}}
          >
            {t('COMMON_MANAGE')}
          </div>
        ),
      })
    })
    return rows
  }

  const handleSearchFilter = (text) => {
    setSearchText(text)

    if (text === '') setFilteredSessions(instituteSessionInfo?.sessionList)
    else {
      let tempArray = instituteSessionInfo?.sessionList?.filter(({name}) =>
        name?.toLowerCase()?.includes(text)
      )
      setFilteredSessions(tempArray)
    }
  }

  return (
    <div>
      {instituteSessionInfo?.sessionList?.length >= 0 ? (
        <div>
          <ListCard
            title={`${t('INSTITUTE_SESSION_LIST__HEADER_LABEL')} : ${
              instituteSessionInfo?.sessionList?.length
            }`}
          >
            <div className={styles.subHeaderWrapper}>
              <div className={styles.subHeaderSearchbarWrapper}>
                <SearchBar
                  value={searchText}
                  placeholder={t('INSTITUTE_SESSION_LIST__SEARCH_PLACEHOLDER')}
                  handleSearchFilter={handleSearchFilter}
                />
              </div>

              <div
                className={classNames(
                  'tm-para-14 tm-cr-bl-2',
                  styles.addAction
                )}
                onClick={() => history.push(INSTITUTE_SESSION__ADD)}
              >
                {t('INSTITUTE_SESSION_LIST__ADD_DEPARTMENT_ACTION')}
              </div>
            </div>

            <Table
              rows={getRows(filteredSessions)}
              cols={INSTITUTE_SESSION_LIST_TABLE_HEADERS}
            />
          </ListCard>
        </div>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('INSTITUTE_SESSION_LIST__EMPTY_LABEL')}
            desc={t('INSTITUTE_SESSION_LIST__EMPTY_DESC')}
            btnOptions={{
              label: t('INSTITUTE_SESSION_LIST__EMPTY_BTN_LABEL'),
              action: () => history.push(INSTITUTE_SESSION__ADD),
            }}
          />
        </div>
      )}
    </div>
  )
}
