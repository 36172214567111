import React from 'react'
import styles from './DateField.module.css'

export default function DateField({
  placeholder,
  value,
  handleChange,
  fieldName,
  disabled,
  max,
  min,
}) {
  return (
    <div>
      <input
        type="date"
        className={styles.textInputBox}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange({fieldName, value: e.target.value})}
        max={max ? max : undefined}
        disabled={disabled}
        min={min ? min : undefined}
      />
    </div>
  )
}
