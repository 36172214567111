import * as PC from './pages.constants'
import {GLOBAL_PERMISSION_IDS} from './permissions.constants'

export const GLOBAL_SIDEBAR_ITEMS = {
  DASHBOARD: {
    id: 'DASHBOARD',
    label: 'dashboardLabel',
    icon: 'dashboard',
    active: true,
    route: PC.DASHBOARD,
    eventName: '',
    permissionId: '',
  },
  DEPARTMENTS: {
    id: 'DEPARTMENTS',
    label: 'departmentLabel',
    icon: 'institute',
    active: true,
    route: PC.DEPARTMENTS,
    permissionId: GLOBAL_PERMISSION_IDS.DEPARTMENT__GET,
    child: {
      ADD_DEPARTMENT: {
        id: 'ADD_DEPARTMENT',
        label: 'addDepartmentLabel',
        route: PC.DEPARTMENTS__ADD,
      },
      MANAGE_DEPARTMENT: {
        id: 'MANAGE_DEPARTMENT',
        label: '',
        route: PC.MANAGE_DEPARTMENT,
        child: {
          DEPARTMENT_DASHBOARD: {
            id: 'DEPARTMENT_DASHBOARD',
            label: 'dashboardLabel',
            icon: 'dashboard',
            active: true,
            route: PC.MANAGE_DEPARTMENT,
            eventName: '',
          },
          DEPARTMENT_MANAGE_USER: {
            id: 'DEPARTMENT_MANAGE_USER',
            label: 'manageUserLabel',
            icon: 'users',
            active: true,
            route: PC.MANAGE_DEPARTMENT__MANAGE_USER,
            eventName: '',
            childRoutes: [
              PC.MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS,
              PC.MANAGE_DEPARTMENT__MANAGE_USER__FACULTY,
              PC.MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS__ADD,
              PC.MANAGE_DEPARTMENT__MANAGE_USER__FACULTY__ADD,
            ],
          },
          DEPARTMENT_PROGRAMS: {
            id: 'DEPARTMENT_PROGRAMS',
            label: 'departmentProgramLabel',
            icon: 'configure',
            active: true,
            route: PC.MANAGE_DEPARTMENT__PROGRAMS,
            eventName: '',
            childRoutes: [
              PC.MANAGE_DEPARTMENT__PROGRAMS__ADD,
              PC.MANAGE_DEPARTMENT__PROGRAMS__ADD_BATCH,
            ],
          },
          DEPARTMENT_COURSES: {
            id: 'DEPARTMENT_COURSES',
            label: 'departmentCourseLabel',
            icon: 'book',
            active: true,
            route: PC.MANAGE_DEPARTMENT__COURSES,
            eventName: '',
            childRoutes: [
              PC.MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS,
              PC.MANAGE_DEPARTMENT__COURSES__COURSE_OFFERINGS__ADD,
              PC.MANAGE_DEPARTMENT__COURSES__COURSE_MASTER,
              PC.MANAGE_DEPARTMENT__COURSES__COURSE_MASTER__ADD,
            ],
          },
        },
      },
    },
    childRoutes: [PC.DEPARTMENTS__ADD],
    eventName: '',
  },
  INSTITUTE_SESSION: {
    id: 'INSTITUTE_SESSION',
    label: 'sessionLabel',
    icon: 'users',
    active: true,
    route: PC.INSTITUTE_SESSION,
    eventName: '',
    permissionId: GLOBAL_PERMISSION_IDS.INSTITUTE_SEESION__GET,
  },
  MANAGE_USER: {
    id: 'MANAGE_USER',
    label: 'manageUserLabel',
    icon: 'users',
    active: false,
    route: PC.MANAGE_USER,
    eventName: '',
    permissionId: '',
  },
  MY_COURSES: {
    id: 'MY_COURSES',
    label: 'myCourseLabel',
    icon: 'users',
    active: false,
    route: PC.MY_COURSES,
    eventName: '',
    permissionId: '',
  },
}

export const SIDEBAR_ITEMS = [
  GLOBAL_SIDEBAR_ITEMS.DASHBOARD,
  GLOBAL_SIDEBAR_ITEMS.DEPARTMENTS,
  GLOBAL_SIDEBAR_ITEMS.INSTITUTE_SESSION,
  GLOBAL_SIDEBAR_ITEMS.MANAGE_USER,
  GLOBAL_SIDEBAR_ITEMS.MY_COURSES,
]

export const DEPARTMENT_SIDEBAR_ITEMS = [
  // GLOBAL_SIDEBAR_ITEMS.DEPARTMENTS.child.MANAGE_DEPARTMENT.child
  //   .DEPARTMENT_DASHBOARD,
  GLOBAL_SIDEBAR_ITEMS.DEPARTMENTS.child.MANAGE_DEPARTMENT.child
    .DEPARTMENT_MANAGE_USER,
  GLOBAL_SIDEBAR_ITEMS.DEPARTMENTS.child.MANAGE_DEPARTMENT.child
    .DEPARTMENT_PROGRAMS,
  GLOBAL_SIDEBAR_ITEMS.DEPARTMENTS.child.MANAGE_DEPARTMENT.child
    .DEPARTMENT_COURSES,
]
