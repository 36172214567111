import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  taglineInput: Joi.string().min(0).max(50).label('Tagline'),
  tagline: Joi.string().required().max(50).label('Tagline'),

  coursePrefixInput: Joi.string().min(0).max(50).label('Course Prefix'),
  coursePrefix: Joi.string().allow('').max(50).label('Course Prefix'),

  phoneNumberInput: Joi.string()
    .min(0)
    .max(10)
    .pattern(/^\d*$/)
    .label('Phone number'),
  phoneNumber: Joi.string()
    .allow('')
    .length(10)
    .pattern(/^\d*$/)
    .label('Phone number'),

  emailInput: Joi.string().max(100).label('Email Address'),
  email: Joi.string()
    .allow('')
    .max(100)
    .email({tlds: {allow: false}})
    .label('Email Address'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  tagline: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_TAGLINE_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_TAGLINE_PLACEHOLDER',
    fieldName: 'tagline',
    options: {
      inputValidation: VALIDATION_SCHEMA.taglineInput,
      finalValidation: VALIDATION_SCHEMA.tagline,
    },
  },
  course_prefix: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PREFIX_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PREFIX_PLACEHOLDER',
    fieldName: 'course_prefix',
    options: {
      inputValidation: VALIDATION_SCHEMA.coursePrefixInput,
      finalValidation: VALIDATION_SCHEMA.coursePrefix,
    },
  },
  phone_number: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PHONE_NUMBER_LABEL',
    placeholder:
      'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PHONE_NUMBER_PLACEHOLDER',
    fieldName: 'phone_number',
    options: {
      inputValidation: VALIDATION_SCHEMA.phoneNumberInput,
      finalValidation: VALIDATION_SCHEMA.phoneNumber,
    },
  },
  email: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_EMAIL_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_EMAIL_PLACEHOLDER',
    fieldName: 'email',
    options: {
      inputValidation: VALIDATION_SCHEMA.emailInput,
      finalValidation: VALIDATION_SCHEMA.email,
    },
  },
}

export const ADD_DEPARTMENT_FIELDS = {
  name: MODAL_FIELDS.name,
  course_prefix: MODAL_FIELDS.course_prefix,
}
export const DEFAULT_ADD_DEPARTMENT_FIELDS_VALUES = {
  name: '',
  course_prefix: '',
}
