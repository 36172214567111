import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {
  MANAGE_DEPARTMENT__PROGRAMS__ADD,
  MANAGE_DEPARTMENT__PROGRAMS__ADD_BATCH,
} from '../../../../constants/pages.constants'
import DepartmentProgramList from './components/DepartmentProgramList/DepartmentProgramList'
import SliderAddProgram from './components/SliderAddProgram/SliderAddProgram'
import SliderAddProgramBatch from './components/SliderAddProgramBatch/SliderAddProgramBatch'
import styles from './DepartmentPrograms.module.css'

export default function DepartmentPrograms() {
  return (
    <div className={styles.wrapper}>
      <DepartmentProgramList />

      <Switch>
        <Route
          exact
          path={MANAGE_DEPARTMENT__PROGRAMS__ADD}
          component={SliderAddProgram}
        />
        <Route
          exact
          path={MANAGE_DEPARTMENT__PROGRAMS__ADD_BATCH}
          component={SliderAddProgramBatch}
        />
      </Switch>
    </div>
  )
}
