import axios from 'axios'
import {REACT_APP_API_URL} from '../../../../constants/envVars.constants'

export const utilsGetCourseRecordings = async (classroomId) => {
  let params = new URLSearchParams()
  params.append('node_id', classroomId)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}tfile/recordings`,
    params,
  })
  return res
}
