export const loginPage = {
  mainHeading: 'Welcome!',
  forgotPassword: 'Forgot Password?',
  usernameLabel: 'Enter your username/email',
  usernamePlaceholder: 'Eg. krati or kratiagrawal2000@gmail.com',
  passwordLabel: 'Enter password',
  passwordPlaceholder: 'Type here',
  agreeText: 'By continuing, you agree to our',
  termsCondition: 'Terms and Conditions',
  privacyPolicy: 'Privacy Policy',

  // Forgot password page
  forgotPasswordMainHeading: 'Forgot Password',
  forgotPasswordEmailLabel: 'Enter your email ID',
  forgotPasswordEmailPlaceholder: 'Eg. kratiagrawal2000@gmail.com',

  // Temporary password page
  LOGIN__TEMPORARY_PASSWORD__HEADING: 'Password Sent!',
  LOGIN__TEMPORARY_PASSWORD__PARAGRAPH:
    'We have sent a temporary password to your email account. Enter that password below to login and create a new password',
  LOGIN__TEMPORARY_PASSWORD__PASSWORD_LABEL: 'Temporary Password',
  LOGIN__TEMPORARY_PASSWORD__PASSWORD_PLACEHOLDER: 'Type here',
  LOGIN__TEMPORARY_PASSWORD__NOT_RECEIVED: 'Did not receive email?',
  LOGIN__TEMPORARY_PASSWORD__RESEND_PASSWORD: ' Resend Password',

  // Reset password page
  LOGIN__RESET_PASSWORD__HEADING: 'Reset Password',
  LOGIN__RESET_PASSWORD__PASSWORD_LABEL: 'New Password',
  LOGIN__RESET_PASSWORD__PASSWORD_PLACEHOLDER: 'Type here',
  LOGIN__RESET_PASSWORD__CONFIRM_PASSWORD_LABEL: 'Confirm Password',
  LOGIN__RESET_PASSWORD__CONFIRM_PASSWORD_PLACEHOLDER: 'Type here',
  LOGIN__RESET_PASSWORD__PASSWORD_NOT_MATCH_ERROR: 'Password doesn’t match',
  LOGIN__RESET_PASSWORD__SUBMIT_BUTTON_TEXT: 'Reset Password',
}
