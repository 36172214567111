import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonCard.module.css'

export default function KrayonCard({size, className, children}) {
  return (
    <div className={classNames(styles.card, styles[size], className)}>
      {children}
    </div>
  )
}

KrayonCard.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

KrayonCard.defaultProps = {
  size: 'medium',
}
