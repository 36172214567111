import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  instituteSessionId: Joi.string().required().not('select').label('Session'),

  parentId: Joi.string().required().not('select').label('Course'),

  sectionNumInput: Joi.string().min(0).max(1).pattern(/^\d*$/).label('Section'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  node_id: {
    fieldType: 'select',
    label: 'SLIDER_ADD_COURSE_OFFERING__COURSE_MASTER_LABEL',
    placeholder: 'SLIDER_ADD_COURSE_OFFERING__COURSE_MASTER_PLACEHOLDER',
    fieldName: 'node_id',
    options: {finalValidation: VALIDATION_SCHEMA.parentId, options: []},
  },
  institute_session_id: {
    fieldType: 'select',
    label: 'SLIDER_ADD_COURSE_OFFERING__SESSION_LABEL',
    placeholder: 'SLIDER_ADD_COURSE_OFFERING__SESSION_PLACEHOLDER',
    fieldName: 'institute_session_id',
    options: {
      finalValidation: VALIDATION_SCHEMA.instituteSessionId,
      options: [],
    },
  },
  section_num: {
    fieldType: 'text',
    label: 'SLIDER_ADD_COURSE_OFFERING__COURSE_SECTION_NUM_LABEL',
    placeholder: 'SLIDER_ADD_COURSE_OFFERING__COURSE_SECTION_NUM_PLACEHOLDER',
    fieldName: 'section_num',
    options: {inputValidation: VALIDATION_SCHEMA.sectionNumInput},
  },
}

export const ADD_DEPARTMENT_COURSE_OFFERING_FIELDS = {
  node_id: MODAL_FIELDS.node_id,
  institute_session_id: MODAL_FIELDS.institute_session_id,
  // section_num: MODAL_FIELDS.section_num,
}
export const DEFAULT_ADD_DEPARTMENT_COURSE_OFFERING_FIELDS_VALUES = {
  node_id: 'select',
  institute_session_id: 'select',
  // section_num: 1,
}
