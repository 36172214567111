import React from 'react'
import {hideToast} from '../../../redux/actions/common.actions'
import {ToastStack} from '@teachmint/common'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import styles from './AppToastStack.module.css'

function AppToastStack() {
  const toasts = useSelector((state) => {
    return Object.values(state.toasts)
  }, shallowEqual)

  const dispatch = useDispatch()

  return (
    <ToastStack
      className={styles.wrapper}
      autoClose
      toastList={toasts}
      onCloseClick={(id) => dispatch(hideToast({id}))}
    />
  )
}

export default AppToastStack
