import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonH6.module.css'

export default function KrayonH6({type, className, children}) {
  return (
    <h6 className={classNames(styles.heading, styles[type], className)}>
      {children}
    </h6>
  )
}

KrayonH6.propTypes = {
  type: PropTypes.oneOf(['bold', 'semibold']),
}

KrayonH6.defaultProps = {
  type: 'semibold',
}
