import React, {useState} from 'react'
import {Button, Input} from '@teachmint/common'
import {useDispatch} from 'react-redux'
import SliderScreen from '../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../utils/history'
import styles from './SliderAddInstituteSession.module.css'
import {useTranslation} from 'react-i18next'
import {
  ADD_INSTITUTE_SESSION_FIELDS,
  DEFAULT_ADD_INSTITUTE_SESSION_FIELDS_VALUES,
} from './addInstituteSessionFormData'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../utils/validation'
import {getFromSessionStorage} from '../../../../utils/storage.helpers'
import InputField from '../../../../components/common/InputField/InputField'
import {
  formatToTimestamp,
  YYYY_MM_DD_FORMAT,
} from '../../../../utils/datetime.helpers'
import {produce} from 'immer'
import globalActions from '../../../../redux/actions/global.actions'

export default function SliderAddInstituteSession() {
  const [formData, setFormData] = useState(
    DEFAULT_ADD_INSTITUTE_SESSION_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleInputChange = ({fieldName, value}) => {
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_INSTITUTE_SESSION_FIELDS,
        fieldName,
        value
      )
    )
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    let isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_INSTITUTE_SESSION_FIELDS,
      formData
    )

    const finalFormData = produce(formData, (draft) => {
      draft.start_date = formatToTimestamp(YYYY_MM_DD_FORMAT, draft.start_date)
      draft.end_date = formatToTimestamp(YYYY_MM_DD_FORMAT, draft.end_date)
      return draft
    })

    // Check if end date greater than start date
    if (finalFormData?.start_date > finalFormData?.end_date) {
      isInputValid = false
      setErrorObject({
        ...errorObject,
        [ADD_INSTITUTE_SESSION_FIELDS.start_date.fieldName]: '',
        [ADD_INSTITUTE_SESSION_FIELDS.end_date.fieldName]:
          'End date must be greater than start date',
      })
    }

    const instituteId = getFromSessionStorage('INSTITUTE_ID')
    if (isInputValid && instituteId) {
      const successAction = () => {
        history.goBack()
      }

      dispatch(
        globalActions.addInstituteSession.request(
          {...finalFormData, node_id: instituteId},
          successAction
        )
      )
    }
  }

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_INSTITUTE_SESSION_LABEL')}
          />

          <SliderScreenBody>
            <div>
              <form>
                {Object.values(ADD_INSTITUTE_SESSION_FIELDS)?.map(
                  ({fieldType, fieldName, label, placeholder}) => (
                    <div key={fieldName} className={styles.inputWrapper}>
                      {fieldType === 'date' ? (
                        <InputField
                          type={fieldType}
                          title={t(label)}
                          fieldName={fieldName}
                          value={formData[fieldName]}
                          onChange={handleInputChange}
                          placeholder={t(placeholder)}
                          errorMsg={errorObject[fieldName]}
                        />
                      ) : (
                        <Input
                          type={fieldType}
                          title={t(label)}
                          fieldName={fieldName}
                          value={formData[fieldName]}
                          onChange={handleInputChange}
                          placeholder={t(placeholder)}
                          errorMsg={errorObject[fieldName]}
                        />
                      )}
                    </div>
                  )
                )}

                <Button
                  className={styles.submitButton}
                  size="medium"
                  onClick={handleFormSubmit}
                >
                  {t('COMMON_SUBMIT')}
                </Button>
              </form>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
