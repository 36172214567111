import axios from 'axios'
import {REACT_APP_API_URL} from '../../constants/envVars.constants'

export const utilslogin = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}login/auth`,
    data,
  })
  return res
}

export const utilsForgotPassword = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}login/send/email`,
    data,
  })
  return res
}

export const utilsVerifyTemporaryPassword = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}auth/verify/temporary/password`,
    data,
  })
  return res
}

export const utilsResetPassword = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}auth/reset/password`,
    data,
  })
  return res
}
