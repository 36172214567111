export const englishLang = {
  // Common words
  COMMON__REQUIRED: 'Required',
  COMMON_STEP: 'Step',
  COMMON_ADD: 'Add',
  COMMON_MANAGE: 'Manage',
  COMMON_SUBMIT: 'Submit',
  COMMON_BACK: 'Back',
  COMMON_NEXT: 'Next',
  COMMON_LOGIN: 'Login',

  // Backend Errors
  ERROR_1000: 'Something went wrong',

  ERROR_1200: 'Not Found',
  ERROR_1201: 'User Not Found',
  ERROR_1202: 'Unable to find department',
  ERROR_1203: 'Unable to find course',
  ERROR_1204: 'Unable to send email',
  ERROR_1205: 'Too many emails sent',
  ERROR_1206: 'Too many Attempts',

  ERROR_1207: 'Invalid credentials',

  ERROR_1209: 'IP address email requests exceeded',
  ERROR_1210: 'Username is not availaible',
  ERROR_1211: 'Unable to upload file',
  ERROR_1212: 'Announcement not found',

  ERROR_1213: 'Unable to verify recaptha',

  ERROR_1214: 'Unable to find Institute',
  ERROR_1215: 'Username or email already exists',

  ERROR_1216: 'User not found',

  ERROR_1217: 'contact your administrator',

  // Sidebar items

  // Department list page
  DEPARTMENT_LIST__EMPTY_LABEL: 'Start adding departments to your institute',
  DEPARTMENT_LIST__EMPTY_DESC:
    'There are no departments in your institute right now, departments added will appear here',
  DEPARTMENT_LIST__EMPTY_BTN_LABEL: 'Add Department',
  DEPARTMENT_LIST__HEADER_LABEL: 'Departments',
  DEPARTMENT_LIST__SEARCH_PLACEHOLDER: 'Search for departments',
  DEPARTMENT_LIST__ADD_DEPARTMENT_ACTION: 'Add Department',

  // Slider add department
  SLIDER_ADD_DEPARTMENT__LABEL: 'Add Department',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_NAME_LABEL: 'Department Name',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_NAME_PLACEHOLDER: 'Enter department name',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_TAGLINE_LABEL: 'Tagline',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_TAGLINE_PLACEHOLDER: 'Enter tagline',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PREFIX_ENABLED_LABEL:
    'Enable Course Prefix',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PREFIX_LABEL: 'Course Prefix',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PREFIX_PLACEHOLDER:
    'Enter course prefix',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PHONE_NUMBER_LABEL: 'Phone Number',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_PHONE_NUMBER_PLACEHOLDER:
    'Enter phone number',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_EMAIL_LABEL: 'Email',
  SLIDER_ADD_DEPARTMENT__DEPARTMENT_COURSE_EMAIL_PLACEHOLDER: 'Enter email',

  // Slider feedback form
  FEEDBACK__LABEL: 'Send Feedback',
  FEEDBACK__FEEDBACK_LABEL:
    'Your feedback is valuable, please tell us how can we improve your experience',
  FEEDBACK__FEEDBACK_PLACEHOLDER: 'Write your feedback here',

  // Dashboard steps section
  DASHBOARD__STEPS_SECTION__CREATE_SESSION: 'Create Session',
  DASHBOARD__STEPS_SECTION__CREATE_DEPARTMENT: 'Create Department',
  DASHBOARD__STEPS_SECTION__SETUP_DEPARTMENT: 'Setup Department',

  // Department program page list
  DEPARTMENT_PROGRAM__PROGRAM__EMPTY_LABEL:
    'Start adding programs to your department',
  DEPARTMENT_PROGRAM__PROGRAM__EMPTY_DESC:
    'There are no programs in your department right now, courses added will appear here',
  DEPARTMENT_PROGRAM__PROGRAM__EMPTY_BTN_LABEL: 'Add Program',
  DEPARTMENT_PROGRAM__PROGRAM__ADD_PROGRAM_BATCH: '+Add Batch',

  // Slider add department program
  SLIDER_ADD_DEPARTMENT_PROGRAM__LABEL: 'Add Program',
  SLIDER_ADD_DEPARTMENT_PROGRAM__NAME_LABEL: 'Program Name',
  SLIDER_ADD_DEPARTMENT_PROGRAM__NAME_PLACEHOLDER: 'Enter program name',
  SLIDER_ADD_DEPARTMENT_PROGRAM__DURATION_LABEL: 'Program Duration',
  SLIDER_ADD_DEPARTMENT_PROGRAM__DURATION_PLACEHOLDER: 'Enter program duration',

  // Slider add department progrm batch
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__LABEL: 'Add Program Batch',
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__NAME_LABEL: 'Program Batch Name',
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__NAME_PLACEHOLDER:
    'Enter program batch name',
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__START_TIME_LABEL: 'Start Date',
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__START_TIME_PLACEHOLDER:
    'Enter start date',
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__END_TIME_LABEL: 'End Date',
  SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__END_TIME_PLACEHOLDER: 'Enter end date',

  // Department course page
  DEPARTMENT_COURSES__COURSES_TAB_LABEL: 'Courses',
  DEPARTMENT_COURSES__COURSE_MASTER_TAB_LABEL: 'Course Master',

  // Department course master page list
  DEPARTMENT_COURSES__COURSE_MASTER__EMPTY_LABEL:
    'Start adding courses to your department',
  DEPARTMENT_COURSES__COURSE_MASTER__EMPTY_DESC:
    'There are no courses in your department right now, courses added will appear here',
  DEPARTMENT_COURSES__COURSE_MASTER__EMPTY_BTN_LABEL: 'Add Course',
  DEPARTMENT_COURSES__COURSE_MASTER__HEADER_LABEL: 'Courses',
  DEPARTMENT_COURSES__COURSE_MASTER__SEARCH_PLACEHOLDER: 'Search for Courses',
  DEPARTMENT_COURSES__COURSE_MASTER__ADD_COURSE_ACTION: '+Add Course',

  // Department course offering page list
  DEPARTMENT_COURSES__COURSE_OFFERING__EMPTY_LABEL:
    'Start adding course offerings to your department',
  DEPARTMENT_COURSES__COURSE_OFFERING__EMPTY_DESC:
    'There are no course offerings in your department right now, course offerings added will appear here',
  DEPARTMENT_COURSES__COURSE_OFFERING__EMPTY_BTN_LABEL: 'Add Course Offering',
  DEPARTMENT_COURSES__COURSE_OFFERING__ADD_COURSE_ACTION:
    '+Add Course Offering',

  // Slider add course offering
  SLIDER_ADD_COURSE_OFFERING__LABEL: 'Add Course Offering',
  SLIDER_ADD_COURSE_OFFERING__SESSION_LABEL: 'Select Session',
  SLIDER_ADD_COURSE_OFFERING__SESSION_PLACEHOLDER: 'Select session',
  SLIDER_ADD_COURSE_OFFERING__COURSE_MASTER_LABEL: 'Select Course',
  SLIDER_ADD_COURSE_OFFERING__COURSE_MASTER_PLACEHOLDER: 'Select course',
  SLIDER_ADD_COURSE_OFFERING__COURSE_SECTION_NUM_LABEL: 'Number of Section',
  SLIDER_ADD_COURSE_OFFERING__COURSE_SECTION_NUM_PLACEHOLDER:
    'Enter number of sections',
  SLIDER_ADD_COURSE_OFFERING__SECTION_LABEL: 'Add Sections',
  SLIDER_ADD_COURSE_OFFERING__SECTION_PLACEHOLDER: 'Enter section name',
  SLIDER_ADD_COURSE_OFFERING__ADD_SECTION_LABEL: '+Add section',

  // Slider add department course master
  SLIDER_ADD_COURSE_MASTER__LABEL: 'Add Course',
  SLIDER_ADD_COURSE_MASTER__NAME_LABEL: 'Course Name',
  SLIDER_ADD_COURSE_MASTER__NAME_PLACEHOLDER: 'Enter course name',
  SLIDER_ADD_COURSE_MASTER__CODE_LABEL: 'Course Code',
  SLIDER_ADD_COURSE_MASTER__CODE_PLACEHOLDER: 'Enter course code',
  SLIDER_ADD_COURSE_MASTER__COURSE_CREDIT_LABEL: 'Course Credits',
  SLIDER_ADD_COURSE_MASTER__COURSE_CREDIT_PLACEHOLDER: 'Enter course credit',

  // Slider add institute session
  SLIDER_ADD_INSTITUTE_SESSION_LABEL: 'Add Session',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_NAME_LABEL: 'Session Name',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_NAME_PLACEHOLDER: 'Enter session name',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_CODE_LABEL: 'Session Code',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_CODE_PLACEHOLDER: 'Enter session code',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_START_TIME_LABEL: 'Start Date',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_START_TIME_PLACEHOLDER:
    'Enter start date',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_END_TIME_LABEL: 'End Date',
  SLIDER_ADD_INSTITUTE_SESSION__SESSION_END_TIME_PLACEHOLDER: 'Enter end date',

  // Institute session list page
  INSTITUTE_SESSION_LIST__EMPTY_LABEL:
    'Start adding sessions to your institute',
  INSTITUTE_SESSION_LIST__EMPTY_DESC:
    'There are no sessions in your institute right now, sessions added will appear here',
  INSTITUTE_SESSION_LIST__EMPTY_BTN_LABEL: 'Add Session',
  INSTITUTE_SESSION_LIST__HEADER_LABEL: 'Sessions',
  INSTITUTE_SESSION_LIST__SEARCH_PLACEHOLDER: 'Search for sessions',
  INSTITUTE_SESSION_LIST__ADD_DEPARTMENT_ACTION: '+Add Session',

  // Course sidebar
  COURSE_SIDEBAR__BACK_TO_DASHBORD_LABEL: 'Back to Dashboard',

  // Course Sidebar Items
  COURSE_SIDEBAR_ITEMS__DASHBOARD_LABEL: 'Dashboard',
  COURSE_SIDEBAR_ITEMS__MANAGE_STUDENTS_LABEL: 'Students',
  COURSE_SIDEBAR_ITEMS__MANAGE_FACULTY_LABEL: 'Faculty',
  COURSE_SIDEBAR_ITEMS__RECORDINGS_LABEL: 'Recordings',

  // Department manage user
  DEPARTMENT_MANAGE_USER__STUDENT_TAB_LABEL: 'Students',
  DEPARTMENT_MANAGE_USER__FACULTY_TAB_LABEL: 'Faculty',

  // Slider add department student form data
  SLIDER_ADD_DEPARTMENT_STUDENT_LABEL: 'Add Students',
  SLIDER_ADD_DEPARTMENT_STUDENT__NAME_LABEL: 'Name',
  SLIDER_ADD_DEPARTMENT_STUDENT__NAME_PLACEHOLDER: 'Enter name',
  SLIDER_ADD_DEPARTMENT_STUDENT__USER_NAME_LABEL: 'Username',
  SLIDER_ADD_DEPARTMENT_STUDENT__USER_NAME_PLACEHOLDER: 'Enter username',
  SLIDER_ADD_DEPARTMENT_STUDENT__EMAIL_LABEL: 'Email',
  SLIDER_ADD_DEPARTMENT_STUDENT__EMAIL_PLACEHOLDER: 'Enter email',
  SLIDER_ADD_DEPARTMENT_STUDENT__PROGRAM_LABEL: 'Select Program',
  SLIDER_ADD_DEPARTMENT_STUDENT__PROGRAM_PLACEHOLDER: 'Select program',

  // Slider add department student form data
  SLIDER_ADD_DEPARTMENT_FACULTY_LABEL: 'Add Faculty',
  SLIDER_ADD_DEPARTMENT_FACULTY__NAME_LABEL: 'Name',
  SLIDER_ADD_DEPARTMENT_FACULTY__NAME_PLACEHOLDER: 'Enter name',
  SLIDER_ADD_DEPARTMENT_FACULTY__USER_NAME_LABEL: 'Username',
  SLIDER_ADD_DEPARTMENT_FACULTY__USER_NAME_PLACEHOLDER: 'Enter username',
  SLIDER_ADD_DEPARTMENT_FACULTY__EMAIL_LABEL: 'Email',
  SLIDER_ADD_DEPARTMENT_FACULTY__EMAIL_PLACEHOLDER: 'Enter email',

  // My courses page
  MY_COURSES__EMPTY_LABEL: 'You are not added to any course',
  MY_COURSES__EMPTY_DESC:
    'You are not added to any course, courses added will appear here',

  // Department student page
  DEPARTMENT_STUDENT_LIST__EMPTY_LABEL:
    'Start adding students to your department',
  DEPARTMENT_STUDENT_LIST__EMPTY_DESC:
    'There are no students in your department right now, students added will appear here',
  DEPARTMENT_STUDENT_LIST__EMPTY_BTN_LABEL: 'Add Students',
  DEPARTMENT_STUDENT_LIST__HEADER_LABEL: 'Students',
  DEPARTMENT_STUDENT_LIST__SEARCH_PLACEHOLDER: 'Search for students',
  DEPARTMENT_STUDENT_LIST__ADD_STUDENT_ACTION: '+Add Student',

  // Department faculty page
  DEPARTMENT_FACULTY_LIST__EMPTY_LABEL:
    'Start adding faculty to your department',
  DEPARTMENT_FACULTY_LIST__EMPTY_DESC:
    'There are no faculty in your department right now, faculty added will appear here',
  DEPARTMENT_FACULTY_LIST__EMPTY_BTN_LABEL: 'Add Faculty',
  DEPARTMENT_FACULTY_LIST__HEADER_LABEL: 'Faculty',
  DEPARTMENT_FACULTY_LIST__SEARCH_PLACEHOLDER: 'Search for faculty',
  DEPARTMENT_FACULTY_LIST__ADD_STUDENT_ACTION: '+Add Faculty',

  // Course students page
  COURSE_STUDENT_LIST__EMPTY_LABEL: 'Start adding students to your course',
  COURSE_STUDENT_LIST__EMPTY_DESC:
    'There are no students in your course right now, students added will appear here',
  COURSE_STUDENT_LIST__EMPTY_BTN_LABEL: 'Add Student',
  COURSE_STUDENT_LIST__HEADER_LABEL: 'Student',
  COURSE_STUDENT_LIST__SEARCH_PLACEHOLDER: 'Search for student',
  COURSE_STUDENT_LIST__ADD_STUDENT_ACTION: '+Add Student',

  // Course faculty page
  COURSE_FACULTY_LIST__EMPTY_LABEL: 'Start adding faculty to your course',
  COURSE_FACULTY_LIST__EMPTY_DESC:
    'There are no faculty in your course right now, faculty added will appear here',
  COURSE_FACULTY_LIST__EMPTY_BTN_LABEL: 'Add Faculty',
  COURSE_FACULTY_LIST__HEADER_LABEL: 'Faculty',
  COURSE_FACULTY_LIST__SEARCH_PLACEHOLDER: 'Search for Faculty',
  COURSE_FACULTY_LIST__ADD_FACULTY_ACTION: '+Add Faculty',

  // Course slider add student page
  SLIDER_ADD_COURSE_STUDENT_LABEL: 'Manage Students',

  // Course slider add faculty page
  SLIDER_ADD_COURSE_FACULTY_LABEL: 'Manage Faculty',
}
