import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonH5.module.css'

export default function KrayonH5({type, className, children}) {
  return (
    <h5 className={classNames(styles.heading, styles[type], className)}>
      {children}
    </h5>
  )
}

KrayonH5.propTypes = {
  type: PropTypes.oneOf(['bold', 'semibold']),
}

KrayonH5.defaultProps = {
  type: 'semibold',
}
