import {all} from 'redux-saga/effects'

import {
  watchGetInstituteInfoSaga,
  watchGetInstitutePublicInfoSaga,
} from '../../pages/InstituteInfo/instituteInfo.saga'

import {
  watchAddDepartmentProgramSaga,
  watchAddDepartmentProgramBatchSaga,
  watchGetDepartmentProgramsSaga,
} from '../../pages/DepartmentsPage/pages/DepartmentPrograms/departmentProgram.saga'

import {watchGetUserInfoSaga} from '../../pages/UserInfo/userInfo.saga'
import {
  watchGetDepartmentCoursesSaga,
  watchAddDepartmentCourseSaga,
} from '../../pages/DepartmentsPage/pages/DepartmentCourses/departmentCourses.saga'
import {
  watchGetDepartmentCourseMasterSaga,
  watchAddDepartmentCourseMasterSaga,
} from '../../pages/DepartmentsPage/pages/DepartmentCourses/departmentCourseMaster.saga'
import {
  watchAddDepartmentUserSaga,
  watchGetDepartmentUserSaga,
} from '../../pages/DepartmentsPage/pages/DepartmentManageUser/departmentManagerUser.saga'
import {
  watchAddRemoveCourseUsersSaga,
  watchGetCourseUsersSaga,
} from '../../pages/Course/pages/CourseStudents/courseUsers.saga'
import {watchGetCourseInfoSaga} from '../../pages/Course/course.saga'
import {watchGetCourseRecordingsSaga} from '../../pages/Course/pages/CourseRecordings/courseRecordings.saga'
import {
  watchAddDepartmentSaga,
  watchGetDepartmentsSaga,
} from '../../pages/DepartmentsPage/department.saga'
import {
  watchAddSessionSaga,
  watchGetSessionsSaga,
} from '../../pages/InstituteSession/instituteSession.saga'

// Add the sagas in this array
const sagasList = [
  watchGetInstitutePublicInfoSaga(),
  watchGetInstituteInfoSaga(),
  watchGetDepartmentsSaga(),
  watchAddDepartmentSaga(),
  watchGetSessionsSaga(),
  watchAddSessionSaga(),

  watchGetUserInfoSaga(),

  watchGetDepartmentProgramsSaga(),
  watchAddDepartmentProgramSaga(),
  watchAddDepartmentProgramBatchSaga(),

  watchGetDepartmentCoursesSaga(),
  watchAddDepartmentCourseSaga(),

  watchAddDepartmentCourseMasterSaga(),
  watchGetDepartmentCourseMasterSaga(),

  watchAddDepartmentUserSaga(),
  watchGetDepartmentUserSaga(),

  watchGetCourseUsersSaga(),
  watchAddRemoveCourseUsersSaga(),

  watchGetCourseInfoSaga(),

  watchGetCourseRecordingsSaga(),
]

// notice how we now only export the rootSaga
// single entry point to start all Sagas at once
export default function* rootSaga() {
  yield all(sagasList)
}
