import React, {useState} from 'react'
import {Button, Input} from '@teachmint/common'
import {useDispatch} from 'react-redux'
import SliderScreen from '../../../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../../../utils/history'
import styles from './SliderAddProgramBatch.module.css'
import {produce} from 'immer'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../../../utils/validation'
import {
  ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS,
  DEFAULT_ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS_VALUES,
} from './addProgramBatchFormData'
import InputField from '../../../../../../components/common/InputField/InputField'
import {
  formatToTimestamp,
  YYYY_MM_DD_FORMAT,
} from '../../../../../../utils/datetime.helpers'
import {matchPath, useLocation} from 'react-router-dom'

import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__PROGRAMS,
} from '../../../../../../constants/pages.constants'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'
import globalActions from '../../../../../../redux/actions/global.actions'
import {getDepartmentIdFromQuery} from '../../../../../../utils/storage.helpers'

export default function SliderAddProgramBatch() {
  const [formData, setFormData] = useState(
    DEFAULT_ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const search = useLocation()?.search
  const programId = new URLSearchParams(search).get('program_id')
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  const handleInputChange = ({fieldName, value}) => {
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS,
        fieldName,
        String(value)
      )
    )
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    let isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS,
      formData
    )

    const finalFormData = produce(formData, (draft) => {
      draft.start_date = formatToTimestamp(YYYY_MM_DD_FORMAT, draft.start_date)
      draft.end_date = formatToTimestamp(YYYY_MM_DD_FORMAT, draft.end_date)
      return draft
    })

    // Check if end date greater than start date
    if (finalFormData?.start_date > finalFormData?.end_date) {
      isInputValid = false
      setErrorObject({
        ...errorObject,
        [ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS.start_date.fieldName]: '',
        [ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS.end_date.fieldName]:
          'End date must be greater than start date',
      })
    }

    if (isInputValid && programId) {
      const successAction = () => {
        dispatch(
          globalActions?.getDepartmentPrograms?.request(params?.departmentId)
        )
        history.push(updatePathWithParams(MANAGE_DEPARTMENT__PROGRAMS, params))
      }

      dispatch(
        globalActions?.addDepartmentProgramBatch?.request(
          {...finalFormData, node_id: programId},
          successAction
        )
      )
    }
  }

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__LABEL')}
          />

          <SliderScreenBody>
            <div>
              <form>
                {Object.values(ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS)?.map(
                  ({fieldType, fieldName, label, placeholder}) => (
                    <div key={fieldName} className={styles.inputWrapper}>
                      {fieldType === 'date' ? (
                        <InputField
                          type={fieldType}
                          title={t(label)}
                          fieldName={fieldName}
                          value={formData[fieldName]}
                          onChange={handleInputChange}
                          placeholder={t(placeholder)}
                          errorMsg={errorObject[fieldName]}
                        />
                      ) : (
                        <Input
                          type={fieldType}
                          title={t(label)}
                          fieldName={fieldName}
                          value={formData[fieldName]}
                          onChange={handleInputChange}
                          placeholder={t(placeholder)}
                          errorMsg={errorObject[fieldName]}
                        />
                      )}
                    </div>
                  )
                )}

                <Button
                  className={styles.submitButton}
                  size="medium"
                  onClick={handleFormSubmit}
                >
                  {t('COMMON_SUBMIT')}
                </Button>
              </form>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
