import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './PasswordField.module.css'
import {useState} from 'react'
import KrayonIcon from '../../KrayonIcon/KrayonIcon'

export default function PasswordField({
  fieldName,
  value,
  onChange,
  placeholder,
  showFieldBorder,
  infoType,
  id,
}) {
  const [showPassword, setshowPassword] = useState(false)

  return (
    <div
      className={classNames(styles.inputWrapper)}
      data-status={showFieldBorder ? infoType : ''}
    >
      <input
        type={showPassword ? 'text' : 'password'}
        value={value}
        className={styles.input}
        onChange={(e) =>
          onChange({
            fieldName,
            value: String(e.target.value).trimLeft(),
            event: e,
          })
        }
        placeholder={placeholder}
        id={id}
      />
      <div
        className={styles.showButtonWrapper}
        onClick={() => setshowPassword(!showPassword)}
      >
        <KrayonIcon
          name={showPassword ? 'visibility' : 'visibility_off'}
          size="s"
        />
      </div>
    </div>
  )
}

PasswordField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  showFieldBorder: PropTypes.bool,
  infoType: PropTypes.string,
  id: PropTypes.string,
}

PasswordField.defaultProps = {
  fieldName: '',
  value: '',
  placeholder: '',
  onChange: () => {},
  showFieldBorder: false,
  infoType: '',
  id: '',
}
