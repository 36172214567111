export const commonActionTypes = {
  LOGOUT_USER: 'LOGOUT_USER',
  SHOW_SIDEBAR: 'SHOW_SIDEBAR',
  HIDE_SIDEBAR: 'HIDE_SIDEBAR',
  SHOW_TOAST: 'SHOW_TOAST',
  HIDE_TOAST: 'HIDE_TOAST',
  SHOW_FEEDBACK_SLIDER: 'SHOW_FEEDBACK_SLIDER',
  HIDE_FEEDBACK_SLIDER: 'HIDE_FEEDBACK_SLIDER',
  SET_SELECTED_INSTITUTE: 'SET_SELECTED_INSTITUTE',
}
