import React, {useState} from 'react'
import {Button, Input} from '@teachmint/common'
import {useDispatch} from 'react-redux'
import SliderScreen from '../../../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../../../utils/history'
import styles from './SliderAddCourseMaster.module.css'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../../../utils/validation'
import {
  ADD_DEPARTMENT_COURSE_MASTER_FIELDS,
  DEFAULT_ADD_DEPARTMENT_COURSE_MASTER_FIELDS_VALUES,
} from './addCourseMasterFormData'
import {matchPath} from 'react-router-dom'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__COURSES__COURSE_MASTER,
} from '../../../../../../constants/pages.constants'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'
import globalActions from '../../../../../../redux/actions/global.actions'

export default function SliderAddCourseMaster() {
  const [formData, setFormData] = useState(
    DEFAULT_ADD_DEPARTMENT_COURSE_MASTER_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_DEPARTMENT_COURSE_MASTER_FIELDS,
        fieldName,
        value
      )
    )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_DEPARTMENT_COURSE_MASTER_FIELDS,
      formData
    )

    if (isInputValid && params?.departmentId) {
      const successAction = () => {
        dispatch(
          globalActions?.getDepartmentCourseMaster?.request(
            params?.departmentId
          )
        )
        history.push(
          updatePathWithParams(
            MANAGE_DEPARTMENT__COURSES__COURSE_MASTER,
            params
          )
        )
      }

      dispatch(
        globalActions?.addDepartmentCourseMaster?.request(
          {...formData, node_id: params?.departmentId},
          successAction
        )
      )
    }
  }

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_COURSE_MASTER__LABEL')}
          />

          <SliderScreenBody>
            <div>
              <form>
                {Object.values(ADD_DEPARTMENT_COURSE_MASTER_FIELDS)?.map(
                  ({fieldType, fieldName, label, placeholder}) => (
                    <div key={fieldName} className={styles.inputWrapper}>
                      <Input
                        type={fieldType}
                        title={t(label)}
                        fieldName={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        placeholder={t(placeholder)}
                        errorMsg={errorObject[fieldName]}
                      />
                    </div>
                  )
                )}

                <Button
                  className={styles.submitButton}
                  size="medium"
                  onClick={handleFormSubmit}
                >
                  {t('COMMON_SUBMIT')}
                </Button>
              </form>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
