import React from 'react'
import {useSelector} from 'react-redux'
import StatsSection from './components/StatsSection/StatsSection'
import StepsSection from './components/Onboarding/Onboarding'

export default function Dashboard() {
  const {globalData, instituteSessionInfo} = useSelector((state) => state)
  const departmentExist =
      globalData?.instituteDepartment?.data?.length > 0 || false,
    sessionExist = instituteSessionInfo?.sessionList?.length > 0 || false

  return (
    <>{departmentExist && sessionExist ? <StatsSection /> : <StepsSection />}</>
    // <StepsSection />
  )
}
