import axios from 'axios'
import {REACT_APP_API_URL} from '../../../../constants/envVars.constants'

export const utilsGetDepartmentUsers = async (departmentId) => {
  let params = new URLSearchParams()
  params.append('node_id', departmentId)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}department/people`,
    params,
  })
  return res
}

export const utilsAddDepartmentUser = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}department/add/user`,
    data,
  })
  return res
}
