import React from 'react'
import styles from './SearchBar.module.css'
import KrayonIcon from '../KrayonIcon/KrayonIcon'

export default function SearchBar({value, placeholder, handleSearchFilter}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.searchIconWrapper}>
        <KrayonIcon name="search" size="s" />
      </div>

      <input
        type="text"
        placeholder={placeholder}
        value={value}
        className={styles.input}
        onInput={(e) =>
          handleSearchFilter(e.target.value.trimLeft().toLowerCase())
        }
      />

      {value && (
        <div
          className={styles.clearIconWrapper}
          onClick={() => handleSearchFilter('')}
        >
          <KrayonIcon name="close" size="s" />
        </div>
      )}
    </div>
  )
}
