import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonP3.module.css'

export default function KrayonP3({type, className, children, ...props}) {
  return (
    <p className={classNames(styles.para, styles[type], className)} {...props}>
      {children}
    </p>
  )
}

KrayonP3.propTypes = {
  type: PropTypes.oneOf(['light', 'regular', 'medium']),
}

KrayonP3.defaultProps = {
  type: 'regular',
}
