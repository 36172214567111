import React, {useEffect, useState} from 'react'
import {utilsForgotPassword} from './../../apiServices'
import styles from './ForgotPasswordPage.module.css'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../utils/validation'
import history from '../../../../utils/history'
import {
  LOGIN,
  LOGIN__TEMPORARY_PASSWORD_PAGE,
} from '../../../../constants/pages.constants'
import {
  DEFAULT_FORGOT_PASSWORD_FIELDS_VALUES,
  FORGOT_PASSWORD_FIELDS,
} from './forgotPasswordFormData'
import {REACT_APP_GOOGLE_RECAPTCHA} from '../../../../constants/envVars.constants'
import {Link} from 'react-router-dom'
import {
  getSubdomain,
  setToSessionStorage,
} from '../../../../utils/storage.helpers'
import KrayonInputField from '../../../../components/common/KrayonInputField/KrayonInputField'
import KrayonButton from '../../../../components/common/KrayonButton/KrayonButton'
import KaryonH3 from '../../../../components/common/KrayonH3/KrayonH3'
import KrayonP2 from '../../../../components/common/KrayonP2/KrayonP2'

export default function ForgotPasswordPage({setGlobalError, setIsLoading}) {
  const [formData, setFormData] = useState(
    DEFAULT_FORGOT_PASSWORD_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const {t} = useTranslation('loginPage')

  useEffect(() => {
    return () => setGlobalError('')
  }, [])

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(formData, FORGOT_PASSWORD_FIELDS, fieldName, value)
    )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      FORGOT_PASSWORD_FIELDS,
      formData
    )

    if (isInputValid)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_GOOGLE_RECAPTCHA, {action: 'submit'})
          .then((token) => {
            setIsLoading(true)
            utilsForgotPassword({
              ...formData,
              subdomain: getSubdomain(),
              recaptcha_token: token,
            })
              .then(({data}) => {
                if (data?.status && data?.obj?.uuid) {
                  setToSessionStorage('UNIVERSITY__EMAIL', formData?.email)
                  history.push(
                    `${LOGIN__TEMPORARY_PASSWORD_PAGE}?uuid=${data?.obj?.uuid}`
                  )
                } else
                  setGlobalError(t(`translation:ERROR_${data?.error_code}`))
              })
              .catch(() => setGlobalError(t('translation:ERROR_1000')))
              .finally(() => setIsLoading(false))
          })
      })
  }

  return (
    <form>
      <KaryonH3 className={styles.heading}>
        {t('forgotPasswordMainHeading')}
      </KaryonH3>

      {Object.values(FORGOT_PASSWORD_FIELDS)?.map(
        ({fieldType, fieldName, label, placeholder}) => (
          <KrayonInputField
            fieldType={fieldType}
            label={t(label)}
            fieldName={fieldName}
            value={formData[fieldName]}
            onChange={handleInputChange}
            placeholder={t(placeholder)}
            infoText={errorObject[fieldName]}
          />
        )
      )}

      <KrayonButton
        width="full"
        size="large"
        onClick={handleFormSubmit}
        className={styles.submitButton}
      >
        {t('translation:COMMON_LOGIN')}
      </KrayonButton>

      <Link to={LOGIN}>
        <KrayonP2 className={styles.backBtn}>
          {t('translation:COMMON_BACK')}
        </KrayonP2>
      </Link>
    </form>
  )
}
