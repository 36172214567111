import axios from 'axios'
import {REACT_APP_API_URL} from '../../constants/envVars.constants'

export const utilsGetCourseInfo = async (courseId) => {
  let params = new URLSearchParams()
  params.append('id', courseId)
  params.append('node_id', courseId)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}class-room`,
    params,
  })
  return res
}
