import React, {useEffect, useState} from 'react'
import styles from './Onboarding.module.css'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import OnbaordingSteps from './OnbaordingSteps/OnbaordingSteps'
import OnbaordingForms from './OnboardingForms/OnboardingForms'

const GLOBAL_STATUS = {
  SUCCESS: 'success',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

export default function StepsSection() {
  const [selectedStep, setSelectedStep] = useState('ADD_SESSION')

  const {globalData, instituteSessionInfo} = useSelector((state) => state)
  const {t} = useTranslation('instituteOnboarding')

  const departmentExist = true,
    sessionExist = true

  useEffect(() => {
    if (sessionExist && !departmentExist) setSelectedStep('ADD_DEPARTMENT')
  }, [globalData, instituteSessionInfo])

  const stepsItems = {
    ADD_SESSION: {
      label: t('sessionHeading'),
      active: true,
      iconName: 'event_note',
      status: sessionExist ? GLOBAL_STATUS.SUCCESS : GLOBAL_STATUS.ACTIVE,
      formHeading: t('sessionHeading'),
      formDesc: t('sessionDescription'),
    },
    ADD_DEPARTMENT: {
      label: t('departmentHeading'),
      active: true,
      iconName: 'class',
      status: sessionExist
        ? departmentExist
          ? GLOBAL_STATUS.SUCCESS
          : GLOBAL_STATUS.ACTIVE
        : GLOBAL_STATUS.INACTIVE,
      formHeading: t('departmentHeading'),
      formDesc: t('departmentDescription'),
    },
    SETUP_DEPARTMENT: {
      label: t('departmentSetup'),
      active: true,
      iconName: 'apartment',
      status:
        sessionExist && departmentExist
          ? GLOBAL_STATUS.ACTIVE
          : GLOBAL_STATUS.INACTIVE,
    },
  }

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.leftWrapper}>
          <OnbaordingSteps stepsItems={stepsItems} />
        </div>

        <div className={styles.rightWrapper}>
          <OnbaordingForms
            stepsItems={stepsItems}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
          />
        </div>
      </div>
    </div>
  )
}
