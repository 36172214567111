import {ErrorBoundary} from '@teachmint/common'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import styles from './Course.module.css'
import Feedback from '../Feedback/Feedback'
import Loader from '../../components/common/Loader/Loader'
import history from '../../utils/history'
import {LOGIN} from '../../constants/pages.constants'
import CourseContentArea from './components/CourseContentArea/CourseContentArea'
import CourseNavbar from './components/CourseNavbar/CourseNavbar'
import CourseSidebar from './components/CourseSidebar/CourseSidebar'

import {useParams} from 'react-router-dom'

import globalActions from '../../redux/actions/global.actions'

export default function Course() {
  const dispatch = useDispatch()
  const {showSidebar, courseInfo} = useSelector((state) => state)
  const params = useParams()

  // Get user info
  useEffect(() => {
    const successAction = () => {
      dispatch(globalActions?.getInstituteInfo?.request())
    }
    const failureAction = () => history.push(LOGIN)

    dispatch(globalActions?.getUserInfo?.request(successAction, failureAction))
  }, [])

  // Get course info and students list from course id
  useEffect(() => {
    if (params?.courseId) {
      dispatch(globalActions?.getCourseInfo?.request(params?.courseId))
      dispatch(globalActions?.getCourseUsers?.request(params?.courseId))
    }
  }, [])

  // Get department students list
  useEffect(() => {
    if (courseInfo?.courseInfo?.ancestors?.[2])
      dispatch(
        globalActions?.getDepartmentUser.request(
          courseInfo?.courseInfo?.ancestors?.[2]
        )
      )
  }, [courseInfo?.courseInfo?._id])

  return (
    <div className={styles.homeWrapper}>
      <Feedback />
      <Loader />

      <ErrorBoundary>
        {/* Sidebar for mobile */}
        <div className={styles.sidebarMobileWrapper}>
          <ErrorBoundary>
            {showSidebar ? <CourseSidebar /> : null}
          </ErrorBoundary>
        </div>

        <div className={styles.contentAreaWrapper}>
          <CourseNavbar />
          <div className={styles.mainAreaWrapper}>
            <div className={styles.sidebarLaptopWrapper}>
              <CourseSidebar />
            </div>
            <CourseContentArea />
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}
