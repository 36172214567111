import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import styles from './CourseRecordings.module.css'
import globalActions from '../../../../redux/actions/global.actions'

export default function CourseRecordings() {
  const dispatch = useDispatch()
  const params = useParams()
  const {recordingInfo} = useSelector((state) => state)

  useEffect(() => {
    if (params?.courseId)
      dispatch(globalActions?.getCourseRecordings?.request(params?.courseId))
  }, [params?.courseId])

  return (
    <div>CourseRecordings : {recordingInfo?.recordingsList?.length || 0}</div>
  )
}
