import Joi from 'joi'

export const validateInput = (validationSchema, value) => {
  if (!validationSchema) return {status: true}

  const schema = {test: validationSchema},
    values = {test: value}
  const {error} = Joi.object(schema)?.validate(values)

  return {status: error ? false : true, error: error?.message}
}

export const utilsHandleInputChange = (
  formData,
  fieldData,
  fieldName,
  value,
  indexArr = []
) => {
  const validationSchema = fieldData?.[fieldName]?.options?.inputValidation
  const validationResult = validateInput(validationSchema, value)

  // Function to update value
  const updateValue = (formData, indexArr, fieldName, value) => {
    if (indexArr?.length === 0) {
      formData[fieldName] = value
      return
    }
    const deletedElement = indexArr.splice(0, 1)
    updateValue(formData[deletedElement[0]], indexArr, fieldName, value)
  }

  // If validation success
  if (validationResult?.status) {
    let formDataTemp = JSON.parse(JSON.stringify(formData))
    updateValue(formDataTemp, indexArr, fieldName, value)
    return formDataTemp
  }
  return formData
}

export const utilsHandleSetError = (
  setErrorObject,
  fieldName,
  error,
  indexArr = []
) =>
  setErrorObject((errorObject) => ({...errorObject, ...{[fieldName]: error}}))

export const utilsFinalFormSubmitChecks = (setErrorObject, fields, data) => {
  let isInputValid = true
  const errorObject = {}

  const iterate = (data, errorObject) => {
    if (!Array.isArray(data)) {
      Object.values(fields).forEach(({fieldName, options}) => {
        const {status, error} = validateInput(
          options?.finalValidation,
          data[fieldName]
        )

        if (!status) {
          errorObject[fieldName] = error
          // utilsHandleSetError(setErrorObject, fieldName, error)
          isInputValid = false
        }
      })
      return
    }
    for (let i = 0; i < data?.length; i++) {
      errorObject[i] = {}
      iterate(data[i], errorObject[i])
    }
  }
  iterate(data, errorObject)

  setErrorObject(errorObject)
  return isInputValid
}
