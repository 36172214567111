import {actionCreator} from '../../utils/redux.helpers'

const GLOBAL_ACTIONS = [
  'getPublicInstituteInfo',
  'getInstituteInfo',
  'getInstituteDepartment',
  'addInstituteDepartment',
  'getInstituteSession',
  'addInstituteSession',

  'getUserInfo',

  'getCourseInfo',

  'getCourseRecordings',

  'getCourseUsers',
  'addremoveCourseUsers',

  'getDepartmentUser',
  'addDepartmentUser',

  'getDepartmentCourseMaster',
  'addDepartmentCourseMaster',

  'getDepartmentCourses',
  'addDepartmentCourse',

  'getDepartmentPrograms',
  'addDepartmentProgram',
  'addDepartmentProgramBatch',
]

const globalActions = {}

GLOBAL_ACTIONS.forEach((item) => {
  globalActions[item] = actionCreator(item)
})

export default globalActions
