import {call, put, takeEvery} from 'redux-saga/effects'
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../redux/actions/common.actions'
import * as Api from './apiServices'
import globalActions from '../../../../redux/actions/global.actions'

function* getDepartmentPrograms({data, successAction, failureAction}) {
  try {
    const response = yield call(
      Api.utilsGetDepartmentPrograms,
      data.departmentId
    )
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(
        globalActions.getDepartmentPrograms.success(response?.data?.obj)
      )
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getDepartmentPrograms.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetDepartmentProgramsSaga() {
  yield takeEvery(
    globalActions.getDepartmentPrograms.REQUEST,
    getDepartmentPrograms
  )
}

function* addDepartmentProgram({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsAddDepartmentProgram, data)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.addDepartmentProgram.success(response?.data?.obj))
      yield put(
        showSuccessToast(
          `${response?.data?.name ? 'Updated' : 'Added'} successfully`
        )
      )
      yield put({type: globalActions.getDepartmentPrograms.REQUEST})
    }
  } catch (error) {
    yield put(globalActions.addDepartmentProgram.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchAddDepartmentProgramSaga() {
  yield takeEvery(
    globalActions.addDepartmentProgram.REQUEST,
    addDepartmentProgram
  )
}

function* addDepartmentProgramBatch({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsAddDepartmentProgramBatch, data)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(
        globalActions.addDepartmentProgramBatch.success(response?.data?.obj)
      )
      yield put(
        showSuccessToast(
          `${response?.data?.name ? 'Updated' : 'Added'} successfully`
        )
      )
    }
  } catch (error) {
    failureAction?.()
    yield put(globalActions.addDepartmentProgramBatch.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchAddDepartmentProgramBatchSaga() {
  yield takeEvery(
    globalActions.addDepartmentProgramBatch.REQUEST,
    addDepartmentProgramBatch
  )
}
