import React from 'react'
import styles from './ListCard.module.css'
import classNames from 'classnames'

export default function ListCard({title = '', desc = '', children}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={classNames('tm-hdg-14 lg:tm-hdg-16')}>{title}</div>
        <div
          className={classNames('tm-para-12 lg:tm-para-14', styles.headerDesc)}
        >
          {desc}
        </div>
      </div>

      {children}
    </div>
  )
}
