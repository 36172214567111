import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  userNameInput: Joi.string().min(0).max(50).label('Username'),
  userName: Joi.string().required().max(50).label('Username'),

  emailInput: Joi.string().min(0).max(100).label('Email Address'),
  email: Joi.string()
    .max(100)
    .email({tlds: {allow: false}})
    .label('Email Address'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  user_name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__USER_NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__USER_NAME_PLACEHOLDER',
    fieldName: 'user_name',
    options: {
      inputValidation: VALIDATION_SCHEMA.userNameInput,
      finalValidation: VALIDATION_SCHEMA.userName,
    },
  },
  email: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__EMAIL_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__EMAIL_PLACEHOLDER',
    fieldName: 'email',
    options: {
      inputValidation: VALIDATION_SCHEMA.emailInput,
      finalValidation: VALIDATION_SCHEMA.email,
    },
  },
}

export const ADD_DEPARTMENT_FACULTY_FIELDS = {
  name: MODAL_FIELDS.name,
  user_name: MODAL_FIELDS.user_name,
  email: MODAL_FIELDS.email,
}
export const DEFAULT_ADD_DEPARTMENT_FACULTY_FIELDS_VALUES = {
  name: '',
  user_name: '',
  email: '',
}
