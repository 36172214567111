import React, {useEffect, useState} from 'react'
import {
  LOGIN,
  LOGIN__FORGOT_PASSWORD,
  LOGIN__RESET_PASSWORD,
  LOGIN__TEMPORARY_PASSWORD_PAGE,
} from '../../constants/pages.constants'
import {Route, Switch} from 'react-router-dom'
import LoginPage from './components/LoginPage/LoginPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import TemporaryPasswordPage from './pages/TemporaryPasswordPage/TemporaryPasswordPage'
import ResetPasswordPage from './pages/ResetPasswordPage/ResetPasswordPage'
import styles from './Login.module.css'
import {useDispatch, useSelector} from 'react-redux'
import Loader from '../../components/common/Loader/Loader'
import globalActions from '../../redux/actions/global.actions'
import KrayonCard from '../../components/common/KrayonCard/KrayonCard'
import KrayonP2 from '../../components/common/KrayonP2/KrayonP2'

export default function Login() {
  const [globalError, setGlobalError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()
  const {globalData} = useSelector((state) => state)

  useEffect(() => {
    dispatch(globalActions?.getPublicInstituteInfo?.request())
  }, [])

  return (
    <div className={styles.loginWrapper}>
      <Loader isLoading={isLoading} />

      <KrayonCard size="large" className={styles.loginCard}>
        <div className={styles.logoWrapper}>
          <img
            src={globalData?.publicInstituteInfo?.data?.cover_img_url}
            alt="logo"
            className={styles.logo}
          />
        </div>

        {globalError && (
          <KrayonP2 className={styles.errorText} color="error">
            {globalError}
          </KrayonP2>
        )}

        <Switch>
          <Route
            exact
            path={LOGIN}
            render={(props) => (
              <LoginPage
                {...props}
                setGlobalError={setGlobalError}
                setIsLoading={setIsLoading}
              />
            )}
          />
          <Route
            exact
            path={LOGIN__FORGOT_PASSWORD}
            render={(props) => (
              <ForgotPasswordPage
                {...props}
                setGlobalError={setGlobalError}
                setIsLoading={setIsLoading}
              />
            )}
          />
          <Route
            exact
            path={LOGIN__TEMPORARY_PASSWORD_PAGE}
            render={(props) => (
              <TemporaryPasswordPage
                {...props}
                setGlobalError={setGlobalError}
                setIsLoading={setIsLoading}
              />
            )}
          />
          <Route
            exact
            path={LOGIN__RESET_PASSWORD}
            render={(props) => (
              <ResetPasswordPage
                {...props}
                setGlobalError={setGlobalError}
                setIsLoading={setIsLoading}
              />
            )}
          />
        </Switch>
      </KrayonCard>
    </div>
  )
}
