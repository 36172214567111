import {reducerHandler} from '../../utils/redux.helpers'
import globalActions from '../actions/global.actions'

const actionReducerMapping = {
  getPublicInstituteInfo: 'publicInstituteInfo',
  getInstituteInfo: 'instituteInfo',
  getInstituteDepartment: 'instituteDepartment',
  addInstituteDepartment: 'addInstituteDepartment',
  getInstituteSession: 'instituteSession',
  addInstituteSession: 'addInstituteSession',

  getUserInfo: 'userInfo',
  getCourseInfo: 'courseInfo',
  getCourseRecordings: 'courseRecordings',

  getCourseUsers: 'courseUsers',
  addremoveCourseUsers: 'addRemoveCourseUsers',

  getDepartmentUser: 'departmentUser',
  addDepartmentUser: 'addDepartmentUser',

  getDepartmentCourseMaster: 'departmentCourseMaster',
  addDepartmentCourseMaster: 'addDepartmentCourseMaster',

  getDepartmentcourses: 'departmentCourses',
  addDepartmentCourse: 'addDepartmentCourse',

  getDepartmentPrograms: 'departmentPrograms',
  addDepartmentProgram: 'addDepartmentProgram',
  addDepartmentProgramBatch: 'addDepartmentProgramBatch',
}

// Initial State
const initialAsyncState = {
  isLoading: false,
  loaded: false,
  data: null,
  error: null,
}

// Initial Reducer State
const initialState = {}

//  Output -> {
//   publicInstituteInfo: initialAsyncState,
//   adminProfile: initialAsyncState,
//   ....
// }
Object.values(actionReducerMapping).forEach((item) => {
  initialState[item] = initialAsyncState
})

// Global Reducer
// action -> e.g: getUserProfile_RESUEST
export const globalReducers = (state = initialState, action) => {
  const actionParent = String(action.type).split('_')?.[0] // getUserProfile_RESUEST -> getUserProfile
  const actionReducerKey = actionReducerMapping[actionParent]

  if (actionReducerKey) {
    return {
      ...state,
      [actionReducerKey]: reducerHandler(
        state[actionReducerKey],
        action,
        globalActions[actionParent]
      ),
    }
  }

  return state
}
