import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  startDate: Joi.string().required().label('Start date'),

  endDate: Joi.string().required().label('End date'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  start_date: {
    fieldType: 'date',
    label: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__START_TIME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__START_TIME_PLACEHOLDER',
    fieldName: 'start_date',
    options: {
      finalValidation: VALIDATION_SCHEMA.startDate,
    },
  },
  end_date: {
    fieldType: 'date',
    label: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__END_TIME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_PROGRAM_BATCH__END_TIME_PLACEHOLDER',
    fieldName: 'end_date',
    options: {
      finalValidation: VALIDATION_SCHEMA.endDate,
    },
  },
}

export const ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS = {
  name: MODAL_FIELDS.name,
  start_date: MODAL_FIELDS.start_date,
  end_date: MODAL_FIELDS.end_date,
}
export const DEFAULT_ADD_DEPARTMENT_PROGRAM_BATCH_FIELDS_VALUES = {
  name: '',
  start_date: '',
  end_date: '',
}
