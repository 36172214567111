import {call, put, takeEvery} from 'redux-saga/effects'
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../redux/actions/common.actions'
import * as Api from './apiServices'
import globalActions from '../../../../redux/actions/global.actions'

function* getDepartmentUser({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsGetDepartmentUsers, data.departmentId)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.getDepartmentUser.success(response?.data?.obj))
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getDepartmentUser.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetDepartmentUserSaga() {
  yield takeEvery(globalActions.getDepartmentUser.REQUEST, getDepartmentUser)
}

function* addDepartmentUser({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsAddDepartmentUser, data)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.addDepartmentUser.success(response?.data?.obj))
      yield put(
        showSuccessToast(
          `${response?.data?.name ? 'Updated' : 'Added'} successfully`
        )
      )

      yield put({type: globalActions.getDepartmentUser.REQUEST})
    }
  } catch (error) {
    failureAction?.()
    yield put(globalActions.addDepartmentUser.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchAddDepartmentUserSaga() {
  yield takeEvery(globalActions.addDepartmentUser.REQUEST, addDepartmentUser)
}
