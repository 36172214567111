const {
  REACT_APP_BASE_URL,
  REACT_APP_API_URL,
  REACT_APP_API_HOST_URL,
  REACT_APP_ENV_TYPE,
  REACT_APP_GOOGLE_RECAPTCHA,
} = process.env

export {
  REACT_APP_BASE_URL,
  REACT_APP_API_URL,
  REACT_APP_API_HOST_URL,
  REACT_APP_ENV_TYPE,
  REACT_APP_GOOGLE_RECAPTCHA,
}
