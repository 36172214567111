import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonIconWrapper.module.css'
import KrayonIcon from '../KrayonIcon/KrayonIcon'

export default function KrayonIconWrapper({
  size,
  wrapperColor,
  iconName,
  iconColor,
  className,
  ...props
}) {
  return (
    <div
      className={classNames(
        styles.wrapper,
        styles[size],
        styles[wrapperColor],
        className
      )}
      {...props}
    >
      <KrayonIcon name={iconName} color={iconColor} />
    </div>
  )
}

KrayonIconWrapper.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  wrapperColor: PropTypes.oneOf(['grey20', 'bilobaLight', 'seapinkLight']),
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
}

KrayonIconWrapper.defaultProps = {
  size: 'medium',
  wrapperColor: 'grey20',
  iconName: '',
  iconColor: '',
  className: '',
}
