import React, {useEffect, useState} from 'react'
import EmptyScreen from '../../../../../../components/common/EmptyScreen/EmptyScreen'
import history from '../../../../../../utils/history'
import styles from './CourseFacultyList.module.css'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {COURSE__MANAGE_FACULTY__ADD} from '../../../../../../constants/pages.constants'
import {Table} from '@teachmint/common'
import classNames from 'classnames'
import ListCard from '../../../../../../components/common/ListCard/ListCard'
import SearchBar from '../../../../../../components/common/SearchBar/SearchBar'
import {useParams} from 'react-router-dom'
import {COURSE_STUDENT_LIST_TABLE_HEADERS} from '../../../CourseStudents/courseStudents.constants'

export default function CourseFacultyList() {
  const [filteredDepartments, setFilteredDepartments] = useState([])
  const [searchText, setSearchText] = useState('')

  const {courseUserInfo} = useSelector((state) => state)
  const {t} = useTranslation()
  const params = useParams()

  useEffect(() => {
    setFilteredDepartments(courseUserInfo?.teachersList)
  }, [courseUserInfo?.teachersList])

  const getRows = (departments) => {
    let rows = []

    departments?.forEach(({_id, name}) => {
      rows.push({
        id: _id,
        name: <div className="tm-hdg-14">{name}</div>,
      })
    })
    return rows
  }

  const handleSearchFilter = (text) => {
    setSearchText(text)

    if (text === '') setFilteredDepartments(courseUserInfo?.teachersList)
    else {
      let tempArray = courseUserInfo?.teachersList?.filter(({name}) =>
        name?.toLowerCase()?.includes(text)
      )
      setFilteredDepartments(tempArray)
    }
  }

  return (
    <div>
      {courseUserInfo?.teachersList?.length > 0 ? (
        <div>
          <ListCard
            title={`${t('COURSE_FACULTY_LIST__HEADER_LABEL')} : ${
              courseUserInfo?.teachersList?.length
            }`}
          >
            <div className={styles.subHeaderWrapper}>
              <div className={styles.subHeaderSearchbarWrapper}>
                <SearchBar
                  value={searchText}
                  placeholder={t('COURSE_FACULTY_LIST__SEARCH_PLACEHOLDER')}
                  handleSearchFilter={handleSearchFilter}
                />
              </div>

              <div
                className={classNames(
                  'tm-para-14 tm-cr-bl-2',
                  styles.addAction
                )}
                onClick={() =>
                  history.push(
                    COURSE__MANAGE_FACULTY__ADD.replace(
                      ':courseId',
                      params?.courseId
                    )
                  )
                }
              >
                {t('COURSE_FACULTY_LIST__ADD_FACULTY_ACTION')}
              </div>
            </div>

            <Table
              rows={getRows(filteredDepartments)}
              cols={COURSE_STUDENT_LIST_TABLE_HEADERS}
            />
          </ListCard>
        </div>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('COURSE_FACULTY_LIST__EMPTY_LABEL')}
            desc={t('COURSE_FACULTY_LIST__EMPTY_DESC')}
            btnOptions={{
              label: t('COURSE_FACULTY_LIST__EMPTY_BTN_LABEL'),
              action: () =>
                history.push(
                  COURSE__MANAGE_FACULTY__ADD.replace(
                    ':courseId',
                    params?.courseId
                  )
                ),
            }}
          />
        </div>
      )}
    </div>
  )
}
