import {call, put, takeEvery} from 'redux-saga/effects'
import {showErrorToast} from '../../redux/actions/common.actions'
import * as Api from './apiServices'
import globalActions from '../../redux/actions/global.actions'

function* getCourseInfo({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsGetCourseInfo, data.courseId)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.getCourseInfo.success(response?.data?.obj))
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getCourseInfo.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetCourseInfoSaga() {
  yield takeEvery(globalActions.getCourseInfo.REQUEST, getCourseInfo)
}
