import {useRef} from 'react'
import {useOutsideClickHandler} from '@teachmint/common'
import PropTypes from 'prop-types'
import styles from './SliderScreen.module.css'

function SliderScreen({setOpen, children, width = '600'}) {
  const wrapperRef = useRef(null)
  useOutsideClickHandler(wrapperRef, (e) => {
    setOpen(e)
  })

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.sliderCard}
        ref={wrapperRef}
        style={{width: `${width}px`}}
      >
        <div className={styles.closeBtn} onClick={setOpen}>
          <img
            src="https://storage.googleapis.com/tm-assets/icons/secondary/right-arrow-secondary.svg"
            className={styles.closeBtnIcon}
            alt=""
          />
        </div>
        {children}
      </div>
    </div>
  )
}

SliderScreen.propTypes = {
  setOpen: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
}

export default SliderScreen
