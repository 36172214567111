import {
  COURSE,
  COURSE__MANAGE_FACULTY,
  COURSE__MANAGE_STUDENTS,
  COURSE__MANAGE_STUDENTS__ADD,
  COURSE__RECORDINGS,
} from './pages.constants'

export const GLOBAL_COURSE_SIDEBAR_ITEMS = {
  COURSE_DASHBOARD: {
    id: 'COURSE_DASHBOARD',
    label: 'COURSE_SIDEBAR_ITEMS__DASHBOARD_LABEL',
    icon: 'dashboard',
    active: true,
    route: COURSE,
    eventName: '',
  },
  COURSE_MANAGE_STUDENTS: {
    id: 'COURSE_MANAGE_STUDENTS',
    label: 'COURSE_SIDEBAR_ITEMS__MANAGE_STUDENTS_LABEL',
    icon: 'dashboard',
    active: true,
    route: COURSE__MANAGE_STUDENTS,
    eventName: '',
    childRoutes: [COURSE__MANAGE_STUDENTS__ADD],
  },
  COURSE_MANAGE_FACULTY: {
    id: 'COURSE_MANAGE_FACULTY',
    label: 'COURSE_SIDEBAR_ITEMS__MANAGE_FACULTY_LABEL',
    icon: 'dashboard',
    active: true,
    route: COURSE__MANAGE_FACULTY,
    eventName: '',
  },
  COURSE_RECORDINGS: {
    id: 'COURSE_RECORDINGS',
    label: 'COURSE_SIDEBAR_ITEMS__RECORDINGS_LABEL',
    icon: 'dashboard',
    active: true,
    route: COURSE__RECORDINGS,
    eventName: '',
  },
}

export const COURSE_SIDEBAR_ITEMS = [
  // GLOBAL_COURSE_SIDEBAR_ITEMS.COURSE_DASHBOARD,
  GLOBAL_COURSE_SIDEBAR_ITEMS.COURSE_MANAGE_STUDENTS,
  GLOBAL_COURSE_SIDEBAR_ITEMS.COURSE_MANAGE_FACULTY,
  // GLOBAL_COURSE_SIDEBAR_ITEMS.COURSE_RECORDINGS,
]
