import produce from 'immer'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import KrayonButton from '../../../../../components/common/KrayonButton/KrayonButton'
import KrayonInputField from '../../../../../components/common/KrayonInputField/KrayonInputField'
import globalActions from '../../../../../redux/actions/global.actions'
import {
  formatToTimestamp,
  YYYY_MM_DD_FORMAT,
} from '../../../../../utils/datetime.helpers'
import {getFromSessionStorage} from '../../../../../utils/storage.helpers'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../../utils/validation'
import {
  ADD_INSTITUTE_SESSION_FIELDS,
  DEFAULT_ADD_INSTITUTE_SESSION_FIELDS_VALUES,
} from '../../../../InstituteSession/components/SliderAddInstituteSession/addInstituteSessionFormData'
import styles from './SessionForm.module.css'

export default function SessionForm({setSelectedStep}) {
  const [formData, setFormData] = useState(
    DEFAULT_ADD_INSTITUTE_SESSION_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const dispatch = useDispatch()
  const {t} = useTranslation('instituteOnboarding')
  const handleInputChange = ({fieldName, value}) => {
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_INSTITUTE_SESSION_FIELDS,
        fieldName,
        value
      )
    )
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    let isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_INSTITUTE_SESSION_FIELDS,
      formData
    )

    const finalFormData = produce(formData, (draft) => {
      draft.start_date = formatToTimestamp(YYYY_MM_DD_FORMAT, draft.start_date)
      draft.end_date = formatToTimestamp(YYYY_MM_DD_FORMAT, draft.end_date)
      return draft
    })

    // Check if end date greater than start date
    if (finalFormData?.start_date > finalFormData?.end_date) {
      isInputValid = false
      setErrorObject({
        ...errorObject,
        [ADD_INSTITUTE_SESSION_FIELDS.start_date.fieldName]: '',
        [ADD_INSTITUTE_SESSION_FIELDS.end_date.fieldName]: t('dateRule'),
      })
    }

    const instituteId = getFromSessionStorage('INSTITUTE_ID')
    if (isInputValid && instituteId) {
      const successAction = () => setSelectedStep('ADD_DEPARTMENT')

      dispatch(
        globalActions.addInstituteSession.request(
          {...finalFormData, node_id: instituteId},
          successAction
        )
      )
    }
  }

  return (
    <>
      <div className={styles.formOuterWrapper}>
        <form className={styles.formWrapper}>
          {Object.values(ADD_INSTITUTE_SESSION_FIELDS)?.map(
            ({fieldType, fieldName, label, placeholder}) => (
              <KrayonInputField
                key={fieldName}
                fieldType={fieldType}
                label={t('translation:' + label)}
                fieldName={fieldName}
                value={formData[fieldName]}
                onChange={handleInputChange}
                placeholder={t('translation:' + placeholder)}
                infoText={errorObject[fieldName]}
              />
            )
          )}
        </form>
      </div>

      <div className={styles.submitButtonWrapper}>
        <KrayonButton
          size="large"
          onClick={handleFormSubmit}
          className={styles.submitButton}
        >
          {t('translation:COMMON_NEXT')}
        </KrayonButton>
      </div>
    </>
  )
}
