import axios from 'axios'
import {REACT_APP_API_URL} from '../../constants/envVars.constants'

export const utilsGetDepartments = async (instituteId) => {
  let params = new URLSearchParams()
  params.append('node_id', instituteId)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}department/list`,
    params,
  })
  return res
}

export const utilsAddDepartment = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}department/add`,
    data,
  })
  return res
}
