import React, {useEffect, useState} from 'react'
import EmptyScreen from '../../../../components/common/EmptyScreen/EmptyScreen'
import history from '../../../../utils/history'
import styles from './DepartmentsList.module.css'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {
  DEPARTMENTS__ADD,
  MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS,
} from '../../../../constants/pages.constants'
import classNames from 'classnames'
import SearchBar from '../../../../components/common/SearchBar/SearchBar'
import {updatePathWithParams} from '../../../../utils/routing.helpers'
import Permission from '../../../../components/common/Permission/Permission'
import {GLOBAL_PERMISSION_IDS} from '../../../../constants/permissions.constants'
import {checkPermission} from '../../../../utils/permission.helpers'
import KrayonButton from '../../../../components/common/KrayonButton/KrayonButton'
import KrayonCard from '../../../../components/common/KrayonCard/KrayonCard'
import KrayonH6 from '../../../../components/common/KrayonH6/KrayonH6'
import KrayonIcon from '../../../../components/common/KrayonIcon/KrayonIcon'
import KrayonP2 from '../../../../components/common/KrayonP2/KrayonP2'
import KrayonP3 from '../../../../components/common/KrayonP3/KrayonP3'
import KrayonH5 from '../../../../components/common/KrayonH5/KrayonH5'
import KrayonIconWrapper from '../../../../components/common/KrayonIconWrapper/KrayonIconWrapper'

export default function DepartmentsList() {
  const [filteredDepartments, setFilteredDepartments] = useState([])
  const [searchText, setSearchText] = useState('')

  const state = useSelector((state) => state)
  const {t} = useTranslation()

  useEffect(() => {
    setFilteredDepartments(state?.globalData?.instituteDepartment?.data)
  }, [state?.globalData?.instituteDepartment?.data])

  const getRows = (departments) => (
    // departments?.map(
    //   ({
    //     _id,
    //     name,
    //     num_of_students,
    //     num_of_faculties,
    //     hod_details,
    //     programs,
    //   }) => (
    <>
      <KrayonCard
        className={styles.listItem}
        // onClick={() =>
        //   history.push(
        //     updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, {
        //       departmentId: _id,
        //     })
        //   )
        // }
      >
        <div className={styles.firstSectionWrapper}>
          <img
            src="https://i.pinimg.com/550x/94/fb/9e/94fb9e94f0db7e3d429df2d9c64527d2.jpg"
            alt=""
            className={styles.itemImg}
          />
          <div className={styles.infoWrapper}>
            <KrayonH6 className={styles.infoWrapper}>
              Aeronautics & Astronautics
            </KrayonH6>
            <div className={styles.hodWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>Dr. Ramakrishna Iyyer</KrayonP2>
            </div>
            <div className={styles.studentWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>45 Members</KrayonP2>
            </div>
          </div>
        </div>

        <div className={styles.secondSectionWrapper}>
          <div className={styles.secondSectionItem}>
            <div className={styles.secondSectionItemIconWrapper}>
              <KrayonIcon name="person" color="biloba" size="m" />
            </div>
            <KrayonP2>2 Programs</KrayonP2>
          </div>
          <div className={styles.secondSectionItem}>
            <div
              className={classNames(
                styles.secondSectionItemIconWrapper,
                styles.coursesItem
              )}
            >
              <KrayonIcon name="person" color="seapink" size="m" />
            </div>
            <KrayonP2>3 Courses</KrayonP2>
          </div>
        </div>
      </KrayonCard>
      <KrayonCard
        className={styles.listItem}
        // onClick={() =>
        //   history.push(
        //     updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, {
        //       departmentId: _id,
        //     })
        //   )
        // }
      >
        <div className={styles.firstSectionWrapper}>
          <img
            src="https://i.pinimg.com/550x/94/fb/9e/94fb9e94f0db7e3d429df2d9c64527d2.jpg"
            alt=""
            className={styles.itemImg}
          />
          <div className={styles.infoWrapper}>
            <KrayonH6 className={styles.infoWrapper}>
              Aeronautics & Astronautics
            </KrayonH6>
            <div className={styles.hodWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>Dr. Ramakrishna Iyyer</KrayonP2>
            </div>
            <div className={styles.studentWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>45 Members</KrayonP2>
            </div>
          </div>
        </div>

        <div className={styles.secondSectionWrapper}>
          <div className={styles.secondSectionItem}>
            <div className={styles.secondSectionItemIconWrapper}>
              <KrayonIcon name="person" color="biloba" size="m" />
            </div>
            <KrayonP2>2 Programs</KrayonP2>
          </div>
          <div className={styles.secondSectionItem}>
            <div
              className={classNames(
                styles.secondSectionItemIconWrapper,
                styles.coursesItem
              )}
            >
              <KrayonIcon name="person" color="seapink" size="m" />
            </div>
            <KrayonP2>3 Courses</KrayonP2>
          </div>
        </div>
      </KrayonCard>
      <KrayonCard
        className={styles.listItem}
        // onClick={() =>
        //   history.push(
        //     updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, {
        //       departmentId: _id,
        //     })
        //   )
        // }
      >
        <div className={styles.firstSectionWrapper}>
          <img
            src="https://i.pinimg.com/550x/94/fb/9e/94fb9e94f0db7e3d429df2d9c64527d2.jpg"
            alt=""
            className={styles.itemImg}
          />
          <div className={styles.infoWrapper}>
            <KrayonH6 className={styles.infoWrapper}>
              Aeronautics & Astronautics
            </KrayonH6>
            <div className={styles.hodWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>Dr. Ramakrishna Iyyer</KrayonP2>
            </div>
            <div className={styles.studentWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>45 Members</KrayonP2>
            </div>
          </div>
        </div>

        <div className={styles.secondSectionWrapper}>
          <div className={styles.secondSectionItem}>
            <div className={styles.secondSectionItemIconWrapper}>
              <KrayonIcon name="person" color="biloba" size="m" />
            </div>
            <KrayonP2>2 Programs</KrayonP2>
          </div>
          <div className={styles.secondSectionItem}>
            <div
              className={classNames(
                styles.secondSectionItemIconWrapper,
                styles.coursesItem
              )}
            >
              <KrayonIcon name="person" color="seapink" size="m" />
            </div>
            <KrayonP2>3 Courses</KrayonP2>
          </div>
        </div>
      </KrayonCard>
      <KrayonCard
        className={styles.listItem}
        // onClick={() =>
        //   history.push(
        //     updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, {
        //       departmentId: _id,
        //     })
        //   )
        // }
      >
        <div className={styles.firstSectionWrapper}>
          <img
            src="https://i.pinimg.com/550x/94/fb/9e/94fb9e94f0db7e3d429df2d9c64527d2.jpg"
            alt=""
            className={styles.itemImg}
          />
          <div className={styles.infoWrapper}>
            <KrayonH6 className={styles.infoWrapper}>
              Aeronautics & Astronautics
            </KrayonH6>
            <div className={styles.hodWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>Dr. Ramakrishna Iyyer</KrayonP2>
            </div>
            <div className={styles.studentWrapper}>
              <KrayonIcon
                name="person"
                color="lightGrey"
                size="m"
                className={styles.icon}
              />
              <KrayonP2>45 Members</KrayonP2>
            </div>
          </div>
        </div>

        <div className={styles.secondSectionWrapper}>
          <div className={styles.secondSectionItem}>
            <KrayonIconWrapper
              wrapperColor="bilobaLight"
              iconName="person"
              iconColor="biloba"
              className={styles.secondSectionItemIconWrapper}
            />

            <KrayonP2>2 Programs</KrayonP2>
          </div>
          <div className={styles.secondSectionItem}>
            <KrayonIconWrapper
              wrapperColor="seapinkLight"
              iconName="person"
              iconColor="seapink"
              className={styles.secondSectionItemIconWrapper}
            />

            <KrayonP2>3 Courses</KrayonP2>
          </div>
        </div>
      </KrayonCard>
    </>
  )
  //   )
  // )

  const handleSearchFilter = (text) => {
    setSearchText(text)

    if (text === '')
      setFilteredDepartments(state?.globalData?.instituteDepartment?.data)
    else {
      let tempArray = state?.globalData?.instituteDepartment?.data?.filter(
        ({name}) => name?.toLowerCase()?.includes(text)
      )
      setFilteredDepartments(tempArray)
    }
  }

  return (
    <div>
      {/* {state?.globalData?.instituteDepartment?.data?.length > 0 ? ( */}
      <div>
        <KrayonH5 className="">{t('DEPARTMENT_LIST__HEADER_LABEL')}</KrayonH5>

        <div className={styles.subHeaderWrapper}>
          <div className={styles.subHeaderSearchbarWrapper}>
            <SearchBar
              value={searchText}
              placeholder={t('DEPARTMENT_LIST__SEARCH_PLACEHOLDER')}
              handleSearchFilter={handleSearchFilter}
            />
          </div>

          <Permission permissionId={GLOBAL_PERMISSION_IDS.DEPARTMENT__ADD}>
            <KrayonButton
              width="fitContent"
              onClick={() => history.push(DEPARTMENTS__ADD)}
            >
              {t('DEPARTMENT_LIST__ADD_DEPARTMENT_ACTION')}
            </KrayonButton>
          </Permission>
        </div>
        <div className={styles.listWrapper}>{getRows(filteredDepartments)}</div>
      </div>
      {/* ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('DEPARTMENT_LIST__EMPTY_LABEL')}
            desc={t('DEPARTMENT_LIST__EMPTY_DESC')}
            btnOptions={
              checkPermission(state, GLOBAL_PERMISSION_IDS.DEPARTMENT__ADD)
                ? {
                    label: t('DEPARTMENT_LIST__EMPTY_BTN_LABEL'),
                    action: () => history.push(DEPARTMENTS__ADD),
                  }
                : null
            }
          />
        </div>
      )} */}
    </div>
  )
}
