import React from 'react'
import styles from './SliderScreenHeader.module.css'
import {Icon} from '@teachmint/common'
import classNames from 'classnames'

export default function SliderScreenHeader({icon, title}) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.headingCon}>
        {icon && <Icon name={icon} type="filled" size="xs" color="basic" />}
        <div
          className={classNames('tm-hdg-16 lg:tm-hdg-20', styles.headingLabel)}
        >
          {title}
        </div>
      </div>
    </div>
  )
}
