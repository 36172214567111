import React from 'react'
import classNames from 'classnames'
import KrayonH5 from '../KrayonH5/KrayonH5'
import KrayonIcon from '../KrayonIcon/KrayonIcon'
import styles from './CollapseCard.module.css'

export default function CollapseCard({
  label,
  children,
  collapse,
  setCollapse,
  className,
}) {
  return (
    <div className={classNames(styles.wrapper, className)}>
      <div
        className={styles.headerWrapper}
        {...{'data-status': collapse}}
        onClick={() => setCollapse(!collapse)}
      >
        <KrayonH5>{label}</KrayonH5>
        <KrayonIcon
          name={collapse ? 'expand_more' : 'chevron_right'}
          size="m"
          className={styles.dropdownIcon}
        />
      </div>

      {!collapse && <div className={styles.bodyWrapper}>{children}</div>}
    </div>
  )
}
