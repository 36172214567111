import {commonActionTypes} from '../actionTypes'

export const sidebarReducer = (state = false, action) => {
  switch (action.type) {
    case commonActionTypes.SHOW_SIDEBAR: {
      return true
    }
    case commonActionTypes.HIDE_SIDEBAR: {
      return false
    }
    default:
      return state
  }
}

export const feedbackSliderReducer = (state = false, action) => {
  switch (action.type) {
    case commonActionTypes.SHOW_FEEDBACK_SLIDER: {
      return true
    }
    case commonActionTypes.HIDE_FEEDBACK_SLIDER: {
      return false
    }
    default:
      return state
  }
}

export const setSelectedInstituteReducer = (state = null, action) => {
  switch (action.type) {
    case commonActionTypes.SET_SELECTED_INSTITUTE: {
      return action.payload
    }
    default:
      return state
  }
}
