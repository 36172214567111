import React from 'react'
import KrayonH3 from '../../../../../components/common/KrayonH3/KrayonH3'
import KrayonP2 from '../../../../../components/common/KrayonP2/KrayonP2'
import DepartmentForm from '../DepartmentForm/DepartmentForm'
import SessionForm from '../SessionForm/SessionForm'
import styles from './OnboardingForms.module.css'

export default function OnboardingForms({
  stepsItems,
  selectedStep,
  setSelectedStep,
}) {
  const renderStep = () => {
    switch (selectedStep) {
      case 'ADD_SESSION':
        return <SessionForm setSelectedStep={setSelectedStep} />
      case 'ADD_DEPARTMENT':
        return <DepartmentForm />
      default:
        return null
    }
  }

  return (
    <>
      <div className={styles.headingWrapper}>
        <KrayonH3>{stepsItems[selectedStep]?.formHeading}</KrayonH3>
        <KrayonP2 className={styles.description}>
          {stepsItems[selectedStep]?.formDesc}
        </KrayonP2>
      </div>

      {renderStep()}
    </>
  )
}
