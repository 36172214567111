import React, {useEffect, useState} from 'react'
import SliderScreen from '../../../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../../../utils/history'
import {useTranslation} from 'react-i18next'
import styles from './SliderAddCourseStudents.module.css'
import {Button, MultiSelect} from '@teachmint/common'
import {useDispatch, useSelector} from 'react-redux'
import {useParams} from 'react-router-dom'
import globalActions from '../../../../../../redux/actions/global.actions'

export default function SliderAddCourseStudents() {
  const [selectedStudents, setSelectedStudents] = useState([])

  const {departmentUserInfo, courseUserInfo} = useSelector((state) => state)
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    if (courseUserInfo?.studentsList?.length > 0)
      setSelectedStudents(courseUserInfo?.studentsList?.map(({_id}) => _id))
  }, [courseUserInfo?.studentsList])

  const handleFormSubmit = () => {
    if (params?.courseId) {
      const successAction = () => {
        dispatch(globalActions?.getCourseUsers?.request(params?.courseId))
        history.goBack()
      }

      dispatch(
        globalActions?.addremoveCourseUsers?.request(
          {
            to_add_iids: selectedStudents,
            to_remove_iids: departmentUserInfo?.studentList
              ?.filter(({_id}) => !selectedStudents.includes(_id))
              ?.map(({_id}) => _id),
            node_id: params?.courseId,
          },
          successAction
        )
      )
    }
  }

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_COURSE_STUDENT_LABEL')}
          />

          <SliderScreenBody>
            <MultiSelect
              options={departmentUserInfo?.studentList?.map(({_id, name}) => {
                return {value: _id, label: name}
              })}
              selectedOptions={selectedStudents}
              onChange={(students) => setSelectedStudents(students)}
              showSelectAll={true}
            />

            <Button
              className={styles.submitButton}
              size="medium"
              onClick={handleFormSubmit}
            >
              {t('COMMON_SUBMIT')}
            </Button>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
