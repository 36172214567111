import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'
import {englishLang} from './en/en'
import {instituteSidebar} from './en/instituteSidebar'
import {loginPage} from './en/loginPage'
import {instituteOnboarding} from './en/instituteOnboarding'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      'en-US': {
        translation: englishLang,
        loginPage: loginPage,
        instituteSidebar: instituteSidebar,
        instituteOnboarding: instituteOnboarding,
      },
    },
    loadPath: '/{{lng}}/{{lng}}.js',

    fallbackLng: 'en-US',
    debug: true,

    // using multiple namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',

    keySeparator: false, // we use content as keys
    interpolation: {escapeValue: false},
    react: {useSuspense: false},
  })

export default i18n
