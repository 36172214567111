import {Button, Input} from '@teachmint/common'
import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import SliderScreen from '../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../components/common/SliderScreenHeader/SliderScreenHeader'
import {hideFeedbackSliderAction} from '../../redux/actions/common.actions'
import styles from './Feedback.module.css'
import {useTranslation} from 'react-i18next'

export default function Feedback() {
  const [feedbackText, setFeedbackText] = useState('')

  const {showFeedbackSlider} = useSelector((state) => state)
  const dispatch = useDispatch()
  const {t} = useTranslation()

  const handleInputChange = ({fieldName, value}) => {
    switch (fieldName) {
      case 'feedback':
        setFeedbackText(value)
        break

      default:
        break
    }
  }

  const handleFormSubmit = () => {}

  return showFeedbackSlider ? (
    <div>
      <SliderScreen setOpen={() => dispatch(hideFeedbackSliderAction())}>
        <>
          <SliderScreenHeader icon="doubt" title={t('FEEDBACK__LABEL')} />

          <SliderScreenBody>
            <div className={styles.formWrapper}>
              <div className={styles.inputWrapper}>
                <Input
                  type="text"
                  title={t('FEEDBACK__FEEDBACK_LABEL')}
                  fieldName="feedback"
                  value={feedbackText}
                  onChange={handleInputChange}
                  placeholder={t('FEEDBACK__FEEDBACK_PLACEHOLDER')}
                />
              </div>

              <Button
                className={styles.submitButton}
                size="medium"
                onClick={handleFormSubmit}
              >
                Submit
              </Button>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  ) : null
}
