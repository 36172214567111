import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './TextField.module.css'

export default function TextField({
  fieldName,
  value,
  onChange,
  placeholder,
  showFieldBorder,
  infoType,
  id,
}) {
  return (
    <input
      type="text"
      value={value}
      className={classNames(styles.input)}
      data-status={showFieldBorder ? infoType : ''}
      onChange={(e) =>
        onChange({
          fieldName,
          value: String(e.target.value).trimLeft(),
          event: e,
        })
      }
      placeholder={placeholder}
      id={id}
    />
  )
}

TextField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  showFieldBorder: PropTypes.bool,
  infoType: PropTypes.string,
  id: PropTypes.string,
}

TextField.defaultProps = {
  fieldName: '',
  value: '',
  placeholder: '',
  onChange: () => {},
  showFieldBorder: false,
  infoType: '',
  id: '',
}
