import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonIcon.module.css'

export default function KrayonIcon({name, color, size, className}) {
  return (
    <span
      className={classNames(styles.materialIcons, className)}
      {...{'data-color': color, 'data-size': size}}
    >
      {name}
    </span>
  )
}

KrayonIcon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf([
    '3xs',
    '2xs',
    'xs',
    's',
    'm',
    'l',
    'xl',
    '2xl',
    '3xl',
  ]),
  color: PropTypes.oneOf([
    'primary',
    'darkGrey',
    'lightGrey',
    'white',
    'error',
    'caution',
    'success',
    'biloba',
    'seapink',
  ]),
}

KrayonIcon.defaultProps = {
  name: '',
  size: 'm',
  color: 'darkGrey',
}
