import React, {useEffect, useState} from 'react'
import EmptyScreen from '../../../../../../components/common/EmptyScreen/EmptyScreen'
import history from '../../../../../../utils/history'
import styles from './DepartmentFacultyListPage.module.css'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {
  MANAGE_DEPARTMENT__MANAGE_USER__FACULTY__ADD,
  MANAGE_DEPARTMENT,
} from '../../../../../../constants/pages.constants'
import {Table} from '@teachmint/common'
import {DEPARTMENT_STUDENT_LIST_TABLE_HEADERS} from '../../departmentManageUser.constants'
import classNames from 'classnames'
import ListCard from '../../../../../../components/common/ListCard/ListCard'
import SearchBar from '../../../../../../components/common/SearchBar/SearchBar'
import {matchPath} from 'react-router-dom'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'

export default function DepartmentFacultyListPage() {
  const [filteredFaculty, setFilteredFaculty] = useState([])
  const [searchText, setSearchText] = useState('')

  const {departmentUserInfo} = useSelector((state) => state)
  const {t} = useTranslation()

  useEffect(() => {
    setFilteredFaculty(departmentUserInfo?.facultyList)
  }, [departmentUserInfo?.facultyList])

  const getAddFacultyPath = () =>
    updatePathWithParams(
      MANAGE_DEPARTMENT__MANAGE_USER__FACULTY__ADD,
      getParamsFromPath(matchPath, MANAGE_DEPARTMENT)
    )

  const getRows = (faculty) => {
    let rows = []

    faculty?.forEach(({_id, name}) => {
      rows.push({
        id: _id,
        name: <div className="tm-hdg-14">{name}</div>,
      })
    })
    return rows
  }

  const handleSearchFilter = (text) => {
    setSearchText(text)

    if (text === '') setFilteredFaculty(departmentUserInfo?.facultyList)
    else {
      let tempArray = departmentUserInfo?.facultyList?.filter(({name}) =>
        name?.toLowerCase()?.includes(text)
      )
      setFilteredFaculty(tempArray)
    }
  }

  return (
    <div>
      {departmentUserInfo?.facultyList?.length > 0 ? (
        <div>
          <ListCard
            title={`${t('DEPARTMENT_FACULTY_LIST__HEADER_LABEL')} : ${
              departmentUserInfo?.facultyList?.length
            }`}
          >
            <div className={styles.subHeaderWrapper}>
              <div className={styles.subHeaderSearchbarWrapper}>
                <SearchBar
                  value={searchText}
                  placeholder={t('DEPARTMENT_FACULTY_LIST__SEARCH_PLACEHOLDER')}
                  handleSearchFilter={handleSearchFilter}
                />
              </div>

              <div
                className={classNames(
                  'tm-para-14 tm-cr-bl-2',
                  styles.addAction
                )}
                onClick={() => history.push(getAddFacultyPath())}
              >
                {t('DEPARTMENT_FACULTY_LIST__ADD_STUDENT_ACTION')}
              </div>
            </div>

            <Table
              rows={getRows(filteredFaculty)}
              cols={DEPARTMENT_STUDENT_LIST_TABLE_HEADERS}
            />
          </ListCard>
        </div>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('DEPARTMENT_FACULTY_LIST__EMPTY_LABEL')}
            desc={t('DEPARTMENT_FACULTY_LIST__EMPTY_DESC')}
            btnOptions={{
              label: t('DEPARTMENT_FACULTY_LIST__EMPTY_BTN_LABEL'),
              action: () => history.push(getAddFacultyPath()),
            }}
          />
        </div>
      )}
    </div>
  )
}
