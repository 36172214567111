import React, {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import styles from './ValueDropdownField.module.css'
import classNames from 'classnames'
import {useOutsideClickHandler} from '@teachmint/common'
import KrayonIcon from '../../KrayonIcon/KrayonIcon'

export default function ValueDropdownField({
  fieldName,
  value,
  onChange,
  placeholder,
  showFieldBorder,
  infoType,
  id,
  options,
  secondValue,
  secondFieldName,
}) {
  const [showDropdown, setShowDropdown] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClickHandler(wrapperRef, () => setShowDropdown(false))

  const renderOptions = () =>
    options?.options?.map((item) => (
      <div
        className={styles.option}
        key={item?.value}
        onClick={(e) =>
          onChange({fieldName: secondFieldName, value: item?.value, event: e})
        }
      >
        {item?.children || item?.label}
      </div>
    ))

  const getLabel = (val) => {
    const item = options?.options?.find((option) => option.value === val)
    return (
      item?.children || (
        <div className={styles.dropdownSelectedValue}>{item?.label}</div>
      ) || <div className={styles.placeholder}>Select</div>
    )
  }

  return (
    <div
      className={styles.wrapper}
      data-status={showFieldBorder ? infoType : ''}
    >
      <input
        type="text"
        className={styles.input}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange({fieldName, value: e.target.value, event: e})}
        id={id}
      />

      <div
        className={classNames(styles.dropdown)}
        onClick={() => setShowDropdown(!showDropdown)}
        onKeyDown={() => setShowDropdown(!showDropdown)}
        ref={wrapperRef}
      >
        <div className={styles.placeholderWrapper}>
          {getLabel(secondValue)}
          <KrayonIcon
            name={showDropdown ? 'expand_more' : 'chevron_right'}
            size="s"
            className={styles.dropdownIcon}
          />
        </div>
        {showDropdown && options?.options?.length && (
          <div className={classNames(styles.optionsBox)}>{renderOptions()}</div>
        )}
      </div>
    </div>
  )
}

ValueDropdownField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  showFieldBorder: PropTypes.bool,
  infoType: PropTypes.string,
  id: PropTypes.string,
}

ValueDropdownField.defaultProps = {
  fieldName: '',
  value: '',
  placeholder: '',
  onChange: () => {},
  showFieldBorder: false,
  infoType: '',
  id: '',
}
