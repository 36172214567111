import {call, put, takeEvery} from 'redux-saga/effects'
import {
  showErrorToast,
  showSuccessToast,
} from '../../../../redux/actions/common.actions'
import * as Api from './apiServices'
import globalActions from '../../../../redux/actions/global.actions'

function* getDepartmentCourseMaster({data, successAction, failureAction}) {
  try {
    const response = yield call(
      Api.utilsGetDepartmentCourseMaster,
      data.departmentId
    )
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(
        globalActions.getDepartmentCourseMaster.success(response?.data?.obj)
      )
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getDepartmentCourseMaster.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetDepartmentCourseMasterSaga() {
  yield takeEvery(
    globalActions.getDepartmentCourseMaster.REQUEST,
    getDepartmentCourseMaster
  )
}

function* addDepartmentCourse({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsAddDepartmentCourseMaster, data)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(
        globalActions.addDepartmentCourseMaster.success(response?.data?.obj)
      )
      yield put(
        showSuccessToast(`${data?._id ? 'Updated' : 'Added'} successfully`)
      )
      yield put({type: globalActions.getDepartmentCourseMaster.REQUEST})
    }
  } catch (error) {
    failureAction?.()
    yield put(globalActions.addDepartmentCourseMaster.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchAddDepartmentCourseMasterSaga() {
  yield takeEvery(
    globalActions.addDepartmentCourseMaster.REQUEST,
    addDepartmentCourse
  )
}
