import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import {Provider} from 'react-redux'
import store from './redux/store'
import './i18n/i18n'

// Teachmint common imports
import '@teachmint/common/lib/styles/devices.css'
import '@teachmint/common/lib/styles/global.css'
import '@teachmint/common/lib/styles/variables.css'
import '@teachmint/common/lib/fontIcons/style.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
reportWebVitals()
