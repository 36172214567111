import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import styles from './LoginPage.module.css'
import {utilslogin} from './../../apiServices'
import {DEFAULT_LOGIN_FIELDS_VALUES, LOGIN_FIELDS} from './../../loginFormData'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../utils/validation'
import history from '../../../../utils/history'
import {
  DASHBOARD,
  LOGIN__FORGOT_PASSWORD,
  LOGIN__RESET_PASSWORD,
} from '../../../../constants/pages.constants'
import {REACT_APP_GOOGLE_RECAPTCHA} from '../../../../constants/envVars.constants'
import {
  getSubdomain,
  setToSessionStorage,
} from '../../../../utils/storage.helpers'
import KrayonInputField from '../../../../components/common/KrayonInputField/KrayonInputField'
import KrayonButton from '../../../../components/common/KrayonButton/KrayonButton'
import KaryonH3 from '../../../../components/common/KrayonH3/KrayonH3'
import KrayonP3 from '../../../../components/common/KrayonP3/KrayonP3'

export default function LoginPage({setGlobalError, setIsLoading}) {
  const [formData, setFormData] = useState(DEFAULT_LOGIN_FIELDS_VALUES)
  const [errorObject, setErrorObject] = useState({})

  const {t} = useTranslation('loginPage')

  useEffect(() => {
    return () => setGlobalError('')
  }, [])

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(formData, LOGIN_FIELDS, fieldName, value)
    )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      LOGIN_FIELDS,
      formData
    )

    if (isInputValid) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_GOOGLE_RECAPTCHA, {action: 'submit'})
          .then((token) => {
            setIsLoading(true)
            utilslogin({
              ...formData,
              subdomain: getSubdomain(),
              recaptcha_token: token,
            })
              .then(({data}) => {
                if (data?.status && data?.obj?._id) {
                  // If first time user add to login
                  if (data?.obj?.first_time_login) {
                    setToSessionStorage(
                      'UNIVERSITY__LOGIN_TOKEN',
                      data?.obj?.login_token
                    )
                    history.push(LOGIN__RESET_PASSWORD)
                  } else history.push(DASHBOARD)
                } else
                  setGlobalError(t(`translation:ERROR_${data?.error_code}`))
              })
              .catch(() => setGlobalError(t('translation:ERROR_1000')))
              .finally(() => setIsLoading(false))
          })
      })
    }
  }

  return (
    <form>
      <KaryonH3 className={styles.heading}>{t('mainHeading')}</KaryonH3>

      <div className={styles.formWrapper}>
        {Object.values(LOGIN_FIELDS)?.map(
          ({fieldType, fieldName, label, placeholder}) => (
            <div key={fieldName} className={styles.inputWrapper}>
              <KrayonInputField
                fieldType={fieldType}
                label={t(label)}
                fieldName={fieldName}
                value={formData[fieldName]}
                onChange={handleInputChange}
                placeholder={t(placeholder)}
                infoText={errorObject[fieldName]}
              />
            </div>
          )
        )}
      </div>

      <KrayonButton
        onClick={() => history.push(LOGIN__FORGOT_PASSWORD)}
        type="text"
        className={styles.forgotPassword}
      >
        {t('forgotPassword')}
      </KrayonButton>

      <KrayonP3 className={styles.termsPrivacyText}>
        {`${t('agreeText')} `} <br />
        <a
          href="https://www.teachmint.com/terms"
          target="_blank"
          rel="noreferrer"
        >
          {t('termsCondition')}
        </a>
        {' & '}
        <a
          href="https://www.teachmint.com/privacy"
          target="_blank"
          rel="noreferrer"
        >
          {t('privacyPolicy')}
        </a>
      </KrayonP3>

      <KrayonButton
        width="full"
        size="large"
        onClick={handleFormSubmit}
        className={styles.submitButton}
      >
        {t('translation:COMMON_LOGIN')}
      </KrayonButton>
    </form>
  )
}
