import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {MANAGE_DEPARTMENT__COURSES__COURSE_MASTER__ADD} from '../../../../../../constants/pages.constants'
import CourseMasterListPage from '../CourseMasterListPage/CourseMasterListPage'
import SliderAddCourseMaster from '../SliderAddCourseMaster/SliderAddCourseMaster'
import styles from './CourseMasterPage.module.css'

export default function CourseMasterPage() {
  return (
    <div>
      <CourseMasterListPage />

      <Switch>
        <Route
          path={MANAGE_DEPARTMENT__COURSES__COURSE_MASTER__ADD}
          component={SliderAddCourseMaster}
        />
      </Switch>
    </div>
  )
}
