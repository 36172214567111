import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonButton.module.css'

export default function KrayonButton({
  onClick,
  category,
  size,
  type,
  disabled,
  width,
  className,
  children,
  ...props
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        styles.button,
        styles[category],
        styles[size],
        styles[type],
        styles[width],
        {[styles.disabled]: disabled},
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

KrayonButton.propTypes = {
  onClick: PropTypes.func,
  category: PropTypes.oneOf(['primary', 'destructive', 'constructive']),
  size: PropTypes.oneOf(['small', 'medium', 'large', 'extraLarge']),
  type: PropTypes.oneOf(['filled', 'outline', 'text']),
  disabled: PropTypes.bool,
  width: PropTypes.oneOf(['fitContent', 'full']),
  className: PropTypes.string,
}

KrayonButton.defaultProps = {
  onClick: (f) => f,
  category: 'primary',
  size: 'medium',
  type: 'filled',
  disabled: false,
  width: 'fitContent',
  className: '',
}
