import axios from 'axios'
import {REACT_APP_API_URL} from '../../constants/envVars.constants'

export const utilsGetInstituteSessions = async (instituteId) => {
  let params = new URLSearchParams()
  params.append('node_id', instituteId)

  const res = await axios({
    method: 'GET',
    url: `${REACT_APP_API_URL}institute-session/list`,
    params,
  })
  return res
}

export const utilsAddInstituteSession = async (data) => {
  const res = await axios({
    method: 'POST',
    url: `${REACT_APP_API_URL}institute-session/add`,
    data,
  })
  return res
}
