import React from 'react'
import {useSelector} from 'react-redux'
import styles from './Loader.module.css'

export default function Loader({isLoading}) {
  const {globalData} = useSelector((state) => state)

  return isLoading ||
    Object.values(globalData)?.filter(({isLoading}) => isLoading)?.length >
      0 ? (
    <div className={styles.wrapper}>
      <div className={styles.loader} />
    </div>
  ) : null
}
