import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonP2.module.css'

export default function KrayonP2({type, color, className, children, ...props}) {
  return (
    <p
      className={classNames(
        styles.para,
        styles[type],
        styles[color],
        className
      )}
      {...props}
    >
      {children}
    </p>
  )
}

KrayonP2.propTypes = {
  type: PropTypes.oneOf(['light', 'regular', 'medium']),
  color: PropTypes.oneOf(['secondary', 'error', 'success']),
}

KrayonP2.defaultProps = {
  type: 'regular',
  color: 'secondary',
}
