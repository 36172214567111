import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './KrayonH3.module.css'

export default function KrayonH3({type, className, children}) {
  return (
    <h3 className={classNames(styles.heading, styles[type], className)}>
      {children}
    </h3>
  )
}

KrayonH3.propTypes = {
  type: PropTypes.oneOf(['bold', 'semibold']),
}

KrayonH3.defaultProps = {
  type: 'semibold',
}
