// Location of loaded dependency in redux
export const DEPENDENCY_REDUX_LOCATION = {
  INSTITUTE_INFO: ['globalData', 'instituteInfo', 'loaded'],
}

// Location of different data in redux
export const DATA_REDUX_LOCATION = {
  INSTITUTE_INFO: [
    'globalData',
    'instituteInfo',
    'data',
    'institutes',
    ':instituteId',
    'role',
  ],
}

export const GLOBAL_PERMISSION_IDS = {
  // Department permissions
  DEPARTMENT__GET: 'department.get',
  DEPARTMENT__ADD: 'department.add',

  // Session permissions
  INSTITUTE_SEESION__GET: 'institute-session.get',
}

//
export const PERMISSION_CONSTANTS = {
  [GLOBAL_PERMISSION_IDS.DEPARTMENT__GET]: {
    id: GLOBAL_PERMISSION_IDS.DEPARTMENT__GET,
    objRoute: DATA_REDUX_LOCATION.INSTITUTE_INFO,
    dependency: DEPENDENCY_REDUX_LOCATION.INSTITUTE_INFO,
  },

  [GLOBAL_PERMISSION_IDS.DEPARTMENT__ADD]: {
    id: GLOBAL_PERMISSION_IDS.DEPARTMENT__ADD,
    objRoute: DATA_REDUX_LOCATION.INSTITUTE_INFO,
    dependency: DEPENDENCY_REDUX_LOCATION.INSTITUTE_INFO,
  },

  [GLOBAL_PERMISSION_IDS.INSTITUTE_SEESION__GET]: {
    id: GLOBAL_PERMISSION_IDS.INSTITUTE_SEESION__GET,
    objRoute: DATA_REDUX_LOCATION.INSTITUTE_INFO,
    dependency: DEPENDENCY_REDUX_LOCATION.INSTITUTE_INFO,
  },
}

export const VARIABLE_CONSTANTS = {
  ':instituteId': {
    id: ':instituteId',
    objRoute: ['selectedInstitute'],
  },
}
