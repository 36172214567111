import {REACT_APP_ENV_TYPE} from '../constants/envVars.constants'

export const getDepartmentIdFromQuery = (search) =>
  new URLSearchParams(search).get('department_id')

export const setToSessionStorage = (key, value) =>
  window.sessionStorage.setItem(key, value)

export const getFromSessionStorage = (key) => window.sessionStorage.getItem(key)

export const setToLocalStorage = (key, value) => {
  window.localStorage.setItem(key, value)
}

export const getFromLocalStorage = (key) => window.localStorage.getItem(key)

export const getSubdomain = () =>
  REACT_APP_ENV_TYPE === 'DEVELOPMENT'
    ? 'pit'
    : String(window.location.hostname)?.split('.')?.[0] || 'www'
