import {useEffect, useState} from 'react'
import classNames from 'classnames'
// import ErrorStatement from './ErrorStatement/ErrorStatement'
import styles from './InputField.module.css'
import DateField from './DateField'

export default function InputField(props) {
  const {
    type,
    value = '',
    placeholder,
    fieldName = '',
    title = '',
    isRequired,
    onChange,
    showError,
    errorMsg,
    setShowError,
  } = props

  const [isError, setIsError] = useState(showError)
  const [error, setError] = useState(errorMsg)

  useEffect(() => {
    if (showError !== isError) setIsError(showError)
  }, [showError])

  useEffect(() => {
    if (errorMsg !== error) setError(errorMsg)
  }, [errorMsg])

  useEffect(() => {
    if (setShowError) setShowError(isError)
  }, [isError])

  const inputComponents = {
    date: (
      <DateField
        placeholder={placeholder}
        value={value}
        handleChange={onChange}
        fieldName={fieldName}
      />
    ),
  }

  return (
    <div className={classNames(styles.wrapper)}>
      {title && (
        <div className={classNames(styles.titleContainer)}>
          {title} {isRequired && <span className={styles.astrik}>*</span>}{' '}
        </div>
      )}
      {inputComponents[type]}
      <div className="tm-para-12 tm-cr-rd-1">{error}</div>
    </div>
  )
}
