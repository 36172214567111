import {useEffect, useState} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {v4 as uuidv4} from 'uuid'
import styles from './KrayonInputField.module.css'
import {INFO_TYPE, INPUT_TYPES} from './KrayonInputField.constants'
import TextField from './TextField/TextField'
import PasswordField from './PasswordField/PasswordField'
import DateField from './DateField/DateField'
import DropdownField from './DropdownField/DropdownField'
import ValueDropdownField from './ValueDropdownField/ValueDropdownField'

export default function KrayonInputField({
  fieldName,
  fieldType,
  value,
  placeholder,
  label,
  onChange,
  infoType,
  infoText,
  classes,
  options,
  secondValue,
  secondFieldName,
}) {
  const [uniqueId, setUniqueId] = useState(null)

  useEffect(() => {
    setUniqueId(uuidv4())
  }, [])

  const showFieldBorder = infoText ? true : false

  const inputComponents = {
    [INPUT_TYPES.TEXT]: (
      <TextField
        fieldName={fieldName}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        showFieldBorder={showFieldBorder}
        infoType={infoType}
        id={uniqueId}
      />
    ),
    [INPUT_TYPES.PASSWORD]: (
      <PasswordField
        fieldName={fieldName}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        showFieldBorder={showFieldBorder}
        infoType={infoType}
        id={uniqueId}
      />
    ),
    [INPUT_TYPES.DROPDOWN]: (
      <DropdownField
        fieldName={fieldName}
        value={value}
        onChange={onChange}
        showFieldBorder={showFieldBorder}
        infoType={infoType}
        options={options}
      />
    ),
    [INPUT_TYPES.DATE]: (
      <DateField
        fieldName={fieldName}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        showFieldBorder={showFieldBorder}
        infoType={infoType}
        id={uniqueId}
      />
    ),
    [INPUT_TYPES.VALUE_DROPDOWN]: (
      <ValueDropdownField
        fieldName={fieldName}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        showFieldBorder={showFieldBorder}
        infoType={infoType}
        id={uniqueId}
        options={options}
        secondValue={secondValue}
        secondFieldName={secondFieldName}
      />
    ),
  }

  return (
    <div className={classNames(styles.wrapper, classes.wrapper)}>
      {label && (
        <label
          className={classNames(styles.label, classes.label)}
          htmlFor={uniqueId}
        >
          {label}&nbsp;
          {options?.isRequired && <span className={styles.astrik}>*</span>}
        </label>
      )}

      {inputComponents[fieldType]}

      {infoText && (
        <div
          className={classNames(
            styles.infoText,
            classes.infoText,
            styles[infoType]
          )}
        >
          {infoText}
        </div>
      )}
    </div>
  )
}

KrayonInputField.propTypes = {
  fieldName: PropTypes.string,
  fieldType: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  infoType: PropTypes.string,
  infoText: PropTypes.string,
  classes: PropTypes.shape({
    wrapper: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

KrayonInputField.defaultProps = {
  fieldName: '',
  value: '',
  placeholder: '',
  label: '',
  onChange: () => {},
  infoType: INFO_TYPE.ERROR,
  infoText: '',
  classes: {},
  options: [],
}
