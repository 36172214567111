import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  codeInput: Joi.string().min(0).max(20).label('Code'),
  code: Joi.string().required().max(20).label('Code'),

  courseCreditsInput: Joi.string()
    .min(0)
    .max(3)
    .pattern(/^\d*$/)
    .label('Course credit'),
  courseCredits: Joi.string()
    .required()
    .max(3)
    .pattern(/^\d*$/)
    .label('Course credit'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_COURSE_MASTER__NAME_LABEL',
    placeholder: 'SLIDER_ADD_COURSE_MASTER__NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  code: {
    fieldType: 'text',
    label: 'SLIDER_ADD_COURSE_MASTER__CODE_LABEL',
    placeholder: 'SLIDER_ADD_COURSE_MASTER__CODE_PLACEHOLDER',
    fieldName: 'code',
    options: {
      inputValidation: VALIDATION_SCHEMA.codeInput,
      finalValidation: VALIDATION_SCHEMA.code,
    },
  },
  course_credits: {
    fieldType: 'text',
    label: 'SLIDER_ADD_COURSE_MASTER__COURSE_CREDIT_LABEL',
    placeholder: 'SLIDER_ADD_COURSE_MASTER__COURSE_CREDIT_PLACEHOLDER',
    fieldName: 'course_credits',
    options: {
      inputValidation: VALIDATION_SCHEMA.courseCreditsInput,
      finalValidation: VALIDATION_SCHEMA.courseCredits,
    },
  },
}

export const ADD_DEPARTMENT_COURSE_MASTER_FIELDS = {
  name: MODAL_FIELDS.name,
  code: MODAL_FIELDS.code,
  course_credits: MODAL_FIELDS.course_credits,
}
export const DEFAULT_ADD_DEPARTMENT_COURSE_MASTER_FIELDS_VALUES = {
  name: '',
  code: '',
  course_credits: '',
}
