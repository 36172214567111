import React from 'react'
import {COURSE_SIDEBAR_ITEMS} from '../../../../constants/courseSidebarItems.constants'
import styles from './CourseSidebar.module.css'
import classNames from 'classnames'
import {Link, matchPath} from 'react-router-dom'
import {hideSidebarAction} from '../../../../redux/actions/common.actions'
import {Icon} from '@teachmint/common'
import {useDispatch} from 'react-redux'
import {useTranslation} from 'react-i18next'
import {COURSE, DEPARTMENTS} from '../../../../constants/pages.constants'
import history from '../../../../utils/history'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../utils/routing.helpers'

export default function CourseSidebar() {
  const dispatch = useDispatch()
  const {t} = useTranslation()

  return (
    <div
      className={styles.sidebarOuterWrapper}
      onClick={() => dispatch(hideSidebarAction())}
    >
      <div className={styles.sidebarWrapper}>
        <div
          onClick={() => history.push(DEPARTMENTS)}
          className={styles.backToDepartmentsWrapper}
        >
          <Icon name="backArrow" type="filled" size="xxs" color="secondary" />
          <div
            className={classNames('tm-para-16', styles.backToDepartmentsLabel)}
          >
            {t('COURSE_SIDEBAR__BACK_TO_DASHBORD_LABEL')}
          </div>
        </div>

        <div>
          {COURSE_SIDEBAR_ITEMS.filter(({active}) => active).map(
            ({id, label, icon, route, childRoutes}) => {
              // Check for current route
              let isActiveTab = matchPath(window.location.pathname, {
                path: route,
              })?.isExact

              // Check for parent route
              !isActiveTab &&
                childRoutes?.forEach((path) => {
                  if (matchPath(window.location.pathname, {path})?.isExact)
                    isActiveTab = true
                })

              return (
                <div key={id}>
                  <Link
                    className={classNames(
                      styles.sidebarItemLink,
                      isActiveTab ? 'tm-bgcr-bl-4' : ''
                    )}
                    onClick={() => dispatch(hideSidebarAction())}
                    to={updatePathWithParams(
                      route,
                      getParamsFromPath(matchPath, COURSE)
                    )}
                  >
                    <div className={styles.sidebarItemLinkInner}>
                      <Icon
                        name={icon}
                        type="filled"
                        size="xs"
                        color={`${isActiveTab ? 'primary' : 'basic'}`}
                      />

                      <div
                        className={classNames(
                          'tm-para-16',
                          isActiveTab ? 'tm-cr-bl-2' : 'tm-cr-bl-1',
                          styles.sidebarItemLabel
                        )}
                      >
                        {t(label)}
                      </div>
                    </div>
                  </Link>
                </div>
              )
            }
          )}
        </div>
      </div>
    </div>
  )
}
