import Joi from 'joi'

const VALIDATION_SCHEMA = {
  usernameInput: Joi.string().min(0).max(50).label('Username'),
  username: Joi.string().required().max(50).label('Username'),

  passwordInput: Joi.string().min(0).max(50).label('Password'),
  password: Joi.string().required().max(50).label('Password'),
}

const MODAL_FIELDS = {
  user_name: {
    fieldType: 'text',
    label: 'usernameLabel',
    placeholder: 'usernamePlaceholder',
    fieldName: 'user_name',
    options: {
      inputValidation: VALIDATION_SCHEMA.usernameInput,
      finalValidation: VALIDATION_SCHEMA.username,
    },
  },
  password: {
    fieldType: 'password',
    label: 'passwordLabel',
    placeholder: 'passwordPlaceholder',
    fieldName: 'password',
    options: {
      inputValidation: VALIDATION_SCHEMA.passwordInput,
      finalValidation: VALIDATION_SCHEMA.password,
    },
  },
}

export const LOGIN_FIELDS = {
  user_name: MODAL_FIELDS.user_name,
  password: MODAL_FIELDS.password,
}
export const DEFAULT_LOGIN_FIELDS_VALUES = {
  user_name: '',
  password: '',
}
