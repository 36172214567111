export const instituteOnboarding = {
  departmentHeading: 'Create Department',
  departmentDescription:
    'Departments that are dedicated to particular academic discipline',
  departmentSetup: 'Setup Department',
  departmentDetails: 'Department details',
  departmentData: 'Department 1',
  departmentAdd: 'Add program in this department',
  addMorePrograms: '+ Add more Programs',
  addNewDepartments: '+ Add new Departments',
  dateRule: 'End date must be greater than start date',
  sessionHeading: 'Create Session',
  sessionDescription:
    'Divide your academic year into different sessions to facilitate continous learning and feedback',
}
