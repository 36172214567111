import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  durationInput: Joi.string().min(0).max(2).pattern(/^\d*$/).label('Duration'),
  duration: Joi.string().required().max(2).pattern(/^\d*$/).label('Duration'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_PROGRAM__NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_PROGRAM__NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  duration: {
    fieldType: 'valueDropdown',
    label: 'SLIDER_ADD_DEPARTMENT_PROGRAM__DURATION_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_PROGRAM__DURATION_PLACEHOLDER',
    fieldName: 'duration',
    options: {
      inputValidation: VALIDATION_SCHEMA.durationInput,
      finalValidation: VALIDATION_SCHEMA.duration,
      options: [
        {value: 'years', label: 'Years'},
        {value: 'months', label: 'Months'},
        {value: 'weeks', label: 'Weeks'},
        {value: 'days', label: 'Days'},
      ],
    },
  },
}

export const ADD_DEPARTMENT_PROGRAM_FIELDS = {
  name: MODAL_FIELDS.name,
  duration: MODAL_FIELDS.duration,
}
export const DEFAULT_ADD_DEPARTMENT_PROGRAM_FIELDS_VALUES = {
  name: '',
  duration: '',
  durationType: 'years',
}
