import Joi from 'joi'

const VALIDATION_SCHEMA = {
  emailInput: Joi.string().min(0).max(100).label('Email Address'),
  email: Joi.string()
    .required()
    .regex(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
    .label('Email Address')
    .messages({
      'string.pattern.base': 'Enter valid email',
    }),
  // .messages({ 'Enter valid email'}),
}

const MODAL_FIELDS = {
  email: {
    fieldType: 'text',
    label: 'forgotPasswordEmailLabel',
    placeholder: 'forgotPasswordEmailPlaceholder',
    fieldName: 'email',
    options: {
      inputValidation: VALIDATION_SCHEMA.emailInput,
      finalValidation: VALIDATION_SCHEMA.email,
    },
  },
}

export const FORGOT_PASSWORD_FIELDS = {
  email: MODAL_FIELDS.email,
}
export const DEFAULT_FORGOT_PASSWORD_FIELDS_VALUES = {
  email: '',
}
