import {useRef, useState} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './DropdownField.module.css'
import {Icon, useOutsideClickHandler} from '@teachmint/common'

export default function DropdownField({
  fieldName,
  value,
  onChange,
  // showFieldBorder,
  options,
}) {
  const [showDropdown, setShowDropdown] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideClickHandler(wrapperRef, () => setShowDropdown(false))

  const renderOptions = () =>
    options?.map((item) => (
      <div
        className={styles.option}
        key={item?.value}
        onClick={(e) => onChange({fieldName, value: item?.value, event: e})}
      >
        {item?.children || item?.label}
      </div>
    ))

  const getLabel = (val) => {
    const item = options.find((option) => option.value === val)
    return (
      item?.children ||
      item?.label || <div className={styles.placeholder}>Select</div>
    )
  }

  return (
    <div
      className={classNames(styles.dropdown)}
      onClick={() => setShowDropdown(!showDropdown)}
      onKeyDown={() => setShowDropdown(!showDropdown)}
      ref={wrapperRef}
    >
      <div className={styles.placeholderWrapper}>
        {getLabel(value)}
        <Icon name="downArrow" size="xxs" className={styles.dropdownIcon} />
      </div>
      {showDropdown && options?.length && (
        <div className={classNames(styles.optionsBox)}>{renderOptions()}</div>
      )}
    </div>
  )
}

DropdownField.propTypes = {
  fieldName: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  // showFieldBorder: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
}

DropdownField.defaultProps = {
  fieldName: '',
  value: '',
  onChange: () => {},
  // showFieldBorder: false,
  options: [],
}
