import Joi from 'joi'

const VALIDATION_SCHEMA = {
  nameInput: Joi.string().min(0).max(50).label('Name'),
  name: Joi.string().required().max(50).label('Name'),

  userNameInput: Joi.string().min(0).max(50).label('Username'),
  userName: Joi.string().required().max(50).label('Username'),

  emailInput: Joi.string().min(0).max(100).label('Email Address'),
  email: Joi.string()
    .max(100)
    .email({tlds: {allow: false}})
    .label('Email Address'),

  programBatchId: Joi.string().required().not('select').label('Program batch'),
}

const MODAL_FIELDS = {
  name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__NAME_PLACEHOLDER',
    fieldName: 'name',
    options: {
      inputValidation: VALIDATION_SCHEMA.nameInput,
      finalValidation: VALIDATION_SCHEMA.name,
    },
  },
  user_name: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__USER_NAME_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__USER_NAME_PLACEHOLDER',
    fieldName: 'user_name',
    options: {
      inputValidation: VALIDATION_SCHEMA.userNameInput,
      finalValidation: VALIDATION_SCHEMA.userName,
    },
  },
  email: {
    fieldType: 'text',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__EMAIL_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__EMAIL_PLACEHOLDER',
    fieldName: 'email',
    options: {
      inputValidation: VALIDATION_SCHEMA.emailInput,
      finalValidation: VALIDATION_SCHEMA.email,
    },
  },
  program_batch_id: {
    fieldType: 'dropdown',
    label: 'SLIDER_ADD_DEPARTMENT_STUDENT__PROGRAM_LABEL',
    placeholder: 'SLIDER_ADD_DEPARTMENT_STUDENT__PROGRAM_PLACEHOLDER',
    fieldName: 'program_batch_id',
    options: {
      finalValidation: VALIDATION_SCHEMA.programBatchId,
      options: [],
    },
  },
}

export const ADD_DEPARTMENT_STUDENT_FIELDS = {
  name: MODAL_FIELDS.name,
  user_name: MODAL_FIELDS.user_name,
  email: MODAL_FIELDS.email,
  program_batch_id: MODAL_FIELDS.program_batch_id,
}
export const DEFAULT_ADD_DEPARTMENT_STUDENT_FIELDS_VALUES = {
  name: '',
  user_name: '',
  email: '',
  program_batch_id: 'select',
}
