import React, {useEffect} from 'react'
import {matchPath, Route, Switch} from 'react-router-dom'
import LinearTabOptions from '../../../../components/common/LinearTabOptions/LinearTabOptions'
import {
  MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS,
  MANAGE_DEPARTMENT__MANAGE_USER__FACULTY,
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__MANAGE_USER,
} from '../../../../constants/pages.constants'
import {useTranslation} from 'react-i18next'
import styles from './DepartmentManageUser.module.css'
import DepartmentStudentPage from './components/DepartmentStudentPage/DepartmentStudentPage'
import DepartmentFacultyPage from './components/DepartmentFacultyPage/DepartmentFacultyPage'
import history from '../../../../utils/history'
import {useDispatch} from 'react-redux'

import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../utils/routing.helpers'
import globalActions from '../../../../redux/actions/global.actions'

export default function DepartmentManageUser() {
  const dispatch = useDispatch()
  const {t} = useTranslation()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  // Redirect to students tab if manage user url selected
  if (
    matchPath(window.location.pathname, {path: MANAGE_DEPARTMENT__MANAGE_USER})
      .isExact
  )
    history.replace(
      updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, params)
    )

  useEffect(() => {
    if (params?.departmentId)
      dispatch(globalActions?.getDepartmentUsers?.request(params?.departmentId))
  }, [params?.departmentId])

  const tabOptions = [
    {
      route: updatePathWithParams(
        MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS,
        params
      ),
      isSelected: matchPath(window.location.pathname, {
        path: MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS,
      }),
      label: t('DEPARTMENT_MANAGE_USER__STUDENT_TAB_LABEL'),
    },
    {
      route: updatePathWithParams(
        MANAGE_DEPARTMENT__MANAGE_USER__FACULTY,
        params
      ),
      isSelected: matchPath(window.location.pathname, {
        path: MANAGE_DEPARTMENT__MANAGE_USER__FACULTY,
      }),
      label: t('DEPARTMENT_MANAGE_USER__FACULTY_TAB_LABEL'),
    },
  ]

  return (
    <div>
      <LinearTabOptions options={tabOptions} isRouteBased={true} />

      <Switch>
        <Route
          path={MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS}
          component={DepartmentStudentPage}
        />
        <Route
          path={MANAGE_DEPARTMENT__MANAGE_USER__FACULTY}
          component={DepartmentFacultyPage}
        />
      </Switch>
    </div>
  )
}
