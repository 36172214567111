import {call, put, takeEvery} from 'redux-saga/effects'
import {
  showErrorToast,
  showSuccessToast,
} from '../../redux/actions/common.actions'
import {getFromSessionStorage} from '../../utils/storage.helpers'
import * as Api from './apiService'
import globalActions from '../../redux/actions/global.actions'

function* getSessions({successAction, failureAction}) {
  try {
    const response = yield call(
      Api.utilsGetInstituteSessions,
      getFromSessionStorage('INSTITUTE_ID')
    )
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.getInstituteSession.success(response?.data?.obj))
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getInstituteSession.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetSessionsSaga() {
  yield takeEvery(globalActions.getInstituteSession.REQUEST, getSessions)
}

function* addSessionSaga({data, successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsAddInstituteSession, data)
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.addInstituteSession.success(response?.data?.obj))
      yield put(
        showSuccessToast(
          `${response?.data?.name ? 'Updated' : 'Added'} successfully`
        )
      )
      yield put({type: globalActions.getInstituteSession.REQUEST})
    }
  } catch (error) {
    failureAction?.()
    yield put(globalActions.addInstituteSession.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchAddSessionSaga() {
  yield takeEvery(globalActions.addInstituteSession.REQUEST, addSessionSaga)
}
