import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {COURSE__MANAGE_FACULTY__ADD} from '../../../../constants/pages.constants'
import SliderAddCourseFaculty from './components/SliderAddCourseFaculty/SliderAddCourseFaculty'
import CourseFacultyList from './components/CourseFacultyList/CourseFacultyList'
import styles from './CourseFaculty.module.css'

export default function CourseFaculty() {
  return (
    <div>
      <CourseFacultyList />

      <Switch>
        <Route
          path={COURSE__MANAGE_FACULTY__ADD}
          component={SliderAddCourseFaculty}
        />
      </Switch>
    </div>
  )
}
