import React, {useEffect, useState} from 'react'
import {
  utilsForgotPassword,
  utilsVerifyTemporaryPassword,
} from './../../apiServices'
import styles from './TemporaryPasswordPage.module.css'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../utils/validation'
import history from '../../../../utils/history'
import {
  LOGIN__RESET_PASSWORD,
  LOGIN__TEMPORARY_PASSWORD_PAGE,
} from '../../../../constants/pages.constants'
import {
  DEFAULT_TEMPORARY_PASSWORD_FIELDS_VALUES,
  TEMPORARY_PASSWORD_FIELDS,
} from './temporaryPasswordFormData'
import {REACT_APP_GOOGLE_RECAPTCHA} from '../../../../constants/envVars.constants'
import {useLocation} from 'react-router-dom'
import classNames from 'classnames'
import {
  getFromSessionStorage,
  getSubdomain,
  setToSessionStorage,
} from '../../../../utils/storage.helpers'
import KrayonInputField from '../../../../components/common/KrayonInputField/KrayonInputField'
import KrayonButton from '../../../../components/common/KrayonButton/KrayonButton'

export default function TemporaryPasswordPage({setGlobalError, setIsLoading}) {
  const [formData, setFormData] = useState(
    DEFAULT_TEMPORARY_PASSWORD_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const {t} = useTranslation()
  const search = useLocation()?.search
  const uuid = new URLSearchParams(search).get('uuid')

  useEffect(() => {
    return () => setGlobalError('')
  }, [])

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(
        formData,
        TEMPORARY_PASSWORD_FIELDS,
        fieldName,
        value
      )
    )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      TEMPORARY_PASSWORD_FIELDS,
      formData
    )

    if (isInputValid && uuid)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_GOOGLE_RECAPTCHA, {action: 'submit'})
          .then((token) => {
            setIsLoading(true)
            utilsVerifyTemporaryPassword({
              temporary_password: formData.password,
              uuid: uuid,
              subdomain: getSubdomain(),
              recaptcha_token: token,
            })
              .then(({data}) => {
                if (data?.status && data?.obj?.login_token) {
                  setToSessionStorage(
                    'UNIVERSITY__LOGIN_TOKEN',
                    data?.obj?.login_token
                  )
                  history.push(LOGIN__RESET_PASSWORD)
                } else setGlobalError(t(`ERROR_${data?.error_code}`))
              })
              .catch(() => setGlobalError(t('ERROR_1000')))
              .finally(() => setIsLoading(false))
          })
      })
  }

  const resendPassword = () => {
    const email = getFromSessionStorage('UNIVERSITY__EMAIL')
    if (email) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_GOOGLE_RECAPTCHA, {action: 'submit'})
          .then((token) => {
            setIsLoading(true)
            utilsForgotPassword({
              email,
              subdomain: getSubdomain(),
              recaptcha_token: token,
            })
              .then(({data}) => {
                if (data?.status && data?.obj?.uuid) {
                  history.push(
                    `${LOGIN__TEMPORARY_PASSWORD_PAGE}?uuid=${data?.obj?.uuid}`
                  )
                } else setGlobalError(t(`ERROR_${data?.error_code}`))
              })
              .catch(() => setGlobalError(t('ERROR_1000')))
              .finally(() => setIsLoading(false))
          })
      })
    }
  }

  window.localStorage.setItem('UNIVERSITY__EMAIL', formData?.email)

  return (
    <form>
      <div className={classNames('tm-n-hdg-20', styles.heading)}>
        {t('LOGIN__TEMPORARY_PASSWORD__HEADING')}
      </div>
      <div className={classNames('tm-n-para-14', styles.para)}>
        {t('LOGIN__TEMPORARY_PASSWORD__PARAGRAPH')}
      </div>

      <div className={classNames('tm-n-hdg-16', styles.resendWrapper)}>
        {`${t('LOGIN__TEMPORARY_PASSWORD__NOT_RECEIVED')} `}
        <span
          className={classNames('tm-stencil-color-primary-1', styles.resendBtn)}
          onClick={resendPassword}
        >
          {t('LOGIN__TEMPORARY_PASSWORD__RESEND_PASSWORD')}
        </span>
      </div>

      {Object.values(TEMPORARY_PASSWORD_FIELDS)?.map(
        ({fieldType, fieldName, label, placeholder}) => (
          <div key={fieldName} className={styles.inputWrapper}>
            <KrayonInputField
              fieldType={fieldType}
              label={t(label)}
              fieldName={fieldName}
              value={formData[fieldName]}
              onChange={handleInputChange}
              placeholder={t(placeholder)}
              infoText={errorObject[fieldName]}
            />
          </div>
        )
      )}

      <KrayonButton className={styles.submitButton} onClick={handleFormSubmit}>
        {t('COMMON_SUBMIT')}
      </KrayonButton>
    </form>
  )
}
