import React from 'react'
import styles from './CourseNavbar.module.css'
import classNames from 'classnames'
import {Icon} from '@teachmint/common'
import {useDispatch, useSelector} from 'react-redux'
import {
  showFeedbackSliderAction,
  showSidebarAction,
} from '../../../../redux/actions/common.actions'

export default function Navbar() {
  const dispatch = useDispatch()
  const {userInfo} = useSelector((state) => state)

  return (
    <div className={styles.navbarWrapper}>
      <div className={styles.leftWrapper}>
        <div
          className={styles.burgerIconWrapper}
          onClick={() => dispatch(showSidebarAction())}
        >
          <Icon name="preferences" type="filled" size="xs" />
        </div>

        <div className={styles.instituteWrapper}>
          <img
            className={styles.instituteLogo}
            src="https://www.teachmint.com/institute/static/media/institute-default.201512be.svg"
            alt=""
          />

          <div className={styles.instituteInfoWrapper}>
            <div className="tm-hdg-16 lg:tm-hdg-18">Demo College</div>
            <div
              className={classNames(
                'tm-para-14 lg:tm-para-16',
                styles.instituteId
              )}
            >
              Institute Id: DEM456
            </div>
          </div>
        </div>
      </div>

      <div className={styles.rightWrapper}>
        <div
          className={styles.helpBtnWrapper}
          onClick={() => dispatch(showFeedbackSliderAction())}
        >
          <Icon name="doubt" type="filled" size="xs" color="secondary" />
        </div>

        <div className={styles.userInfoWrapper}>
          <img
            className={styles.userImage}
            src="https://www.teachmint.com/institute/static/media/institute-default.201512be.svg"
            alt=""
          />

          <div
            className={classNames('tm-hdg-16 lg:tm-hdg-18', styles.userName)}
          >
            {userInfo?.userInfo?.name}
          </div>
        </div>
      </div>
    </div>
  )
}
