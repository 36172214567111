import React from 'react'
import styles from './DepartmentProgramList.module.css'
import {useSelector} from 'react-redux'
import EmptyScreen from '../../../../../../components/common/EmptyScreen/EmptyScreen'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__PROGRAMS__ADD,
  MANAGE_DEPARTMENT__PROGRAMS__ADD_BATCH,
} from '../../../../../../constants/pages.constants'
import history from '../../../../../../utils/history'
import {useTranslation} from 'react-i18next'
import {matchPath} from 'react-router-dom'
import {
  timestampToFormat,
  YYYY_MM_DD_FORMAT,
} from '../../../../../../utils/datetime.helpers'
import classNames from 'classnames'
import {Button} from '@teachmint/common'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'

export default function DepartmentProgramList() {
  const {departmentProgramInfo} = useSelector((state) => state)

  const {t} = useTranslation()

  const getAddProgramPath = () =>
    updatePathWithParams(
      MANAGE_DEPARTMENT__PROGRAMS__ADD,
      getParamsFromPath(matchPath, MANAGE_DEPARTMENT)
    )

  const getAddProgramBatchPath = () =>
    updatePathWithParams(
      MANAGE_DEPARTMENT__PROGRAMS__ADD_BATCH,
      getParamsFromPath(matchPath, MANAGE_DEPARTMENT)
    )

  return (
    <>
      {departmentProgramInfo?.departmentProgramList?.length > 0 ? (
        <>
          <div className={styles.addProgramBtnWrapper}>
            <Button
              className={styles.button}
              size="medium"
              onClick={() => history.push(getAddProgramPath())}
            >
              {t('DEPARTMENT_PROGRAM__PROGRAM__EMPTY_BTN_LABEL')}
            </Button>
          </div>

          {departmentProgramInfo?.departmentProgramList?.map(
            ({_id, name, program_batches}) => (
              <div key={_id} className={styles.item}>
                <div className={styles.itemHeader}>
                  <div className="tm-hdg-16">{name}</div>
                </div>

                <div className={styles.programBatchWrapper}>
                  <div
                    className={classNames(
                      styles.batchItem,
                      styles.emptyBatchItem
                    )}
                    onClick={() =>
                      history.push(
                        `${getAddProgramBatchPath()}?program_id=${_id}`
                      )
                    }
                  >
                    <div className="tm-hdg-16 tm-cr-bl-2">
                      {t('DEPARTMENT_PROGRAM__PROGRAM__ADD_PROGRAM_BATCH')}
                    </div>
                  </div>

                  {program_batches?.map(({_id, name, start_date, end_date}) => (
                    <div key={_id} className={styles.batchItem}>
                      <div className="tm-hdg-16"> {name}</div>
                      <div
                        className={classNames('tm-para-14', styles.dateWrapper)}
                      >
                        {`${timestampToFormat(
                          YYYY_MM_DD_FORMAT,
                          start_date
                        )}-${timestampToFormat(YYYY_MM_DD_FORMAT, end_date)}`}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )
          )}
        </>
      ) : (
        <div className={styles.emptyWrapper}>
          <EmptyScreen
            icon="https://img.freepik.com/free-vector/shop-cart-shop-building-cartoon_138676-2085.jpg"
            label={t('DEPARTMENT_PROGRAM__PROGRAM__EMPTY_LABEL')}
            desc={t('DEPARTMENT_PROGRAM__PROGRAM__EMPTY_DESC')}
            btnOptions={{
              label: t('DEPARTMENT_PROGRAM__PROGRAM__EMPTY_BTN_LABEL'),
              action: () => history.push(getAddProgramPath()),
            }}
          />
        </div>
      )}
    </>
  )
}
