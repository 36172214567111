import React, {useState} from 'react'
import {Button, Icon, Input} from '@teachmint/common'
import {useDispatch, useSelector} from 'react-redux'
import SliderScreen from '../../../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../../../utils/history'
import styles from './SliderAddCourse.module.css'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../../../utils/validation'
import {
  ADD_DEPARTMENT_COURSE_OFFERING_FIELDS,
  DEFAULT_ADD_DEPARTMENT_COURSE_OFFERING_FIELDS_VALUES,
} from './addCourseFormData'

import {produce} from 'immer'
import {matchPath} from 'react-router-dom'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__COURSES,
} from '../../../../../../constants/pages.constants'
import classNames from 'classnames'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'
import globalActions from '../../../../../../redux/actions/global.actions'

const generateNewSectionName = (sectionFormData) => {
  const names = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']
  let generatedName = ''
  let oldSectionNameArr = sectionFormData?.map(({name}) => name) || []

  for (let i = 0; i < names.length; i++) {
    if (!oldSectionNameArr.includes(names[i])) {
      generatedName = names[i]
      break
    }
  }

  return generatedName
}

export default function SliderAddCourse() {
  const [formData, setFormData] = useState(
    DEFAULT_ADD_DEPARTMENT_COURSE_OFFERING_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const [sectionFormData, setSectionFormData] = useState([{name: 'A'}])
  const [sectionErrorObject, setSectionErrorObject] = useState({})

  const {instituteSessionInfo, departmentCourseInfo} = useSelector(
    (state) => state
  )

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_DEPARTMENT_COURSE_OFFERING_FIELDS,
        fieldName,
        String(value)
      )
    )

  const handleAddSection = () => {
    const sectionFormDataTemp = JSON.parse(JSON.stringify(sectionFormData))
    sectionFormDataTemp.push({name: generateNewSectionName(sectionFormData)})
    setSectionFormData(sectionFormDataTemp)
  }

  const handleRemoveSection = (index) => {
    const sectionFormDataTemp = JSON.parse(JSON.stringify(sectionFormData))
    sectionFormDataTemp.splice(index, 1)
    setSectionFormData(sectionFormDataTemp)
  }

  // useEffect(() => {
  //   const sectionNum = formData?.section_num
  //   const sectionFormDataTemp = JSON.parse(JSON.stringify(sectionFormData))

  //   if(sectionNum !== ''){
  //     if(sectionNum< sectionFormData?.length){

  //     }
  //   }
  // }, [formData?.section_num])

  const handleSectionInputChange = ({fieldName, value}) => {
    const sectionFormDataTemp = JSON.parse(JSON.stringify(sectionFormData))
    sectionFormDataTemp[fieldName].name = value
    setSectionFormData(sectionFormDataTemp)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_DEPARTMENT_COURSE_OFFERING_FIELDS,
      formData
    )

    if (isInputValid && params?.departmentId) {
      const successAction = () => {
        dispatch(
          globalActions?.getDepartmentCourses?.request(params?.departmentId)
        )
        history.push(updatePathWithParams(MANAGE_DEPARTMENT__COURSES, params))
      }

      dispatch(
        globalActions?.addDepartmentCourse?.request(
          {...formData, classes: sectionFormData},
          successAction
        )
      )
    }
  }

  const courseOfferingfields = produce(
    ADD_DEPARTMENT_COURSE_OFFERING_FIELDS,
    (draft) => {
      draft.node_id.options.options = [
        {value: 'select', label: 'Select'},
        ...departmentCourseInfo?.departmentCourseMasterList?.map(
          ({_id, name}) => {
            return {value: _id, label: name}
          }
        ),
      ]
      draft.institute_session_id.options.options = [
        {value: 'select', label: 'Select'},
        ...instituteSessionInfo?.sessionList?.map(({_id, name}) => {
          return {value: _id, label: name}
        }),
      ]
      return draft
    }
  )

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_COURSE_OFFERING__LABEL')}
          />

          <SliderScreenBody>
            <div>
              <form>
                {Object.values(courseOfferingfields)?.map(
                  ({fieldType, fieldName, label, placeholder, options}) => (
                    <div key={fieldName} className={styles.inputWrapper}>
                      <Input
                        type={fieldType}
                        title={t(label)}
                        fieldName={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        placeholder={t(placeholder)}
                        errorMsg={errorObject[fieldName]}
                        options={options.options}
                      />
                    </div>
                  )
                )}

                <div className={styles.sectionFieldsWrapper}>
                  <div className="tm-hdg-16">
                    {t('SLIDER_ADD_COURSE_OFFERING__SECTION_LABEL')}
                  </div>

                  <div className={styles.sectionFieldsInnerWrapper}>
                    {sectionFormData?.map(({name}, index) => (
                      <div
                        key={index}
                        className={styles.sectionInputItemWrapper}
                      >
                        <div
                          className={classNames(
                            styles.inputWrapper,
                            styles.sectionInputWrapper
                          )}
                        >
                          <Input
                            type="text"
                            title=""
                            fieldName={index}
                            value={name}
                            onChange={handleSectionInputChange}
                            placeholder={t(
                              'SLIDER_ADD_COURSE_OFFERING__SECTION_PLACEHOLDER'
                            )}
                            errorMsg={sectionErrorObject[index]}
                          />
                        </div>

                        <div
                          onClick={() => handleRemoveSection(index)}
                          className={styles.crossIcon}
                        >
                          <Icon
                            name="close"
                            type="filled"
                            size="s"
                            color="error"
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  {sectionFormData?.length < 10 && (
                    <div
                      className={classNames(
                        'tm-hdg-14 tm-cr-bl-2',
                        styles.addSectionBtn
                      )}
                      onClick={handleAddSection}
                    >
                      {t('SLIDER_ADD_COURSE_OFFERING__ADD_SECTION_LABEL')}
                    </div>
                  )}
                </div>

                <Button
                  className={styles.submitButton}
                  size="medium"
                  onClick={handleFormSubmit}
                >
                  {t('COMMON_SUBMIT')}
                </Button>
              </form>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
