import React from 'react'
import styles from './LinearTabOptions.module.css'
import classNames from 'classnames'
import history from '../../../utils/history'

export default function LinearTabOptions({
  options,
  selected,
  isRouteBased,
  handleChange,
}) {
  return (
    <div className={styles.wrapper}>
      {options.map(({id, label, route, routeToCheck, isSelected}) => {
        const isTabSelected = isRouteBased ? isSelected : id === selected

        return (
          <div
            key={isRouteBased ? routeToCheck : id}
            className={classNames(
              'tm-para-14 lg:tm-para-16',
              styles.item,
              isTabSelected ? styles.itemSelected : ''
            )}
            onClick={() =>
              isRouteBased ? history.push(route) : handleChange(id)
            }
          >
            {label}
          </div>
        )
      })}
    </div>
  )
}
