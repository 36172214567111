import React from 'react'
import styles from './Navbar.module.css'
import classNames from 'classnames'
import {useDispatch, useSelector} from 'react-redux'
import {
  showFeedbackSliderAction,
  showSidebarAction,
} from '../../../../redux/actions/common.actions'
import KrayonIcon from '../../../../components/common/KrayonIcon/KrayonIcon'
import KrayonIconWrapper from '../../../../components/common/KrayonIconWrapper/KrayonIconWrapper'

export default function Navbar() {
  const dispatch = useDispatch()
  const {userInfo, globalData} = useSelector((state) => state)

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftWrapper}>
        <div
          className={styles.burgerIconWrapper}
          onClick={() => dispatch(showSidebarAction())}
        >
          <KrayonIcon name="menu" />
        </div>

        <img
          className={styles.instituteLogo}
          src={globalData?.instituteInfo?.data?.group_institute?.cover_img_url}
          alt=""
        />
      </div>

      <div className={styles.rightWrapper}>
        <KrayonIconWrapper
          size="small"
          wrapperColor="grey20"
          iconName="notifications"
          className={styles.notification}
          onClick={() => dispatch(showFeedbackSliderAction())}
        />

        <div className={styles.userInfoWrapper}>
          <img
            className={styles.userImage}
            src="https://www.teachmint.com/institute/static/media/institute-default.201512be.svg"
            alt=""
          />
        </div>
      </div>
    </div>
  )
}
