import {combineReducers} from 'redux'
import {commonActionTypes} from '../actionTypes'
import {toastsReducer} from './toast.reducer'

import {
  feedbackSliderReducer,
  setSelectedInstituteReducer,
  sidebarReducer,
} from './common.reducer'
// import userInfoReducer from '../../pages/UserInfo/redux/reducers'
// import departmentProgramReducers from '../../pages/DepartmentsPage/pages/DepartmentPrograms/redux/reducers'
// import departmentCourseReducers from '../../pages/DepartmentsPage/pages/DepartmentCourses/redux/reducers'
// import departmentUserReducers from '../../pages/DepartmentsPage/pages/DepartmentManageUser/redux/reducers'
// import courseUsersReducers from '../../pages/Course/pages/CourseStudents/redux/reducers'
// import courseInfoReducers from '../../pages/Course/redux/reducers'
// import courseRecordingReducers from '../../pages/Course/pages/CourseRecordings/redux/reducers'
import {globalReducers} from './global.reducer'

const appReducer = combineReducers({
  showSidebar: sidebarReducer,
  toasts: toastsReducer,
  showFeedbackSlider: feedbackSliderReducer,
  selectedInstitute: setSelectedInstituteReducer,
  // ...userInfoReducer,
  // ...departmentProgramReducers,
  // ...departmentCourseReducers,
  // ...departmentUserReducers,
  // ...courseUsersReducers,
  // ...courseInfoReducers,
  // ...courseRecordingReducers,
  globalData: globalReducers,
})

export const rootReducer = (state, action) => {
  if (action.type === commonActionTypes.LOGOUT_USER) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
