import React from 'react'
import styles from './DepartmentStudentPage.module.css'
import {Route, Switch} from 'react-router-dom'
import {MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS__ADD} from '../../../../../../constants/pages.constants'
import SliderAddDepartmentStudent from '../SliderAddDepartmentStudent/SliderAddDepartmentStudent'
import DepartmentStudentListPage from '../DepartmentStudentListPage/DepartmentStudentListPage'

export default function DepartmentStudentPage() {
  return (
    <div>
      <DepartmentStudentListPage />

      <Switch>
        <Route
          path={MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS__ADD}
          component={SliderAddDepartmentStudent}
        />
      </Switch>
    </div>
  )
}
