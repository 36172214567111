import React from 'react'
import {Router, Route, Switch, matchPath} from 'react-router-dom'
import {COURSE, DASHBOARD, LOGIN} from './constants/pages.constants'
import Login from './pages/Login/Login'
import history from './utils/history'
import axios from 'axios'
import Home from './pages/Home/Home'
import {ErrorBoundary} from '@teachmint/common'
import AppToastStack from './components/common/AppToastStack/AppToastStack'
import Course from './pages/Course/Course'

axios.defaults.withCredentials = true

function App() {
  // Redirect to login page if user lands on / route
  if (matchPath(window.location.pathname, {path: '/'}).isExact)
    history.replace(LOGIN)

  return (
    <div>
      <ErrorBoundary>
        <AppToastStack />
      </ErrorBoundary>

      <Router history={history}>
        <Switch>
          <Route path={LOGIN} component={Login} />
          <Route path={DASHBOARD} component={Home} />
          <Route path={COURSE} component={Course} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
