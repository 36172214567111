import produce from 'immer'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import CollapseCard from '../../../../../components/common/CollapseCard/CollapseCard'
import KrayonButton from '../../../../../components/common/KrayonButton/KrayonButton'
import KrayonH5 from '../../../../../components/common/KrayonH5/KrayonH5'
import KrayonInputField from '../../../../../components/common/KrayonInputField/KrayonInputField'
import KrayonP2 from '../../../../../components/common/KrayonP2/KrayonP2'
import {MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS} from '../../../../../constants/pages.constants'
import globalActions from '../../../../../redux/actions/global.actions'
import history from '../../../../../utils/history'
import {updatePathWithParams} from '../../../../../utils/routing.helpers'
import {getFromSessionStorage} from '../../../../../utils/storage.helpers'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../../utils/validation'
import {
  ADD_DEPARTMENT_FIELDS,
  DEFAULT_ADD_DEPARTMENT_FIELDS_VALUES,
} from '../../../../DepartmentsPage/components/SliderAddDepartment/addDepartmentFormData'
import {
  ADD_DEPARTMENT_PROGRAM_FIELDS,
  DEFAULT_ADD_DEPARTMENT_PROGRAM_FIELDS_VALUES,
} from '../../../../DepartmentsPage/pages/DepartmentPrograms/components/SliderAddProgram/addProgramFormData'
// import {addDepartmentProgram} from '../../../../DepartmentsPage/pages/DepartmentPrograms/redux/actions/departmentProgram.actions'
import styles from './DepartmentForm.module.css'
// import globalActions from '../../../../../redux/actions/global.actions'

export default function DepartmentForm() {
  const [formData, setFormData] = useState([
    {...DEFAULT_ADD_DEPARTMENT_FIELDS_VALUES, collapse: false},
  ])
  const [errorObject, setErrorObject] = useState([])
  const [programFormData, setProgramFormData] = useState([
    [DEFAULT_ADD_DEPARTMENT_PROGRAM_FIELDS_VALUES],
  ])
  const [programErrorObject, setProgramErrorObject] = useState([])

  const dispatch = useDispatch()
  const {t} = useTranslation('instituteOnboarding')
  const handleInputChange = (departmentIndex, {fieldName, value}) => {
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_DEPARTMENT_FIELDS,
        fieldName,
        value,
        [departmentIndex]
      )
    )
  }

  const handleProgramInputChange = (
    departmentIndex,
    programIndex,
    {fieldName, value}
  ) => {
    setProgramFormData(
      utilsHandleInputChange(
        programFormData,
        ADD_DEPARTMENT_PROGRAM_FIELDS,
        fieldName,
        value,
        [departmentIndex, programIndex]
      )
    )
  }

  // Autofill course prefix if department name changed
  // useEffect(() => {
  //   let generatedName = ''
  //   const words = formData?.name?.split(' ')?.filter((word) => word?.length > 0)
  //   if (words?.length > 1)
  //     generatedName = words
  //       ?.slice(0, 2)
  //       ?.map((word) => word[0])
  //       ?.join('')
  //   else generatedName = words?.[0]?.slice(0, 2) || ''

  //   setFormData(
  //     utilsHandleInputChange(
  //       formData,
  //       ADD_DEPARTMENT_FIELDS,
  //       ADD_DEPARTMENT_FIELDS.course_prefix.fieldName,
  //       generatedName.toUpperCase()
  //     )
  //   )
  // }, [formData?.name])

  const addProgramToDepartment = (index) => {
    const programFormDataTemp = produce(programFormData, (draft) => {
      draft?.[index]?.push(DEFAULT_ADD_DEPARTMENT_PROGRAM_FIELDS_VALUES)
      return draft
    })
    setProgramFormData(programFormDataTemp)
  }

  const addDepartment = (e) => {
    e.preventDefault()
    const formDataTemp = produce(formData, (draft) => {
      draft.push({
        ...DEFAULT_ADD_DEPARTMENT_FIELDS_VALUES,
        collapse: false,
      })
      return draft
    })
    setFormData(formDataTemp)

    const programFormDataTemp = produce(programFormData, (draft) => {
      draft.push([DEFAULT_ADD_DEPARTMENT_PROGRAM_FIELDS_VALUES])
      return draft
    })
    setProgramFormData(programFormDataTemp)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    let isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_DEPARTMENT_FIELDS,
      formData
    )

    // Validate all fields
    isInputValid = utilsFinalFormSubmitChecks(
      setProgramErrorObject,
      ADD_DEPARTMENT_PROGRAM_FIELDS,
      programFormData
    )

    const instituteId = getFromSessionStorage('INSTITUTE_ID')
    if (isInputValid && instituteId) {
      const successAction = (department) => {
        dispatch(
          globalActions.addDepartmentProgram(
            {...programFormData, node_id: department?._id},
            () => {
              history.push(
                updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, {
                  departmentId: department?._id,
                })
              )
            }
          )
        )
      }

      dispatch(
        globalActions?.addInstituteDepartment?.request(
          {...formData, node_id: instituteId},
          successAction
        )
      )
    }
  }

  return (
    <>
      <div className={styles.formOuterWrapper}>
        <form>
          <KrayonH5 className={styles.formHeading}>
            {t('departmentDetails')}
          </KrayonH5>

          {formData?.map((departmentItem, departmentIndex) => (
            <CollapseCard
              key={departmentIndex}
              label={t('departmentData')}
              collapse={departmentItem?.collapse}
              setCollapse={() =>
                handleInputChange(departmentIndex, {
                  fieldName: 'collapse',
                  value: !departmentItem?.collapse,
                })
              }
              className={styles.collapseCard}
            >
              <div className={styles.formWrapper}>
                {Object.values(ADD_DEPARTMENT_FIELDS)?.map(
                  ({fieldType, fieldName, label, placeholder}) => (
                    <KrayonInputField
                      fieldType={fieldType}
                      label={t('translation:' + label)}
                      fieldName={fieldName}
                      value={departmentItem?.[fieldName]}
                      onChange={(data) =>
                        handleInputChange(departmentIndex, data)
                      }
                      placeholder={t('translation:' + placeholder)}
                      infoText={errorObject?.[departmentIndex]?.[fieldName]}
                    />
                  )
                )}
              </div>

              <KrayonP2 type="medium" className={styles.programHeading}>
                {t('departmentAdd')}
              </KrayonP2>

              <div className={styles.programFormOuterWrapper}>
                {programFormData?.[departmentIndex]?.map(
                  (programItem, programIndex) => (
                    <div className={styles.programFormWrapper}>
                      {Object.values(ADD_DEPARTMENT_PROGRAM_FIELDS)?.map(
                        ({
                          fieldType,
                          fieldName,
                          label,
                          placeholder,
                          options,
                        }) => (
                          <KrayonInputField
                            fieldType={fieldType}
                            label={t('translation:' + label)}
                            fieldName={fieldName}
                            value={programItem?.[fieldName]}
                            onChange={(data) =>
                              handleProgramInputChange(
                                departmentIndex,
                                programIndex,
                                data
                              )
                            }
                            placeholder={t('translation:' + placeholder)}
                            infoText={
                              programErrorObject?.[departmentIndex]?.[
                                programIndex
                              ]?.[fieldName]
                            }
                            options={options}
                            secondValue={programItem?.['durationType']}
                            secondFieldName="durationType"
                          />
                        )
                      )}
                    </div>
                  )
                )}
              </div>

              <KrayonButton
                type="text"
                onClick={(e) => {
                  e.preventDefault()
                  addProgramToDepartment(departmentIndex)
                }}
                className={styles.addButtonWrapper}
              >
                {t('addMorePrograms')}
              </KrayonButton>
            </CollapseCard>
          ))}
          <KrayonButton
            type="text"
            onClick={addDepartment}
            className={styles.addButtonWrapper}
          >
            {t('addNewDepartments')}
          </KrayonButton>
        </form>
      </div>

      <div className={styles.submitButtonWrapper}>
        <KrayonButton size="medium" onClick={handleFormSubmit}>
          {t('translation:COMMON_NEXT')}
        </KrayonButton>
      </div>
    </>
  )
}
