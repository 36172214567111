import {ErrorBoundary} from '@teachmint/common'
import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import ContentArea from './components/ContentArea/ContentArea'
import Navbar from './components/Navbar/Navbar'
import Sidebar from './components/Sidebar/Sidebar'
import styles from './Home.module.css'
import Feedback from '../Feedback/Feedback'
import Loader from '../../components/common/Loader/Loader'
import history from '../../utils/history'
import {LOGIN} from '../../constants/pages.constants'
import {getFromSessionStorage} from '../../utils/storage.helpers'
import AnnouncementPanel from './components/AnnouncementPanel/AnnouncementPanel'
import globalActions from '../../redux/actions/global.actions'
import {setSelectedInstitute} from '../../redux/actions/common.actions'

export default function Home() {
  const {instituteInfo, showSidebar, globalData, instituteSessionInfo} =
    useSelector((state) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    const successAction = () => {
      dispatch(
        globalActions?.getInstituteInfo?.request(null, (data) =>
          dispatch(setSelectedInstitute(data?.group_institute?._id))
        )
      )
    }

    const failureAction = () => history.replace(LOGIN)

    dispatch(
      globalActions?.getUserInfo?.request(null, successAction, failureAction)
    )
  }, [])

  useEffect(() => {
    const instituteId = getFromSessionStorage('INSTITUTE_ID')
    if (instituteId) {
      dispatch(globalActions?.getInstituteDepartment?.request())
      dispatch(globalActions?.getInstituteSession?.request())
    }
  }, [instituteInfo?.instituteInfo])

  return (
    <div className={styles.homeWrapper}>
      <Feedback />
      <Loader />

      <ErrorBoundary>
        {/* Sidebar for mobile */}
        <div className={styles.sidebarMobileWrapper}>
          <ErrorBoundary>{showSidebar ? <Sidebar /> : null}</ErrorBoundary>
        </div>

        <div className={styles.contentAreaWrapper}>
          <Navbar />
          <div className={styles.mainAreaWrapper}>
            {/* {globalData?.instituteDepartment?.data?.length > 0 &&
              instituteSessionInfo?.sessionList?.length > 0 && ( */}
            <div className={styles.sidebarLaptopWrapper}>
              <Sidebar />
            </div>
            {/* )} */}
            <ContentArea />

            <div className={styles.announcementPanelWrapper}>
              <AnnouncementPanel />
            </div>
          </div>
        </div>
      </ErrorBoundary>
    </div>
  )
}
