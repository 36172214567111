import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {MANAGE_DEPARTMENT__MANAGE_USER__FACULTY__ADD} from '../../../../../../constants/pages.constants'
import SliderAddDepartmentFaculty from '../SliderAddDepartmentFaculty/SliderAddDepartmentFaculty'
import DepartmentFacultyListPage from '../DepartmentFacultyListPage/DepartmentFacultyListPage'
import styles from './DepartmentFacultyPage.module.css'

export default function DepartmentFacultyPage() {
  return (
    <div>
      <DepartmentFacultyListPage />

      <Switch>
        <Route
          path={MANAGE_DEPARTMENT__MANAGE_USER__FACULTY__ADD}
          component={SliderAddDepartmentFaculty}
        />
      </Switch>
    </div>
  )
}
