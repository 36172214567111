import {call, put, takeEvery} from 'redux-saga/effects'
import * as Api from './apiService'
import globalActions from '../../redux/actions/global.actions'
import {getSubdomain} from '../../utils/storage.helpers'
import {showErrorToast} from '../../redux/actions/common.actions'

function* getUserInfoSaga({successAction, failureAction}) {
  try {
    const response = yield call(Api.utilsGetUserInfo, getSubdomain())
    if (response?.data?.status) {
      successAction?.(response?.data?.obj)
      yield put(globalActions.getUserInfo.success(response?.data?.obj))
    } else throw response?.data?.msg
  } catch (error) {
    failureAction?.()
    yield put(globalActions.getUserInfo.failure(error))
    yield put(
      showErrorToast(error || 'Something went wrong, please check your network')
    )
  }
}
export function* watchGetUserInfoSaga() {
  yield takeEvery(globalActions.getUserInfo.REQUEST, getUserInfoSaga)
}
