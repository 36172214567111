import React, {useEffect} from 'react'
import {matchPath, Route, Switch} from 'react-router-dom'
import {
  MANAGE_DEPARTMENT__COURSES,
  MANAGE_DEPARTMENT__MANAGE_USER,
  MANAGE_DEPARTMENT__PROGRAMS,
  MANAGE_DEPARTMENT,
} from '../../../../constants/pages.constants'
import DepartmentDashboard from '../DepartmentDashboard/DepartmentDashboard'
import DepartmentManageUser from '../DepartmentManageUser/DepartmentManageUser'
import DepartmentPrograms from '../DepartmentPrograms/DepartmentPrograms'
import DepartmentCourses from '../DepartmentCourses/DepartmentCourses'
import styles from './DepartmentContentArea.module.css'
import {useDispatch} from 'react-redux'
import globalActions from '../../../../redux/actions/global.actions'
import {getParamsFromPath} from '../../../../utils/routing.helpers'

export default function DepartmentContentArea() {
  const dispatch = useDispatch()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  useEffect(() => {
    if (params?.departmentId)
      dispatch(
        globalActions?.getDepartmentPrograms?.request(params?.departmentId)
      )
  }, [params?.departmentId])

  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route exact path={MANAGE_DEPARTMENT} component={DepartmentDashboard} />
        <Route
          path={MANAGE_DEPARTMENT__MANAGE_USER}
          component={DepartmentManageUser}
        />
        <Route
          path={MANAGE_DEPARTMENT__PROGRAMS}
          component={DepartmentPrograms}
        />
        <Route
          path={MANAGE_DEPARTMENT__COURSES}
          component={DepartmentCourses}
        />
      </Switch>
    </div>
  )
}
