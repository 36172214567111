import React from 'react'
import styles from './EmptyScreen.module.css'
import classNames from 'classnames'
import {Button} from '@teachmint/common'

export default function EmptyScreen({icon, label, desc, btnOptions}) {
  return (
    <div className={styles.wrapper}>
      <img src={icon} alt="" className={styles.icon} />
      <div className={classNames('tm-hdg-18 lg:tm-hdg-22', styles.label)}>
        {label}
      </div>
      <div className={classNames('tm-para-14 lg:tm-para-16', styles.desc)}>
        {desc}
      </div>
      {btnOptions && (
        <Button
          className={styles.button}
          size="big"
          onClick={btnOptions?.action}
        >
          {btnOptions?.label}
        </Button>
      )}
    </div>
  )
}
