import React, {useEffect, useState} from 'react'
import {utilsResetPassword} from './../../apiServices'
import styles from './ResetPasswordPage.module.css'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
  utilsHandleSetError,
} from '../../../../utils/validation'
import history from '../../../../utils/history'
import {DASHBOARD} from '../../../../constants/pages.constants'
import {
  DEFAULT_RESET_PASSWORD_FIELDS_VALUES,
  RESET_PASSWORD_FIELDS,
} from './resetPasswordFormData'
import {REACT_APP_GOOGLE_RECAPTCHA} from '../../../../constants/envVars.constants'
import classNames from 'classnames'
import {
  getFromSessionStorage,
  getSubdomain,
} from '../../../../utils/storage.helpers'
import KrayonInputField from '../../../../components/common/KrayonInputField/KrayonInputField'
import KrayonButton from '../../../../components/common/KrayonButton/KrayonButton'

export default function ResetPasswordPage({setGlobalError, setIsLoading}) {
  const [formData, setFormData] = useState(DEFAULT_RESET_PASSWORD_FIELDS_VALUES)
  const [errorObject, setErrorObject] = useState({})

  const {t} = useTranslation()

  useEffect(() => {
    return () => setGlobalError('')
  }, [])

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(formData, RESET_PASSWORD_FIELDS, fieldName, value)
    )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    let isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      RESET_PASSWORD_FIELDS,
      formData
    )

    // Validate passsword and confirm password is same
    if (formData.password !== formData.confirm_password) {
      isInputValid = false
      utilsHandleSetError(
        setErrorObject,
        RESET_PASSWORD_FIELDS.confirm_password.fieldName,
        t('LOGIN__RESET_PASSWORD__PASSWORD_NOT_MATCH_ERROR')
      )
    }

    // Get login token from session storage
    const loginToken = getFromSessionStorage('UNIVERSITY__LOGIN_TOKEN')

    if (isInputValid && loginToken)
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(REACT_APP_GOOGLE_RECAPTCHA, {action: 'submit'})
          .then((token) => {
            setIsLoading(true)
            utilsResetPassword({
              password: formData.password,
              login_token: loginToken,
              subdomain: getSubdomain(),
              recaptcha_token: token,
            })
              .then(({data}) => {
                if (data?.status && data?.obj?._id) {
                  history.push(DASHBOARD)
                } else setGlobalError(t(`ERROR_${data?.error_code}`))
              })
              .catch(() => setGlobalError(t('ERROR_1000')))
              .finally(() => setIsLoading(false))
          })
      })
  }

  return (
    <form>
      <div className={classNames('tm-n-hdg-20', styles.heading)}>
        {t('LOGIN__RESET_PASSWORD__HEADING')}
      </div>

      <div className={styles.formWrapper}>
        {Object.values(RESET_PASSWORD_FIELDS)?.map(
          ({fieldType, fieldName, label, placeholder}) => (
            <div key={fieldName} className={styles.inputWrapper}>
              <KrayonInputField
                fieldType={fieldType}
                label={t(label)}
                fieldName={fieldName}
                value={formData[fieldName]}
                onChange={handleInputChange}
                placeholder={t(placeholder)}
                infoText={errorObject[fieldName]}
              />
            </div>
          )
        )}
      </div>

      <KrayonButton className={styles.submitButton} onClick={handleFormSubmit}>
        {t('LOGIN__RESET_PASSWORD__SUBMIT_BUTTON_TEXT')}
      </KrayonButton>
    </form>
  )
}
