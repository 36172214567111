import React from 'react'
import KrayonP2 from '../../../../../components/common/KrayonP2/KrayonP2'
import styles from './OnbaordingSteps.module.css'
import {useTranslation} from 'react-i18next'
import KrayonP3 from '../../../../../components/common/KrayonP3/KrayonP3'
import KrayonH6 from '../../../../../components/common/KrayonH6/KrayonH6'
import KrayonIcon from '../../../../../components/common/KrayonIcon/KrayonIcon'

export default function OnbaordingSteps({stepsItems}) {
  const {t} = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.mobileWrapper}>
        {Object.values(stepsItems)
          ?.filter(({active}) => active)
          ?.map(({status}, index) => (
            <>
              <KrayonP2
                key={index}
                className={styles.mobileStepsItem}
                type="medium"
                {...{'data-color': status}}
              >
                {`${t('COMMON_STEP')} ${index + 1}`}
              </KrayonP2>

              {index !== 2 && (
                <div className={styles.mobileBetweenStepsLine}></div>
              )}
            </>
          ))}
      </div>

      <div className={styles.laptopWrapper}>
        {Object.values(stepsItems)
          ?.filter(({active}) => active)
          ?.map(({label, action, iconName, status}, index) => (
            <div key={index} className={styles.laptopStepsItem}>
              <div className={styles.progressWrapper}>
                <div
                  className={styles.progressCircle}
                  {...{'data-color': status}}
                ></div>
                {index !== 2 && <div className={styles.progressLine}></div>}
              </div>

              <div className={styles.dataWrapper} {...{'data-color': status}}>
                <KrayonP3 type="medium" className={styles.stepNum}>
                  {`${t('COMMON_STEP')} ${index + 1}`}
                </KrayonP3>

                <div className={styles.stepBox}>
                  <div className={styles.iconBox} {...{'data-color': status}}>
                    <KrayonIcon name={iconName} size="s" color="white" />
                  </div>
                  <KrayonH6 className={styles.stepBoxLabel}>{label}</KrayonH6>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
