import React, {useState} from 'react'
import {Button} from '@teachmint/common'
import {useDispatch, useSelector} from 'react-redux'
import SliderScreen from '../../../../../../components/common/SliderScreen/SliderScreen'
import SliderScreenBody from '../../../../../../components/common/SliderScreenBody/SliderScreenBody'
import SliderScreenHeader from '../../../../../../components/common/SliderScreenHeader/SliderScreenHeader'
import history from '../../../../../../utils/history'
import styles from './SliderAddDepartmentStudent.module.css'
import {useTranslation} from 'react-i18next'
import {
  utilsFinalFormSubmitChecks,
  utilsHandleInputChange,
} from '../../../../../../utils/validation'
import {
  ADD_DEPARTMENT_STUDENT_FIELDS,
  DEFAULT_ADD_DEPARTMENT_STUDENT_FIELDS_VALUES,
} from './addDepartmentStudentFormData'
import {matchPath} from 'react-router-dom'
import {
  MANAGE_DEPARTMENT,
  MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS,
} from '../../../../../../constants/pages.constants'
import {produce} from 'immer'
import {
  getParamsFromPath,
  updatePathWithParams,
} from '../../../../../../utils/routing.helpers'
import KrayonInputField from '../../../../../../components/common/KrayonInputField/KrayonInputField'
import globalActions from '../../../../../../redux/actions/global.actions'

export default function SliderAddDepartmentStudent() {
  const [formData, setFormData] = useState(
    DEFAULT_ADD_DEPARTMENT_STUDENT_FIELDS_VALUES
  )
  const [errorObject, setErrorObject] = useState({})

  const {departmentProgramInfo} = useSelector((state) => state)

  const dispatch = useDispatch()
  const {t} = useTranslation()
  const params = getParamsFromPath(matchPath, MANAGE_DEPARTMENT)

  const handleInputChange = ({fieldName, value}) =>
    setFormData(
      utilsHandleInputChange(
        formData,
        ADD_DEPARTMENT_STUDENT_FIELDS,
        fieldName,
        value
      )
    )

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setErrorObject({})

    // Validate all fields
    const isInputValid = utilsFinalFormSubmitChecks(
      setErrorObject,
      ADD_DEPARTMENT_STUDENT_FIELDS,
      formData
    )

    if (isInputValid && params?.departmentId) {
      const successAction = () => {
        dispatch(
          globalActions?.getDepartmentUsers?.request(params?.departmentId)
        )
        history.push(
          updatePathWithParams(MANAGE_DEPARTMENT__MANAGE_USER__STUDENTS, params)
        )
      }

      dispatch(
        globalActions?.addDepartmentUser?.request(
          {
            ...formData,
            node_id: params?.departmentId,
            enrollment_number: 'GI-14556',
            user_role: 'DEPARTMENT_STUDENT',
            start_date: '1655296719',
            end_date: '1676296719',
          },
          successAction
        )
      )
    }
  }

  const addDepartmentStudentFields = produce(
    ADD_DEPARTMENT_STUDENT_FIELDS,
    (draft) => {
      const programBatchs = [{value: 'select', label: 'Select'}]

      departmentProgramInfo?.departmentProgramList?.forEach(
        ({program_batches}) => {
          program_batches?.forEach((programBatchItem) =>
            programBatchs.push({
              value: programBatchItem?._id,
              label: programBatchItem?.name,
            })
          )
        }
      )

      draft.program_batch_id.options.options = programBatchs
      return draft
    }
  )

  return (
    <div>
      <SliderScreen setOpen={() => history.goBack()}>
        <>
          <SliderScreenHeader
            icon="dashboard"
            title={t('SLIDER_ADD_DEPARTMENT_STUDENT_LABEL')}
          />

          <SliderScreenBody>
            <div>
              <form>
                {Object.values(addDepartmentStudentFields)?.map(
                  ({fieldType, fieldName, label, placeholder, options}) => (
                    <div key={fieldName} className={styles.inputWrapper}>
                      <KrayonInputField
                        fieldType={fieldType}
                        label={t(label)}
                        fieldName={fieldName}
                        value={formData[fieldName]}
                        onChange={handleInputChange}
                        placeholder={t(placeholder)}
                        infoText={errorObject[fieldName]}
                        options={options.options}
                      />
                    </div>
                  )
                )}

                <Button
                  className={styles.submitButton}
                  size="medium"
                  onClick={handleFormSubmit}
                >
                  {t('COMMON_SUBMIT')}
                </Button>
              </form>
            </div>
          </SliderScreenBody>
        </>
      </SliderScreen>
    </div>
  )
}
