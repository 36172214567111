import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {INSTITUTE_SESSION__ADD} from '../../constants/pages.constants'
import InstituteSessionList from './components/InstituteSessionList/InstituteSessionList'
import SliderAddInstituteSession from './components/SliderAddInstituteSession/SliderAddInstituteSession'
// import styles from './InstituteSession.module.css'

export default function InstituteSession() {
  return (
    <div>
      <InstituteSessionList />

      <Switch>
        <Route
          exact
          path={INSTITUTE_SESSION__ADD}
          component={SliderAddInstituteSession}
        />
      </Switch>
    </div>
  )
}
